import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import RoutesPathDefs from "../../RoutesPathDefs";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {helpers, TaggedLogger} from "@nextlot/core";
import {MyBiddingWarrantStateButtonLabel} from "../MyBiddingWarrantStateButtonLabel";
import {WebcastEnterButtons} from "../WebcastEnterButtons";


const _logger = TaggedLogger.get('AuctionCard');

export default function AuctionCard(props:{ auction:Auction_ModelTypeDef }) {
  const { auction } = props;

  return (
      <div className="auction-card">
          <div className="auction-card__header bg-light">
              <div className="auction-card__info">
                  <div className="auction-card__type">
                      <span>{auction.auctionType.name}</span>

                      <span className="timeline-state-indicator">
                          <i className={classNames("fa-regular fa-fw", {
                              "fa-circle-play": auction.timelineState.isRunning,
                              "fa-circle-check": auction.timelineState.isCompleted,
                              "fa-calendar-day": auction.timelineState.isUpcoming,
                          })} />
                          &nbsp;
                          {auction.timelineState.name}</span>
                  </div>
                  <div className="d-none d-lg-block vr" />
                  <hr className="d-lg-none" />
                  <div className="auction-card__name">

                      <Link to={RoutesPathDefs.toAuctionLotsPage.generatePath(auction.id)}
                            state={{ fromAuctionsList: true, auction: auction }}
                            className="lh-base h3 text-decoration-none">{auction.name}</Link>


                      <div className="auction-card__location-info"><i className="fas fa-map-marker-alt" /> {auction.locationInfo || 'Online Bidding'}</div>
                  </div>
              </div>
              <hr className="d-lg-none" />
              <div className="auction-card__lots">
                  <span className="text-end">LOTS</span>
                  <span className="text-end">
                    <Link to={RoutesPathDefs.toAuctionLotsPage.generatePath(auction.id)}
                          state={{ fromAuctionsList: true, auction: auction }}
                          className="h4 text-decoration-none">{auction.lotsCount}</Link>
                  </span>
              </div>
          </div>



          <div className="auction-card__body">

              <div className="auction-card__thumb-img">
                  <img src={auction.urlThumbImage} alt={`${auction.name} image`} />
              </div>

              <div className="auction-card__details">

                  <div className="auction-card__timeline">
                      {helpers.buildAuctionTimelineLabels(auction, helpers.formatDateTime).map(
                          (label, idx, labels) => <div key={idx}>{label}</div>)}
                  </div>
                  {
                      auction.viewingInfo
                          ? <div className="auction-card__viewing_info">
                              <span className='text-body-secondary fs-6 fw-light'>VIEWING</span>
                              <div>{auction.viewingInfo}</div>
                          </div>
                          : null
                  }
                  <div className="auction-card__summary">
                      <span className='text-body-secondary fs-6 fw-light'>SUMMARY</span>
                      <div className="" dangerouslySetInnerHTML={{__html: auction.summary}} />
                  </div>


                  <div className="auction-card__footer-bar">

                      <MyBiddingWarrantStateButtonLabel auction={auction} />

                      <WebcastEnterButtons auction={auction} />

                      <Link to={RoutesPathDefs.toAuctionLotsPage.generatePath(auction.id)}
                            state={{ fromAuctionsList: true, auction: auction }}
                            className="btn btn-outline-secondary">View lots</Link>
                  </div>
              </div>
          </div>
      </div>
  )
}
