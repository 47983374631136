import React from "react";
import classNames from "classnames";
import ReactModal from "react-modal";
import {useDispatch} from "react-redux";
import {ApiResourceDetailsLevelsEnum} from "@nextlot/core/type_defs/api";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {useCurrentAuctionModelState} from "@nextlot/core/hooks";
import AnimatedSkeletons from "../AnimatedSkeletons";
import {AppStoreDispatch} from "@nextlot/core";
import {DATETIME_FORMAT_STRINGS, formatDateTime, buildAuctionTimelineLabels} from "@nextlot/core/helpers";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";


export function AuctionPageHeaderCmp() {

    const dispatch = useDispatch<AppStoreDispatch>();
    const {auctionId, auction, isFetching} = useCurrentAuctionModelState();

    const [isExpanded, setIsExpanded] = React.useState( ! window.localStorage[`NL:Auction:${auctionId}:headerKeepCollapsed`]);
    const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);

    const isMissingFullDetails = (auction?._detailsLevel !== ApiResourceDetailsLevelsEnum.full);

    React.useEffect(() => {
        if (isExpanded && isMissingFullDetails) {
            dispatch.currentAuction.asyncFetchLoad({ auctionId, detailsLevel: ApiResourceDetailsLevelsEnum.full });
        }
    }, [auctionId, isMissingFullDetails, isExpanded]);


    const isLoadingFullDetails:boolean = isFetching && isMissingFullDetails;


    const handleClickExpandCollapse = (evt) => {
        evt.preventDefault();

        if (isExpanded) {
            window.localStorage[`NL:Auction:${auctionId}:headerKeepCollapsed`] = true;
        }

        setIsExpanded(!isExpanded);
    }


    const handleToggleOpenTermsModal = (evt) => {
        evt.preventDefault();
        setIsTermsModalOpen(!isTermsModalOpen);
    }



    return (
        <div className={classNames('auction-header', isExpanded ? 'is-expanded' : 'is-collapsed')} >

            <div className="container-xxl">
                <div className="title-area-grid">
                    <div className="auction-name">
                        <h3 className="">{auction.name}</h3>
                    </div>
                    <div className="auction-type">
                        <span className='badge bg-secondary fg-dark'>{auction.auctionType.name}</span>
                    </div>
                    <div className="auction-time-info">
                        {computeAuctionTimeInfo(auction)}
                    </div>
                    <div className="auction-terms">
                        •&nbsp;
                        <a href='' onClick={handleToggleOpenTermsModal} target="_blank" className='show-new-window-hint'>Auction Terms</a>
                        <ReactModal
                            isOpen={isTermsModalOpen}
                            portalClassName={'rmodal__portal'}
                            overlayClassName={{ base: 'rmodal__overlay', afterOpen: 'rmodal__overlay--after-open', beforeClose: '' }}
                            bodyOpenClassName={'rmodal__html-body--open'}
                            className={'rmodal__dialog'}
                            contentLabel="Auction Terms & Conditions"
                        >

                            <div className="bg-light d-flex flex-row gap-4 p-3 pb-2">
                                <h5 className="flex-grow-1">Auction Terms & Conditions </h5>
                                <a href={`${NextLotJSDATA.webapp_root_base_url}/sale_terms/${auctionId}`} target="_blank" className="d-none d-lg-block"><i className="fa-solid fa-share-nodes" /></a>
                                <button type="button" className="btn-close" onClick={handleToggleOpenTermsModal} />
                            </div>

                            <div className="p-3" style={{height: "90%", width: "100%"}}>
                                <iframe className="" src={`${NextLotJSDATA.webapp_root_base_url}/sale_terms/${auctionId}`} width="100%" height="100%" title="Auction Terms" />
                            </div>
                        </ReactModal>
                    </div>

                    <div className="auction-details">
                        •&nbsp;
                        <a href='src/components/AuctionPageHeaderCmp' onClick={handleClickExpandCollapse} className='text-decoration-none'>
                            {
                                isExpanded
                                    ? <><span className='text-decoration-underline'>Less details</span> <i className="fa-regular fa-angles-up" /></>
                                    : <><span className='text-decoration-underline'>More details</span> <i className="fa-regular fa-angles-down" /></>
                            }

                        </a>
                    </div>

                </div>


                <div className="row row-details">
                    <div className="col">

                        {
                            isLoadingFullDetails
                                ? <div className="py-5"><AnimatedSkeletons.ParagraphSkeleton /></div>
                                : <div className="pb-3 vstack gap-3">

                                    {
                                        auction.internetBuyerPremiumDisplayText
                                            ? <div>
                                                <div className='text-body-secondary fs-6 fw-light'>BUYER PREMIUM</div>
                                                <div>{auction.internetBuyerPremiumDisplayText}</div>
                                            </div>
                                            : null
                                    }

                                    {
                                        auction.locationInfo
                                            ? <div>
                                                <div className='text-body-secondary fs-6 fw-light'>LOCATION</div>
                                                <div>{auction.locationInfo}</div>
                                            </div>
                                            : null
                                    }

                                    <div>
                                        <div className='text-body-secondary fs-6 fw-light'>SUMMARY</div>
                                        <div className='fr-view' dangerouslySetInnerHTML={{ __html: auction.summary }} />
                                    </div>

                                    {
                                        auction.viewingInfo
                                            ? <div>
                                                <div className='text-body-secondary fs-6 fw-light'>VIEWING</div>
                                                <div>{auction.viewingInfo}</div>
                                            </div>
                                            : null
                                    }


                                    {
                                        auction.detailsPageHtml
                                            ? <div>
                                                <div className='text-body-secondary fs-6 fw-light'>DESCRIPTION</div>
                                                <div className='fr-view' dangerouslySetInnerHTML={{ __html: auction.detailsPageHtml }} />
                                            </div>
                                            : null
                                    }


                                    <div className='pt-3 border-top border-gray d-flex flex-row justify-content-start'>
                                        <a href="src/components/AuctionPageHeaderCmp" onClick={handleClickExpandCollapse} className='btn btn-sm btn-outline-dark text-decoration-none'>
                                            <i className="fa-regular fa-angles-up" /> &nbsp; Hide auction details
                                        </a>
                                    </div>


                                </div>
                        }
                    </div>
                </div>


            </div>
        </div>
    )
}


function computeAuctionTimeInfo(auction:Auction_ModelTypeDef) {
    const timelineStateName = auction.timelineState.name
    if (auction.timelineState.isCompleted) {
        return `${timelineStateName} ${formatDateTime(auction.completesAt, DATETIME_FORMAT_STRINGS.AUCTION_HEADER)}`;
    }
    else if (auction.timelineState.isUpcoming) {
        if (auction.auctionType.hasWebcastBidding) {
          return <>
                    {buildAuctionTimelineLabels(auction, formatDateTime).map(
                      (label, idx, labels) => <span key={idx}>{idx > 0 && <>•&nbsp;</>}{label}</span>)}
                 </>;
        } else {
          return `${timelineStateName} ${formatDateTime(auction.startsAt, DATETIME_FORMAT_STRINGS.AUCTION_HEADER)}`;
        }
    }
    else {
        // running webcast
        if (auction.auctionType.hasWebcastBidding) {
          return <>
            <span className='me-2 badge bg-success'>{timelineStateName}</span>
              {buildAuctionTimelineLabels(auction, formatDateTime).map(
                              (label, idx) => <span key={idx}>{idx > 0 && <>&nbsp;•&nbsp;</>}{label}</span>)}
            </>
        }

        // running
        return <><span className='badge bg-success'>{timelineStateName}</span> <span>Lots start closing {formatDateTime(auction.closingStartsAt, DATETIME_FORMAT_STRINGS.AUCTION_HEADER)}</span></>
    }
}

