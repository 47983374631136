import currency, {Options} from "currency.js";

type CurrencyOptions_TypeDef = Required<{
    symbol: string,
    separator: string,
    decimal: string,
    precision: number
}>;

const THRESHOLD_DOLLAR_AMOUNT_SHOW_DECIMALS = 10;

const groupRegex = /(\d)(?=(\d{3})+\b)/g;


const CURRENCY_OPTIONS:{[Key:string]:CurrencyOptions_TypeDef} = {
    'USD': { symbol: "$", separator: ",", decimal: ".", precision: 2 },
    'CAD': { symbol: "$", separator: ",", decimal: ".", precision: 2 },
    'AUD': { symbol: "$", separator: ",", decimal: ".", precision: 2 },
    'EUR': { symbol: "€", separator: ",", decimal: ".", precision: 2 },
    'GBP': { symbol: "£", separator: ",", decimal: ".", precision: 2 },
}

function formatFunc(currency: currency, opts: Options):string {
    // console.log('>>>>[formatFunc]>>>>', currency, opts);

    const currencySymbolPrefix:string = opts.symbol;
    const dollars:number = currency.dollars();
    const cents:number = currency.cents() // result can be between 0 and 99

    let centsStr:string|null = null;
    if (cents > 0) {
        centsStr = '.' + String(cents).padStart(2, '0');
    }
    // else if (dollars < THRESHOLD_DOLLAR_AMOUNT_SHOW_DECIMALS) { // and cents == 0
    //     centsStr = '.00';
    // }

    // Handle correction for currency values greater than `2 ** 31` (2_147_483_648) when the `~~` bitwiseNOT operator overflows.
    // https://stackoverflow.com/a/75849951
    const dollarCorrection = currency.value > 2_147_483_648 ? Math.trunc(currency.value) : dollars;
    const dollarsStr = String(dollarCorrection).replace(groupRegex, '$1' + opts.separator);

    return `${currencySymbolPrefix}${dollarsStr}${centsStr ? centsStr : ''}`;
}


class MoneyHelper {
    private readonly currencyOptions:CurrencyOptions_TypeDef & Options;

    constructor(currencyCode:string) {
        this.currencyOptions = {
            ... CURRENCY_OPTIONS[currencyCode],
            format: formatFunc,
        }
    }

    fromCents = (amountCents) => (currency(amountCents, { ... this.currencyOptions, fromCents: true }));
    fromAmount = (amount) => (currency(amount, { ... this.currencyOptions}));

    formatFromCents = (amountCents) => (this.fromCents(amountCents).format());
    amountFromCents = (amountCents) => (this.fromCents(amountCents).value);

}


const MONEY_HELPERS_MAP_BY_CURRENCY_CODE:Map<String, MoneyHelper> = new Map(
    Object.keys(CURRENCY_OPTIONS)
        .map((currencyCode) => {
            return [currencyCode, new MoneyHelper(currencyCode)];
        }));


export function getMoneyHelperForCurrencyCode(currencyCode:string = 'USD'):MoneyHelper {
    return MONEY_HELPERS_MAP_BY_CURRENCY_CODE.get(currencyCode);
}
