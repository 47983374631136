import React from "react";
import classNames from "classnames";
import {CSSTransition} from "react-transition-group";
import {Auction_ModelTypeDef, RunningCatalogLot_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {Link, useLocation, useNavigate} from "react-router-dom";
import RoutesPathDefs from "../RoutesPathDefs";
import LotWatchWidgetButton from "./LotWatchWidgetButton";
import {useDispatch} from "react-redux";
import {Dispatch} from "@nextlot/core/rematch/store";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import LotBiddingAreaCmp from "./lot_bidding_area/LotBiddingAreaCmp";

export enum BiddingContextViewEnum {
    list = 'list',
    fast_bidding = 'fast_bidding',
    watchlist = 'watchlist',
}

type LotCard_PropsTypeDef = {
    auction: Auction_ModelTypeDef,
    lot: RunningCatalogLot_ModelTypeDef,
    biddingContextView: BiddingContextViewEnum,
}

const ANIMATION_DURATION = 500; // must match CSS animation, see class applied to <CSSTransition> below (animated-out)

export default function LotCard({ lot, auction, biddingContextView }:LotCard_PropsTypeDef) {
    // _logger.debug(`[LotCard#${lot.id}] >>> render()`);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatchStore = useDispatch<Dispatch>();

    const handleClick_openLotDetails = React.useCallback((evt) => {
        if (biddingContextView === BiddingContextViewEnum.watchlist) {
            // open in a new tab/window
            window.open(window.location.origin + NextLotJSDATA.webapp_root_path + RoutesPathDefs.toLotDetailsPage.generatePath(lot.id, lot.auctionId), '_blank');
        }
        else {
            navigate(RoutesPathDefs.toLotDetailsPage.generatePath(lot.id, lot.auctionId), { state: { preloadedLot: lot, backgroundLocation: location } });
        }
    }, [lot.id, biddingContextView]);


    const animateOnComplete = biddingContextView !== BiddingContextViewEnum.list; // animate for fast_bidding and watchlist
    const animationIn = animateOnComplete
        ? (! lot.isCompleted)
        : true; // never animate when animateOnComplete is not set
    const animateUnmountOnExit = biddingContextView === BiddingContextViewEnum.fast_bidding; // only unmount in fast_bidding


    const handleOnExitedAnimation = () => {
        dispatchStore.currentAuctionLots.updateLotSetHiddenFromView(lot.id);
        dispatchStore.watchlist.updateLotAsClosed(lot.id);
    }

    return (
        <CSSTransition
            unmountOnExit={animateUnmountOnExit}
            timeout={ANIMATION_DURATION}
            classNames='animated-out'
            in={animationIn}
            onExited={handleOnExitedAnimation}
        >
            <div className={classNames("lot-card", lot.myWatched && 'is-watched')}>
                <div className="lot__header">
                    <div className="lot-number has-cursor-pointer" onClick={handleClick_openLotDetails}>
                        <span style={{ opacity: 0.5 }}>#</span><strong>{lot.number}</strong>
                    </div>

                    <div className="lot-actions">
                        <LotWatchWidgetButton lot={lot} />
                    </div>
                </div>

                <div className="lot__name has-cursor-pointer" onClick={handleClick_openLotDetails}>
                    {lot.name}
                </div>

                <div className="lot__image has-cursor-pointer">
                    <img src={lot.focalMediaFileThumbImageUrl} alt={lot.number} onClick={handleClick_openLotDetails} />
                    {
                        (!!lot.videoUrl)
                        &&
                        <div style={{
                            position: "absolute",
                            top: 10,
                            left: 10,
                            width: 60,
                            height: 60,
                        }}><i className='fas fa-circle-video fa-lg'/></div>
                    }
                </div>


                <div className='lot__description' dangerouslySetInnerHTML={{ __html: lot.description as string }} />


                <div className='lot__bidding'>
                    <LotBiddingAreaCmp
                        auction={auction}
                        lot={lot}
                    />
                </div>

                {
                    (biddingContextView === BiddingContextViewEnum.watchlist)
                    &&
                    <div className='pt-2 border-top text-secondary' style={{ alignSelf: 'flex-end' }}>
                        <Link className="text-decoration-none text-secondary"
                              to={`/${auction.id}/lots?filters=watched%3Atrue`}
                              target='_blank'><i className={'fa-solid fa-gavel'} /> &nbsp; {auction.name}</Link>
                    </div>
                }
            </div>
        </CSSTransition>
    )
}

