import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import AppController from "../../AppController";
import {
    CatalogLot_ModelTypeDef,
    LotMetal_ModelTypeDef,
    ListingBrowserParams,
    LotsPaginatedList_TypeDef,
    LotsRunningTimedStaggeredList_TypeDef,
    RunningCatalogLot_ModelTypeDef,
    RunningLotMetal_ModelTypeDef
} from "../../type_defs/model";
import {ApiResource_LotMetal, ApiResource_RunningTimedLot, ApiResourceDetailsLevelsEnum} from "../../type_defs/api";
import {TaggedLogger} from "../../utilities";


const _logger = TaggedLogger.get('currentAuctionLotsModel');

export enum LotsViewModes_Enum {
    grid = 'grid',
    list = 'list',
    fastBidding = 'fastBidding',
}


export type CurrentAuctionLotsModelState = {

    lotInFocus?: RunningCatalogLot_ModelTypeDef, // used for the full-page single lot view

    lotsList: RunningCatalogLot_ModelTypeDef[]|null,
    lotsById: Map<number, RunningCatalogLot_ModelTypeDef>,

    searchQuery?:string,
    sortingCriteria?:string,

    refTimestamp?: number,
    cursorTimestamp?: number,
    nextCursorTimestamp?: number,
    windowCount?: number,

    pageNumber: number,
    pageSize?: number,
    pagesCount: number,

    totalCount: number,

    isInitialFetching: boolean,
    isFetching: boolean,
    hasFetchError?: string|boolean,
}

export const currentAuctionLots = createModel<RootModel>()({
    name: 'currentAuctionLots',

    state: {
        isInitialFetching: true,
        isFetching: true,

        lotsList: [],
        lotsById: new Map(),

        totalCount: 0,
        pageNumber: 1,
        pagesCount: 1,
    } as CurrentAuctionLotsModelState,

    reducers: {
        resetDataBeforeInitialFetch: (state, payload?) => {
            state.isInitialFetching = true;
            state.isFetching = true;
            state.hasFetchError = null;

            state.lotsList = [];
            state.lotsById = new Map();
            state.lotInFocus = null;

            state.refTimestamp = 0;
            state.cursorTimestamp = 0;
            state.nextCursorTimestamp = 0;
            state.pageNumber = 1;
            state.totalCount = 0;
            state.pagesCount = 0;
        },

        initForTimedStaggeredFastBiddingView: (state, apiResponseData:LotsRunningTimedStaggeredList_TypeDef) => {
            state.isInitialFetching = false;
            state.isFetching = true;
            state.hasFetchError = null;

            state.lotsList = [];
            state.lotsById = new Map();
            state.lotInFocus = null;

            state.refTimestamp = 0;
            state.cursorTimestamp = 0;
            state.nextCursorTimestamp = 0;
            state.pageNumber = 1;
            state.totalCount = 0;
            state.pagesCount = 0;

            mutateStateForTimedStaggeredFastBiddingView(state, apiResponseData);
        },

        appendForTimedStaggeredFastBiddingView: (state, apiResponseData:LotsRunningTimedStaggeredList_TypeDef) => {
            mutateStateForTimedStaggeredFastBiddingView(state, apiResponseData);
        },

        setViewList: (state, payload:LotsPaginatedList_TypeDef<CatalogLot_ModelTypeDef>) => {
            state.isInitialFetching = false;
            state.isFetching = false;
            state.hasFetchError = null;

            state.lotsList = payload.list;

            state.refTimestamp = 0;
            state.cursorTimestamp = 0;

            state.pageNumber = payload.pageNumber;
            state.pageSize = payload.pageSize;
            state.totalCount = payload.totalCount;
            state.pagesCount = payload.pagesCount;
        },


        setViewLotInFocus: (state, payload:CatalogLot_ModelTypeDef) => {
            state.isInitialFetching = false;
            state.isFetching = false;
            state.hasFetchError = null;

            state.lotInFocus = payload;
        },


        updateViewLotInFocus: (state, payload:CatalogLot_ModelTypeDef) => {
            state.lotInFocus = {
                ... state.lotInFocus,
                ... payload,
            };
            state.isFetching = false;
            state.hasFetchError = null;
        },


        setIsFetching: (state, payload:boolean = true) => {
            state.isFetching = payload;
        },

        setFetchError: (state, payload:string|boolean) => {
            state.isInitialFetching = false;
            state.isFetching = false;
            state.hasFetchError = payload;
        },

        updateLotWithFullDetails: (state, payloadLotWithFullDetails:CatalogLot_ModelTypeDef) => {
            if (state.lotInFocus) {
                Object.assign(state.lotInFocus, payloadLotWithFullDetails);
            }
            else {
                state.lotsList?.forEach((sLot) => {
                    if (sLot.id === payloadLotWithFullDetails.id) {
                        Object.assign(sLot, payloadLotWithFullDetails);
                        return
                    }
                });
            }
        },

        updateLotsListWithFullDetails: (state, payloadListLotsWithFullDetails: Array<CatalogLot_ModelTypeDef>): void => {
            payloadListLotsWithFullDetails.forEach((pLot) => {
                state.lotsList?.forEach((sLot) => {
                    if (sLot.id === pLot.id) {
                        Object.assign(sLot, pLot);
                        return
                    }
                });
            });
        },


        updateRunningLotAndExtendLinkedLots: (state, payloadLot:RunningLotMetal_ModelTypeDef) => {
            if (state.lotInFocus) {
                mutateRunningLotWithBidAndLinkedGroupExtension(state.lotInFocus, payloadLot);
            }
            else {
                state.lotsList?.forEach((sLot) => {
                    mutateRunningLotWithBidAndLinkedGroupExtension(sLot, payloadLot);
                });
            }
        },


        updateLotWithChangedInterest: (state, payloadLot:LotMetal_ModelTypeDef) => {
            if (state.lotInFocus) {
                state.lotInFocus.myWatched = payloadLot.myWatched;
            }
            else {
                state.lotsList?.forEach((sLot) => {
                    if (sLot.id === payloadLot.id) {
                        sLot.myWatched = payloadLot.myWatched;
                    }
                });
            }
        },


        updateLotSetHiddenFromView: (state, lotId:number) => {
            const idx = state.lotsList.findIndex(lot => lot.id == lotId);
            if (idx > -1) {
                state.lotsList[idx].isHiddenFromLotsListView = true;
            }
        }
    },


    effects: (dispatch) => ({


        async asyncLoadForSingleLotView(params:{ lotId: number, detailsLevel?:ApiResourceDetailsLevelsEnum }, rootState) {
            try {
                const { lotId } = params;
                const auctionId = rootState.currentAuction.auctionId;

                if (rootState.currentAuctionLots.lotInFocus?.id !== lotId) {
                    // cleanup previously loaded lot if exists
                    dispatch.currentAuctionLots.setViewLotInFocus(null);
                }

                dispatch.currentAuctionLots.setIsFetching(true);

                const [responseDataLot, responseDataMediaFiles] = await Promise.all([
                    AppController.instance.remoteDataHandler.fetchAuctionLot(auctionId, lotId, params.detailsLevel || ApiResourceDetailsLevelsEnum.full),
                    AppController.instance.remoteDataHandler.fetchAuctionLotItemMediaFilesList(auctionId, lotId)
                ]);

                if (rootState.currentAuctionLots.lotInFocus?.id === responseDataLot.id) {
                    dispatch.currentAuctionLots.updateViewLotInFocus({
                        ... responseDataLot,
                        mediaFiles: responseDataMediaFiles.list,
                    });
                }
                else {
                    dispatch.currentAuctionLots.setViewLotInFocus({
                        ... responseDataLot,
                        mediaFiles: responseDataMediaFiles.list,
                    });
                }

            }
            catch (ex) {
                dispatch.currentAuctionLots.setFetchError(ex.toString() || true);
            }
        },


        async asyncLoadForBrowsableView(params:ListingBrowserParams = { pageNumber: 1 }, rootState) {
            try {
                dispatch.currentAuctionLots.setIsFetching(true);

                if (params.entityDetailsLevel === ApiResourceDetailsLevelsEnum.full) {
                    // sort-of-HACK for lots ListView, when we fetch with full details, we first make a call to fetch the minimal information,
                    let responseData = await AppController.instance.remoteDataHandler
                        .fetchAuctionLotsListBrowsable(rootState.currentAuction.auctionId, {
                            ... params,
                            entityDetailsLevel: ApiResourceDetailsLevelsEnum.minimal,
                        })

                    dispatch.currentAuctionLots.setViewList(responseData);

                    // then, we make a subsequent call for the full details information
                    try {
                        responseData = await AppController.instance.remoteDataHandler
                            .fetchAuctionLotsListBrowsable(rootState.currentAuction.auctionId, params);
                        dispatch.currentAuctionLots.updateLotsListWithFullDetails(responseData.list);
                    }
                    catch (ex) {
                        // silent fail, we should NOT set the fetch error, it will break the view
                    }
                }
                else {
                    const responseData = await AppController.instance.remoteDataHandler
                        .fetchAuctionLotsListBrowsable(rootState.currentAuction.auctionId, params)

                    dispatch.currentAuctionLots.setViewList(responseData);
                }
            }
            catch (ex) {
                dispatch.currentAuctionLots.setFetchError(ex.toString() || true);
            }
        },



        async asyncInitLoadForTimedStaggeredFastBiddingView(_params?, rootState?) {
            try {
                const responseData = await AppController.instance.remoteDataHandler.fetchAuctionLotsRunningTimedStaggeredPaginatedList(rootState.currentAuction.auctionId);

                dispatch.currentAuctionLots.initForTimedStaggeredFastBiddingView(responseData);
            }
            catch (ex) {
                dispatch.currentAuctionLots.setFetchError(ex.toString() || true);
            }
        },


        async asyncLoadMoreForTimedStaggeredFastBiddingView(_params?, rootState?) {
            try {
                dispatch.currentAuctionLots.setIsFetching(true);

                const responseData = await AppController.instance.remoteDataHandler.fetchAuctionLotsRunningTimedStaggeredPaginatedList(rootState.currentAuction.auctionId,
                    rootState.currentAuctionLots.refTimestamp,
                    rootState.currentAuctionLots.nextCursorTimestamp,
                );

                dispatch.currentAuctionLots.appendForTimedStaggeredFastBiddingView(responseData);
            }
            catch (ex) {
                dispatch.currentAuctionLots.setFetchError(ex.toString() || true);
            }
        },




        async asyncFetchAndUpdateRunningLotWithSideEffects({auctionId, lotId}:{auctionId:number, lotId:number}, rootState) {

            // fetch RunningLot via API
            const updatedRunningLot:RunningLotMetal_ModelTypeDef =
                await AppController.instance.remoteDataHandler.fetchAuctionLot<RunningLotMetal_ModelTypeDef, ApiResource_RunningTimedLot>(auctionId, lotId);

            const auctionCompletesAt = rootState.currentAuction?.auction?.completesAt;
            // update Auction completion time if it's before the updated Lot
            if (auctionCompletesAt && auctionCompletesAt < updatedRunningLot.completesAt) {
                dispatch.currentAuction.updateAuction({ completesAt: updatedRunningLot.completesAt, isCompleted: false });
            }

            dispatch.currentAuctionLots.updateRunningLotAndExtendLinkedLots(updatedRunningLot);
            dispatch.watchlist.updateRunningLotAndExtendLinkedLots(updatedRunningLot);
        },



        async asyncFetchAndUpdateLotWithFullDetails({lotId}:{lotId:number}, rootState) {
            // _logger.debug('.asyncFetchAndUpdateLotWithFullDetails: lotId:', lotId);

            const auctionId = rootState.currentAuction.auctionId;

            const [responseDataLot, responseDataMediaFiles] = await Promise.all([
                AppController.instance.remoteDataHandler.fetchAuctionLot(auctionId, lotId, ApiResourceDetailsLevelsEnum.full),
                AppController.instance.remoteDataHandler.fetchAuctionLotItemMediaFilesList(auctionId, lotId)
            ]);

            const lotWithFullDetails:CatalogLot_ModelTypeDef = {
                ... responseDataLot,
                mediaFiles: responseDataMediaFiles.list,
            }

            dispatch.currentAuctionLots.updateLotWithFullDetails(lotWithFullDetails);
        },



        async asyncFetchAndUpdateLotChangedInterest({lotId}:{lotId:number}, rootState) {
            const auctionId = rootState.currentAuction.auctionId;

            // fetch Lot via API
            const updatedLot:LotMetal_ModelTypeDef =
                await AppController.instance.remoteDataHandler.fetchAuctionLot<LotMetal_ModelTypeDef, ApiResource_LotMetal>(auctionId, lotId, ApiResourceDetailsLevelsEnum.metal);

            dispatch.currentAuctionLots.updateLotWithChangedInterest(updatedLot);
        },


    }),
})




function mutateStateForTimedStaggeredFastBiddingView(state, apiResponseData:LotsRunningTimedStaggeredList_TypeDef) {
    state.isFetching = false;
    state.hasFetchError = null;

    state.totalCount = apiResponseData.totalCount;

    state.refTimestamp = apiResponseData.refTimestamp;
    state.cursorTimestamp = apiResponseData.cursorTimestamp;
    state.nextCursorTimestamp = apiResponseData.nextCursorTimestamp;

    apiResponseData.list.forEach((lot) => {
        if (lot.isCompleted) {
            return; // skip completed lots
        }
        if (state.lotsById.has(lot.id)) {
            return; // skip already existing lots
        }
        state.lotsList.push(lot);
        state.lotsById.set(lot.id, lot);
    });
}



function mutateRunningLotWithBidAndLinkedGroupExtension(mutableSourceLot:RunningLotMetal_ModelTypeDef, payloadLot:RunningLotMetal_ModelTypeDef) {
    const { id, linkedLotsGroupId, completesAt, isCompleted } = payloadLot;
    const completesAtSeconds = completesAt.toSeconds();

    if (mutableSourceLot.id === id) {
        mutableSourceLot.bidsCount = payloadLot.bidsCount;
        mutableSourceLot.askingBidAmountCents = payloadLot.askingBidAmountCents;
        mutableSourceLot.leadingBidAmountCents = payloadLot.leadingBidAmountCents;
        mutableSourceLot.myBidIsLeadingBid = payloadLot.myBidIsLeadingBid;
        mutableSourceLot.myMaxBidAmountCents = payloadLot.myMaxBidAmountCents;
        mutableSourceLot.myBidExistsInHistory = payloadLot.myBidExistsInHistory;
        mutableSourceLot.reservePriceStatusCode = payloadLot.reservePriceStatusCode;

        if (mutableSourceLot.completesAt.toSeconds() !== completesAtSeconds || mutableSourceLot.isCompleted !== isCompleted) {
            mutableSourceLot.completesAt = completesAt;
            mutableSourceLot.isCompleted = isCompleted;
            mutableSourceLot.hasRound = payloadLot.hasRound;
        }
    }
    else if (linkedLotsGroupId && mutableSourceLot.linkedLotsGroupId === linkedLotsGroupId) {
        if (mutableSourceLot.completesAt.toSeconds() !== completesAtSeconds || mutableSourceLot.isCompleted !== isCompleted) {
            mutableSourceLot.completesAt = completesAt;
            mutableSourceLot.isCompleted = isCompleted;
        }
    }
}
