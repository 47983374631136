
export const PusherChannelNames = {
    publicEventsForSiteAuctions: function(siteId:number) {
        return `pub-s${siteId}-a`;
    },

    publicEventsForAuctionLots: function(siteId:number, auctionId:number) {
        return `pub-s${siteId}-a${auctionId}-l`;
    },

    privateEventsForBidder: function(siteId:number, bidderId:number) {
        return `private-s${siteId}-b${bidderId}`;
    }
}


export enum PusherConnectionStatesEnum {
    connected = 'connected',
    connecting = 'connecting',
    failed = 'failed',
    unavailable = 'unavailable',
    disconnected = 'disconnected',
}

export interface IPusherAdapter {
    init(currentBidder?:{ id:number, apiToken:string }):Promise<any>;

    updateCurrentBidder(bidder?:false|null|{id:number, token:string}):void;

    connect(): Promise<any>;
    disconnect(): Promise<any>;

    onConnectionError(callbackFn:()=>void):()=>void;
    onConnectionStateChange(callbackFn:(currentState:PusherConnectionStatesEnum, previousState:PusherConnectionStatesEnum)=>void):()=>void;

    onAuctionVisibilityChange(callbackFn:(data:{ auctionId:number, isVisible:boolean })=>void):()=>void;
    onAuctionDescriptiveChange(callbackFn:(data:{ auctionId:number })=>void):()=>void;
    onAuctionBiddingWarrantStateChange(callbackFn:(data:{ auctionId:number, state:string })=>void):()=>void;

    onAuctionLotsChange(auctionId:number, callbackFn:(data:{ lotId:number })=>void):()=>void;
    onMyLotsInterestChange(callbackFn:(data:{ auctionId: number, lotId: number, isWatched:boolean })=>void);
}

