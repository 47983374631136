// https://immerjs.github.io/immer/installation/
import {enableMapSet} from "immer";
enableMapSet();


import AuctionsManager, {WebcastAuctionConnectData_TypeDef, WebcastAuctionHandler} from "./services/AuctionsManager";
import DataStore, {ReduxStateType} from "./services/DataStore";
import {ReduxActiveWebcastSubStateType} from "./redux/reducerActiveWebcast";
import { WebcastStateEnum, RoundTypeEnum, LotStateEnum, RoundStopTypeEnum } from "./data/WebcastDataDomain";


export {
    AuctionsManager,
    DataStore,
    WebcastStateEnum,
    WebcastAuctionHandler,
    RoundTypeEnum,
    LotStateEnum,
    RoundStopTypeEnum
};


export type {
    WebcastAuctionConnectData_TypeDef,
    ReduxStateType as WebcastReduxState_TypeDef,
    ReduxActiveWebcastSubStateType as WebcastReduxActiveWebcastSubState_TypeDef
};


