import { ActionsCreators } from "../actions";
import {ChatMessage, ChatRoom, PMAuctionDataSnapshot} from "../../data/data_types_definitions";
import {WebcastAuctionConnectData_TypeDef} from "../../services/AuctionsManager";
import {createReducer} from "@reduxjs/toolkit";

export type ReduxWebcastChatType = {
    myUserInfo: { userId: number, userRole: string, userRoleIsClerk: boolean, senderUserId:number },
    chatRooms: Array<ChatRoom>,
    chatRoomsById: Map<number, ChatRoom>,
    activeChatRoomId: number,
};


const initialState: ReduxWebcastChatType = {
    myUserInfo: null,
    chatRooms: [],
    chatRoomsById: new Map<number, ChatRoom>(),
    activeChatRoomId: null,
};




/**
 * combined as `webcastChat`
 *
 * ===
 * action: WEBCAST_AUCTION_INIT
 * payload: <SaleConnectDataType> {
 *      participantId: number,
 *      userRole: string
 * }
 *
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMAuctionDataSnapshot> {
 *     chatRooms: Array<ChatRoom>,  // for Bidder, this array will have at most one element
 * }
 *
 *
 * ===
 * action: WEBCAST_CHAT_MESSAGE_POST
 * payload: <ChatMessage> {
 *     roomId: number,
 *     sender: number,
 *     timestamp: number,
 *     body: string
 * }
 *
 *
 *
 * ===
 * action: WEBCAST_CHAT_ROOM_OPEN_AND_READ
 * payload: {
 *     roomId: number
 * }
 *
 *
 * ==
 * action: WEBCAST_CHAT_ROOM_CLOSE
 * payload: {
 *     roomId: number
 * }
 *
 *
 *
 *
 * @param state
 * @param action
 * @returns {{
 *      myUserInfo: { participantId: number, userRole: string, userRoleIsClerk: boolean, senderUserId: number },
 *      chatRooms: Array<ChatRoom>,
 *      chatRoomsById: Map<number, ChatRoom>
 *      activeChatRoomId: number,
 * }}
 */


const reducerWebcastChat = createReducer<ReduxWebcastChatType>(initialState, (builder) => {
    builder.addCase(ActionsCreators.webcast.webcastAuctionInit, (state, action) => {
        const saleConnectData:WebcastAuctionConnectData_TypeDef = action.payload;
        const userIsClerk = saleConnectData.participantRole === 'clerk';

        state.chatRoomsById = initialState.chatRoomsById;
        state.chatRooms = initialState.chatRooms;
        state.activeChatRoomId = initialState.activeChatRoomId;

        state.myUserInfo = {
            userId: saleConnectData.participantId,
            userRole: saleConnectData.participantRole,
            userRoleIsClerk: userIsClerk,
            senderUserId: userIsClerk ? 0 : saleConnectData.participantId,
        }
    });

    builder.addCase(ActionsCreators.webcast.webcastAuctionDataSnapshotInit, (state, action) => {
        const payload: PMAuctionDataSnapshot = action.payload;
        const myUserInfo = state.myUserInfo;
        const payloadChatRoomsMap:Map<number, ChatRoom> = new Map<number, ChatRoom>(payload.chatRooms.map(r => [r.id, r]));
        const chatRooms:Array<ChatRoom> = [];

        // all users have the BROADCAST chatroom (although bidders can only read and cannot send to it)
        const payloadBroadcastChatRoom:ChatRoom = payloadChatRoomsMap.get(-1) || { messages: null };

        const broadcastChatRoom:ChatRoom = {
            id: -1,
            messages: payloadBroadcastChatRoom.messages || [],
            lastReadTimestamp: 0,
            unreadCount: 0,
        };

        chatRooms.push(broadcastChatRoom);

        // process with all the other received (payload) ChatRooms
        payloadChatRoomsMap.forEach((payloadChatRoom:ChatRoom) => {

            // skip the special chatrooms we processed already
            if (payloadChatRoom.id === 0 || payloadChatRoom.id === -1) {
                return;
            }

            // each non special chat-room gets the broadcast messages included
            const roomMessages:Array<ChatMessage> = [].concat(payloadChatRoom.messages || [], broadcastChatRoom.messages);

            // sort ascending by timestamp
            roomMessages.sort((m1:ChatMessage, m2:ChatMessage) => {
                return m1.timestamp - m2.timestamp;
            });


            chatRooms.push({
                id: payloadChatRoom.id,
                messages: roomMessages,
                lastReadTimestamp: 0,
                unreadCount: 0,
            });

        });


        if (myUserInfo.userRoleIsClerk) {
            // for the Clerk user, we need to add the "special" ADMIN chatroom

            const payloadAdminsChatRoom: ChatRoom = payloadChatRoomsMap.get(0) || { messages: null };

            // the "ADMINS" room

            chatRooms.push({
                id: 0,
                messages: payloadAdminsChatRoom.messages || [],
                lastReadTimestamp: 0,
                unreadCount: 0,
            });
        }
        else if ( ! payloadChatRoomsMap.get(myUserInfo.userId)) {
            // ensure own ChatRoom exists, and create empty if not
            chatRooms.push({
                id: myUserInfo.userId,
                messages: [],
                lastReadTimestamp: 0,
                unreadCount: 0,
            })
        }

        chatRooms.sort(chatRoomsSortFunc);

        state.chatRooms = chatRooms;
        state.chatRoomsById = new Map(chatRooms.map((r) => [r.id, r]));
    });



    builder.addCase(ActionsCreators.webcast.webcastChatMessagePost, (state, action) => {
        const chatMessage:ChatMessage = action.payload;

        let updatedChatRooms:Array<ChatRoom>;

        if (chatMessage.roomId === -1) {
            // BROADCAST

            // update all chat rooms, including the "BROADCAST" room (id= -1)
            updatedChatRooms = state.chatRooms.map((chatRoom) => {
                if (chatRoom.id === 0) {
                    // skip adding the message updates for the ADMIN chat room
                    return chatRoom;
                }

                const newChatMessages = [...chatRoom.messages, chatMessage];

                // if "my user is clerk", then don't update the unread count
                if (state.myUserInfo.userRoleIsClerk) {

                    // TODO
                    return {
                        ...chatRoom,
                        messages: newChatMessages,
                    };
                }

                // for a bidder, update the unreadCount
                let newUnreadCount;
                if (chatMessage.sender === state.myUserInfo.userId) {
                    // if sent by "myself", then reset to 0
                    newUnreadCount = 0;
                }
                else {
                    // if sent by "the other guy", then increase
                    newUnreadCount = (chatRoom.unreadCount || 0) + 1;
                }

                // TODO
                return {
                    ...chatRoom,
                    messages: newChatMessages,
                    unreadCount: newUnreadCount,
                }
            });

            state.chatRooms = updatedChatRooms;
            state.chatRoomsById = new Map(updatedChatRooms.map((r) => [r.id, r]));

        }
        else {
            // non-broadcast, message sent to room by participant (sender can be either:  room-owner (i.e. bidder/viewer) or clerk)
            let chatRoom:ChatRoom = state.chatRoomsById.get(chatMessage.roomId);

            if (chatRoom) {
                // if chatRoom exists already in the list, then just change its object reference
                chatRoom = { ... chatRoom };

                // and replace it in the list
                updatedChatRooms = state.chatRooms.map((cr) => {
                    return (cr.id === chatMessage.roomId) ? chatRoom : cr;
                })
            }
            else {
                chatRoom = {
                    id: chatMessage.roomId,
                    messages: [],
                    lastReadTimestamp: 0,
                    unreadCount: 0,
                };

                updatedChatRooms = [ chatRoom, ...state.chatRooms ];
            }



            if (chatMessage.sender === state.myUserInfo.senderUserId) {
                // sender is "myself" => reset unreadCount
                chatRoom.lastReadTimestamp = Date.now();
                chatRoom.unreadCount = 0;
            }
            else {
                // sender is "the other guy" => increase unreadCount
                chatRoom.unreadCount = (chatRoom.unreadCount || 0) + 1;
            }


            // update the chatRoom with the new message and unreadCount, keep object identity here
            chatRoom.isClosed = false; // re-open to show in the Clerk's list
            chatRoom.messages = [ ...chatRoom.messages, chatMessage ];


            updatedChatRooms.sort(chatRoomsSortFunc);

            state.chatRooms = updatedChatRooms;
            state.chatRoomsById = new Map(updatedChatRooms.map((r) => [r.id, r]));

        }
    });



    builder.addCase(ActionsCreators.webcast.webcastChatRoomOpenAndMarkAsRead, (state, action) => {

        const roomId:number = action.payload;

        let newChatRoom:ChatRoom = null;


        const updatedChatRooms = state.chatRooms.map((chatRoom:ChatRoom) => {
            if (chatRoom.id !== roomId) {
                return chatRoom;
            }
            else {
                newChatRoom = {
                    ... chatRoom,
                    isClosed: false,
                    unreadCount: 0,
                    lastReadTimestamp: Date.now(), // in milliseconds
                };
                return newChatRoom
            }
        });




        if (! newChatRoom) {
            newChatRoom = {
                id: roomId,
                messages: [],
                unreadCount: 0,
                lastReadTimestamp: Date.now(), // in milliseconds
            };

            updatedChatRooms.push(newChatRoom);
        }

        updatedChatRooms.sort(chatRoomsSortFunc);

        state.activeChatRoomId = newChatRoom.id;
        state.chatRooms = updatedChatRooms;
        state.chatRoomsById = new Map(updatedChatRooms.map((r) => [r.id, r]));
    });

    builder.addCase(ActionsCreators.webcast.webcastChatRoomClose, (state, action) => {
        const roomId:number = action.payload;

        const updatedChatRooms = state.chatRooms.map((chatRoom:ChatRoom) => {
            if (chatRoom.id === roomId) {
                return {
                    ... chatRoom,
                    unreadCount: 0,
                    isClosed: true,
                }
            }
            return chatRoom;
        });

        state.activeChatRoomId = null;
        state.chatRooms = updatedChatRooms;
        state.chatRoomsById = new Map(updatedChatRooms.map((r) => [r.id, r]));
    });

});






function chatRoomsSortFunc(r1:ChatRoom, r2:ChatRoom) {
    // to sort descending, r1 needs to return smaller number

    if (r1.id === -1 || r2.id === -1) {
        // the 'ALL' (BROADCAST) room is always 1st
        return r1.id === -1 ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
    }
    else if (r1.id === 0 || r2.id === 0) {
        // the 'ADMIN' room is always 2nd
        return r1.id === 0 ? Number.MIN_SAFE_INTEGER + 1 : Number.MAX_SAFE_INTEGER - 1;
    }
    else {
        // if both have unreadCount ...
        if (r1.unreadCount > 0 && r2.unreadCount > 0) {
            // sort by lastReadTimestamp descending
            return (r1.lastReadTimestamp > r2.lastReadTimestamp) ? -20000000000 : 20000000000;
        }
        // if both don't have unread ...
        else if (r1.unreadCount === 0 && r2.unreadCount === 0) {
            // sort by roomId descending
            const r1id = r1.id < 0 ? Math.abs(r1.id) * 10000000000 : r1.id;
            const r2id = r2.id < 0 ? Math.abs(r2.id) * 10000000000 : r2.id;
            return (r1id > r2id) ? 1 : -1;
        }
        else {
            // only one has unread count > 0, goes at the top
            return r1.unreadCount > 0 ? -20000000000 : 20000000000;
        }
    }
}

export default reducerWebcastChat;
