import Pusher, {Channel} from 'pusher-js';
import {
    IPusherAdapter,
    PusherConnectionStatesEnum,
    TaggedLogger,
    SingleTypeEventBus,
    CONSTANTS,
    PusherChannelNames
} from "@nextlot/core";


const _logger = TaggedLogger.get('PusherAdapterWebJS');

export default class PusherAdapterWebJS implements IPusherAdapter {
    private readonly siteId: number;
    private readonly config: { siteId: number; pusherAppKey: string; pusherCluster: string; frontendApiBaseUrl: string };
    private _pusher: Pusher;
    private _pusherChannel_privateEventsForBidder: Channel;
    private _pusherChannel_publicEventsForSiteAuctions: Channel;
    private readonly _connectionErrorEventBus: SingleTypeEventBus<any>;


    constructor(config:{ siteId:number, pusherAppKey:string, pusherCluster:string, frontendApiBaseUrl:string }) {
        this.siteId = config.siteId;
        this.config = config;

        this._connectionErrorEventBus = new SingleTypeEventBus<void>();
    }


    async init(currentBidder?:{ id:number, apiToken:string }) {
        if (this._pusher) {
            // ensure init can be called multiple times
            this._pusher.disconnect();
            //TODO: cleanup all bindings !!!
        }

        let authHeaders = {};
        if (currentBidder?.apiToken) {
            authHeaders = { [CONSTANTS.HEADER_BIDDER_TOKEN]: currentBidder.apiToken }
        }

        _logger.debug('.init() authHeaders:', authHeaders ? 'PRESENT' : 'NOT present');

        this._pusher = new Pusher(this.config.pusherAppKey, {
            cluster: this.config.pusherCluster,
            channelAuthorization: {
                transport: 'ajax',
                endpoint: `${this.config.frontendApiBaseUrl}/sites/${this.siteId}/pusher/auth`,
                headers: authHeaders,
            },
        });


        const connectionFailureCallbackFn = () => {
            this._connectionErrorEventBus.emit();
            this._connectionErrorEventBus.offAll(); // emit only once to all subscribers
        }

        const connectionStateChangeCallbackFn = (stateChange:{current, previous}) => {
            const currentState = stateChange.current;
            const previousState = stateChange.previous;
            _logger.debug('>>on:connection.state_change: current:', currentState, ' <-', previousState);

            if (previousState === PusherConnectionStatesEnum.connected ||
                ['failed', 'unavailable', 'disconnected'].includes(currentState)) {
                connectionFailureCallbackFn();
            }
        };

        this._pusher.connection.bind("error", connectionFailureCallbackFn);
        this._pusher.connection.bind("state_change", connectionStateChangeCallbackFn);


        this._pusherChannel_publicEventsForSiteAuctions = this._pusher.subscribe(PusherChannelNames.publicEventsForSiteAuctions(this.siteId));

        this._pusherChannel_privateEventsForBidder = currentBidder
            ? this._pusher.subscribe(PusherChannelNames.privateEventsForBidder(this.siteId, currentBidder.id))
            : null;


        if (this._pusherChannel_privateEventsForBidder) {
            this._pusherChannel_privateEventsForBidder.bind('pusher:subscription_error', function(error) {
                _logger.error('>_pusherChannel_privateEventsForBidder>>subscription_error: ', error);
                connectionFailureCallbackFn();
            });
        }


        (window as any)._pusherClient = this._pusher;
    }


    updateCurrentBidder(bidder?: false | { id: number; token: string } | null) {
        //TODO: update pusher channelAuthorization ...
    }


    connect(): Promise<any> {
        this._pusher.connect();
        return Promise.resolve();
    }

    disconnect(): Promise<any> {
        this._pusher.disconnect();
        return Promise.resolve();
    }


    onConnectionError(callbackFn: () => void): () => void {
        return this._connectionErrorEventBus.on(callbackFn);
    }


    onAuctionVisibilityChange(callbackFn: (data: { auctionId: number; isVisible: boolean; }) => void): () => void {
        _logger.debug('.onAuctionVisibilityChange channel:', this._pusherChannel_publicEventsForSiteAuctions.name);
        this._pusherChannel_publicEventsForSiteAuctions.bind('visibility', callbackFn);
        return () => {
            this._pusherChannel_publicEventsForSiteAuctions.unbind('visibility', callbackFn);
        }
    }

    onAuctionDescriptiveChange(callbackFn: (data: { auctionId: number; }) => void): () => void {
        _logger.debug('.onAuctionDescriptiveChange: channel:', this._pusherChannel_publicEventsForSiteAuctions.name);
        this._pusherChannel_publicEventsForSiteAuctions.bind('descriptive', callbackFn);
        return () => {
            this._pusherChannel_publicEventsForSiteAuctions.unbind('descriptive', callbackFn);
        }
    }

    onAuctionBiddingWarrantStateChange(callbackFn: (data: { auctionId: number; state: string; }) => void): () => void {
        if (! this._pusherChannel_privateEventsForBidder) {
            // this is here to allow this method to be called w/o any effect for "visitors" (when bidder is not authenticated)
            return ()=>{}; // noop
        }
        _logger.debug('.onAuctionBiddingWarrantStateChange: channel:', this._pusherChannel_privateEventsForBidder.name);
        this._pusherChannel_privateEventsForBidder.bind('warrant', callbackFn);
        return () => {
            this._pusherChannel_privateEventsForBidder.unbind('warrant', callbackFn);
        }
    }

    onAuctionLotsChange(auctionId: number, callbackFn: (data: { lotId: number; }) => void): () => void {
        const publicChannel = this._pusher.subscribe(PusherChannelNames.publicEventsForAuctionLots(this.siteId, auctionId));
        _logger.debug('.onAuctionLotsChange: auctionId:', publicChannel.name);
        publicChannel.bind('lot', callbackFn);
        return () => {
            publicChannel.unbind('lot', callbackFn);
        }
    }

    onMyLotsInterestChange(callbackFn: (data: { auctionId: number; lotId: number; isWatched: boolean; }) => void) {
        _logger.debug('.onMyLotsInterestChange:');
        if (! this._pusherChannel_privateEventsForBidder) {
            return ()=>{};
        }

        this._pusherChannel_privateEventsForBidder.bind('lot-interest', callbackFn);

        return () => {
            this._pusherChannel_privateEventsForBidder.unbind('lot-interest', callbackFn);
        }
    }



    onConnectionStateChange(callbackFn: (currentState:PusherConnectionStatesEnum, previousState:PusherConnectionStatesEnum) => void): () => void {
        if (!callbackFn) {
            throw '.onConnectionStateChange: invalid argument callbackFn';
        }
        const handlerFn = (stateChange:{ previous:PusherConnectionStatesEnum, current:PusherConnectionStatesEnum })=> {
            callbackFn(stateChange.current, stateChange.previous);
        };
        this._pusher.bind("state_change", handlerFn);
        return () => {
            this._pusher.unbind("state_change", handlerFn);
        }
    }

}
