import React from "react";
import {AppController} from "@nextlot/core";
import {LotMetal_ModelTypeDef} from "@nextlot/core/type_defs/model";


export default function LotWatchWidgetButton({ lot }:{ lot:LotMetal_ModelTypeDef }) {

    const [isSending, setIsSending] = React.useState(false);

    const handleClick_toggleWatched = React.useCallback(() => {
        setIsSending(true);
        AppController.instance.remoteDataHandler.submitLotInterest(lot.auctionId, lot.id, !lot.myWatched)
            .finally(() => {
                setIsSending(false);
            });
    }, [lot.id, lot.myWatched]);


    if (typeof(lot.myWatched) !== 'boolean') {
        // when null or undefined => there is no current_bidder (not authenticated)
        return null;
    }


    return (
        <button type='button' className='px-0 btn btn-sm' title='Watch' onClick={handleClick_toggleWatched} disabled={isSending}>
            {
                lot.myWatched
                    ? <><small>Watched</small> &nbsp; <i className="fa-solid fa-star-sharp fa-lg fa-fw text-primary" /></>
                    : <i className="fa-light fa-star-sharp fa-lg fa-fw" />
            }
        </button>
    );
}
