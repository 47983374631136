import React, {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {Auction_ModelTypeDef, BiddingWarrantState_TypeDef} from "@nextlot/core/type_defs/model";
import ReactModal from "react-modal";
import {TaggedLogger} from "@nextlot/core";

const _logger = TaggedLogger.get('MyBiddingWarrantStateButtonLabel');


type CmpProps = {
    auction: Auction_ModelTypeDef
}

export function MyBiddingWarrantStateButtonLabel(props:PropsWithChildren<CmpProps>) {
    const auction: Auction_ModelTypeDef = props.auction;
    const auctionId: number = auction.id;
    const myBiddingWarrantState: BiddingWarrantState_TypeDef|null = auction.myBiddingWarrantState;


    if (! auctionId || auction.isCompleted) {
        return null;
    }

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    if (myBiddingWarrantState) {
        _logger.debug("MyBiddingWarrantStateButtonLabel: myBiddingWarrantState - ", myBiddingWarrantState);
        if (myBiddingWarrantState.isAllowed) {
            return props.children as JSX.Element || (<span className="btn btn-link text-decoration-none text-success fw-bold pe-none">Approved to bid</span>);
        }
        else if (myBiddingWarrantState.isDeclined) {
            return <span className="btn btn-outline-danger disabled">Declined to bid</span>
        }
        else if (myBiddingWarrantState.isPendingResponse) {
            return <span className="btn btn-secondary disabled">Pending approval</span>
        }
        else if (myBiddingWarrantState.isMustAcceptTerms) {
            return <Link className="btn btn-primary" to={`/${auctionId}/warrant`}>Accept terms to bid</Link>
        }
        else if (myBiddingWarrantState.isMustSetupAccount || myBiddingWarrantState.isMustSetupCard) {
            return (
                <>
                    <ReactModal
                        isOpen={isModalOpen}
                        onRequestClose={() => (setIsModalOpen(false))}
                        contentLabel="Request to bid"

                        portalClassName={'rmodal__portal'}
                        overlayClassName={{ base: 'rmodal__overlay', afterOpen: 'rmodal__overlay--after-open', beforeClose: '' }}
                        className={'rmodal__dialog'}
                        bodyOpenClassName={'rmodal__html-body--open'}
                        style={{
                            content: {
                                maxWidth: '500px',
                                height: 'auto',
                                borderRadius: "6px"
                            },
                        }}
                    >
                        <div className="d-flex justify-content-between mx-3 mt-3">
                            <h5 className="">Request to bid</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => (setIsModalOpen(false))} />
                        </div>
                        <hr />
                        <div className="alert alert-danger mx-3" role="alert">
                            You must update your account information before bidding
                        </div>
                        <hr />
                        <div className="d-flex justify-content-end mx-3 mb-3 gap-2 row">
                            <button type="button" className="btn btn-secondary col-lg-auto" onClick={() => (setIsModalOpen(false))}>Close</button>
                            <a className="btn btn-primary col-lg-auto" href={`${NextLotJSDATA.webapp_root_base_url}/account?auction_id=${auctionId}`}>Update profile</a>
                        </div>

                    </ReactModal>

                    <button type="button" onClick={() => (setIsModalOpen(true))} className="btn btn-primary">
                        {
                            myBiddingWarrantState.isMustSetupAccount ? "Request to bid" : "Update profile"
                        }
                    </button>
                </>

            )
        } else if (myBiddingWarrantState.isMustRequest) {
            return <Link className="btn btn-primary" to={`/${auctionId}/warrant`}>Request to bid</Link>
        }
    }
    else {
        return <a className="btn btn-primary" href={NextLotJSDATA.webapp_bidder_login_url}>Register to bid</a>;
    }
}

