import {DateTime, Duration} from "luxon";


// const LOCALE = Intl.NumberFormat().resolvedOptions().locale;

export function formatDateTime(dateTime:DateTime, formatString:string = DATETIME_FORMAT_STRINGS.AUCTION_CARD) {
    return dateTime ? dateTime.toFormat(formatString) : ''
}


export function formatBidExtensionDurationSeconds(seconds:number|undefined):string {
    if (! seconds) {
        return '';
    }

    const minutes = Math.floor(seconds / 60);
    const secondsReminder = seconds % 60;

    return [
        (minutes > 0 ? `${minutes} min` : null),
        (secondsReminder > 0 ? `${secondsReminder} s` : null)
    ].filter(v => v).join(' ');
}


export enum DATETIME_FORMAT_STRINGS {
    AUCTION_CARD = "LLL d, y 'at' h:mma ZZZZ",
    AUCTION_HEADER = "LLL d, y 'at' h:mma ZZZZ",
    LOT_CARD_WITH_WEEKDAY = "ccc, LLL d",
    LOT_CARD_TIME_SIMPLE = "h:mma ZZZZ",
    LOT_CARD_SEARCH = "LLL d y 'at' h:mma ZZZZ",
}



export function formatDurationAsHumanString(duration:Duration):string {
    const durObj = duration.shiftTo('hours', 'minutes', 'seconds').toObject();
    durObj.seconds = Math.floor(durObj.seconds); // use `floor` to account for computational and network times
    const strHours = durObj.hours !== 0 ? `${Math.abs(durObj.hours)}h` : null;
    const strMinutes = (strHours || durObj.minutes !== 0) ? `${Math.abs(durObj.minutes)}m` : null;
    const strSeconds = `${Math.abs(durObj.seconds)}s`;
    return [strHours, strMinutes, strSeconds].filter(v => v).join(' ');
}
