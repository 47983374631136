import {runOnDOMContentLoaded, TaggedLogger} from "@nextlot/core";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";

const _logger = TaggedLogger.get('FormBillingPaymentCard');


export const FormBillingPaymentCard = {
    mount: mount
}



function handleHostedIframeTokenizer(tokenFrame) {
    const tokenizerIframeContentWindow = tokenFrame.contentWindow;
    const elForm = document.getElementById('form_billing_payment_card') as HTMLFormElement;
    const elInputToken:HTMLInputElement = elForm.querySelector('input[name="tokenizer[cc_token]"]') as HTMLInputElement;
    const elInputExpiry:HTMLInputElement = elForm.querySelector('input[name="tokenizer[cc_expiry]"]') as HTMLInputElement;
    const elBtnSubmit:HTMLButtonElement = elForm.querySelector('button[type="submit"]') as HTMLButtonElement;
    const elSpanErrorsText:HTMLSpanElement = elForm.querySelector('#billing_card_errors_text') as HTMLSpanElement;
    const elDisclaimer:HTMLInputElement = elForm.querySelector('#disclaimer_accepted') as HTMLInputElement;
    const hasDisclaimerCheckbox:boolean = !(elDisclaimer === null);

    if (hasDisclaimerCheckbox) {
        elDisclaimer.addEventListener("click", (event) => {
            elBtnSubmit.disabled = !(elDisclaimer.checked && elInputToken.value.length > 0);
        });
    }

    // tokenizerIframeContentWindow.addEventListener('load', (evt) => {
    //     // elBtnSubmit.disabled = true;
    // });


    window.addEventListener('message', (event) => {
        if (event?.source !== tokenizerIframeContentWindow) {
            return;
        }

        _logger.debug('.handleHostedIframeTokenizer::contentWindow>>message', event);

        if (event?.data) {
            try {

                const msgData: { token?: string, expiry?: string, errorCode?: string, errorMessage?: string } = JSON.parse(event.data);

                if (msgData.errorCode === '0' && msgData.token) {
                    elSpanErrorsText.innerText = '';
                    elInputToken.value = msgData.token;
                    elInputExpiry.value = msgData.expiry;
                    if (hasDisclaimerCheckbox) {
                        elBtnSubmit.disabled = !(elDisclaimer.checked);
                    } else {
                        elBtnSubmit.disabled = false;
                    }
                } else if (msgData.errorMessage) {
                    elSpanErrorsText.innerText = msgData.errorMessage;
                    elInputToken.value = null;
                    elInputExpiry.value = null;
                    elBtnSubmit.disabled = true;
                }

            }
            catch (ex) {
                _logger.error('.handleHostedIframeTokenizer FAILED', ex.toString());
            }
        }
    }, false);
}

function handleFormBillingPaymentACH(elBillingPaymentACH) {
    const accountDetails :HTMLDivElement = elBillingPaymentACH.querySelector("#account_details") as HTMLDivElement;

    const formBillingPaymentACH:HTMLFormElement = elBillingPaymentACH.querySelector("#form_billing_payment_ach") as HTMLFormElement;
    const elRoutingNumber:HTMLInputElement = elBillingPaymentACH.querySelector('input[name="routing_number"]') as HTMLInputElement;
    const elAccountNumber:HTMLInputElement = elBillingPaymentACH.querySelector('input[name="account_number"]') as HTMLInputElement;
    const elAchToken:HTMLInputElement = formBillingPaymentACH.querySelector('input[name="ach_token"]') as HTMLInputElement;
    const elBtnSubmit:HTMLButtonElement = formBillingPaymentACH.querySelector('button[type="submit"]') as HTMLButtonElement;
    const elSpanErrorsText: HTMLSpanElement = formBillingPaymentACH.querySelector('#billing_ach_errors_text') as HTMLSpanElement;

    elBtnSubmit.disabled = false;

    function tokenizeRequest(routingNumber, accountNumber) {
        const requestBody = JSON.stringify({"account" : routingNumber + "/" + accountNumber});

        const xhr = new XMLHttpRequest();
        xhr.open("POST", NextLotJSDATA.cardpointe_tokenizer_api_base_url, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(requestBody);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const response: { token?: string, errorcode?: number, message?: string } = JSON.parse(xhr.response);
                if (xhr.status === 200 && response.errorcode === 0) {
                    elAchToken.value = response.token;
                    elSpanErrorsText.innerText = '';
                    formBillingPaymentACH.submit();
                } else {
                    elAchToken.value = '';
                    elSpanErrorsText.innerText = response.message;
                }

            }
        };

        xhr.ontimeout = (e) => {
            elSpanErrorsText.innerText = 'Connection timed out, please try again';
        }
    }

    elBtnSubmit.addEventListener("click", function(event) {
        event.preventDefault();
        event.stopPropagation();

        elSpanErrorsText.innerText = '';
        const routingNumber = elRoutingNumber.value;
        const accountNumber = elAccountNumber.value;

        if(routingNumber && accountNumber) {
            accountDetails.classList.remove('was-validated');
            tokenizeRequest(routingNumber, accountNumber);
        } else {
            accountDetails.classList.add('was-validated');
        }
    }, false);
}

function mount() {

    runOnDOMContentLoaded(() => {


        const tokenizerFrameEl = document.getElementById('iframe_cardpointe_tokenizer');
        if (tokenizerFrameEl) {
            handleHostedIframeTokenizer(tokenizerFrameEl);
        }

        const elBillingPaymentACH: HTMLElement = document.getElementById('billing_payment_ach');
        if (elBillingPaymentACH) {
            handleFormBillingPaymentACH(elBillingPaymentACH);
        }

    })

}
