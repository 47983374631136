import $ from "jquery";
import {Dropdown as BsDropdown} from "bootstrap";
import {Tooltip as BsTooltip} from "bootstrap";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {runOnDOMContentLoaded, loadExternalJsScript} from "@nextlot/core/utilities";
import BidWebApp from "./BidWebApp";
import {FormBillingPaymentCard} from "./public_pages/form_billing_cardpointe";
import {DetectedBrowserDevice} from "./DetectedBrowserDevice";
import {startStimulusApp} from "./stimulus_controller/app";

import {TaggedLogger} from "@nextlot/core/utilities";


declare global {
    const hbspt: any  // HubSpot related
}

interface hsBidderSupportFormPrefillIfLoggedInProps {
    formElem: HTMLFormElement,
    hsFormIdScoped: string,
    formAndData: Array<{
        formIdentifier: string,
        dataIdentifier: string // e.g. NextLotJSDATA.bidder_last_name
    }>
}

const _logger = TaggedLogger.get('PublicWebApp:index');


startStimulusApp();

BidWebApp.mount();


runOnDOMContentLoaded(function () {
    $('input[type="checkbox"][data-unchecked-disable-submit]').click(function () {
        const elChkBox$ = $(this);
        const elBtnSubmit$ = elChkBox$.closest('form').find(':submit');
        elBtnSubmit$.prop('disabled', !elChkBox$.prop('checked'));
    });

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map(tooltipTriggerEl => new BsTooltip(tooltipTriggerEl));

    const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
    [...dropdownElementList].map(dropdownToggleEl => new BsDropdown(dropdownToggleEl));


    const hsBidderSupportFormElem: HTMLFormElement= document.querySelector('#bidder_form_support_form') as HTMLFormElement;
    if(hsBidderSupportFormElem) {
        handleHsBidderSupportForm();
    } else {
        _logger.debug('Bidder support form container not found - default: `#bidder_form_support_form`; HubSpot form not initializing.')
    }


    let addressForm = $(".js-has-address-fields");
    if (addressForm.length) {
        $(".js-country-code-field").change(function() {
            let countryCode = this.value;
            $.get('/account/address_country_regions/' + countryCode, function(response) {
                $(".js-region-field").html(response);
            });
        });
    }


    const forms = document.querySelectorAll('.needs-validation')
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        });


    const browserDevice = new DetectedBrowserDevice();
    _logger.debug('>>isMobileDevice: CHECK: ', browserDevice.isMobile);

    if (browserDevice.isMobile) {
        // rcugut//2023-04-19// enable this (i.e. site-wide) only when the mobile app is available for types of auctions (ex: Timed);
        // for now, only show the mobile app link in the webcast auction card
        // document.querySelectorAll('.check-if-mobile-device').forEach(el => {
        //     el.classList.remove('check-if-mobile-device');
        // })

        try {
            let pwaPopupContainer;
            if (browserDevice.isIos) {
                pwaPopupContainer = browserDevice.isSafari ? document.querySelector('.check-if-mobile-device-pwa.is-pwa-ios') : null;
            }
            else {
                pwaPopupContainer = document.querySelector('.check-if-mobile-device-pwa.is-pwa-android');
            }

            if (pwaPopupContainer) {
                const isPwaStandalone = browserDevice.isIos ? browserDevice.isPwaStandaloneIos : browserDevice.isPwaStandaloneAndroid;
                const isPwaPopupHidden = !!window.localStorage.getItem(`NL:${window.location.hostname}:PWAPOPUP:hidePopup`);
                if (!isPwaStandalone && !isPwaPopupHidden) {
                    pwaPopupContainer.classList.remove('check-if-mobile-device-pwa');
                    const pwaPopupCloseBtn = pwaPopupContainer.querySelector('.btn-close');
                    pwaPopupCloseBtn && pwaPopupCloseBtn.addEventListener('click', function () {
                        window.localStorage.setItem(`NL:${window.location.hostname}:PWAPOPUP:hidePopup`, 'yes');
                        pwaPopupContainer.classList.add('check-if-mobile-device-pwa');
                    });
                }
            }
        }
        catch(ex) {
            _logger.error('PWA::BANNER::ERROR: ', ex);
        }
    }

});



FormBillingPaymentCard.mount();


function hsBidderSupportFormPrefillIfLoggedIn(props:hsBidderSupportFormPrefillIfLoggedInProps) {

    const {formElem, hsFormIdScoped, formAndData} = props;
    const cssVisuallyHidden = `
                                visibility: hidden,
                                pointer-events: none;
                                width: 0;
                                height: 0;
                                position: absolute;
                                top: -999px;
                                z-index: -1;
                            `;

    for(const formData of formAndData) {
        const hsFormInputBidderField: HTMLInputElement = formElem.querySelector(`input[id="${formData.formIdentifier}-${hsFormIdScoped}"]`) as HTMLInputElement;
        if(hsFormInputBidderField) {
            if(!formData.dataIdentifier) {
                return;
            }

            hsFormInputBidderField.value = formData.dataIdentifier;
            hsFormInputBidderField.dispatchEvent(new Event('input', { bubbles: true }));

            const parent: HTMLDivElement = hsFormInputBidderField.closest(`.hs_${formData.formIdentifier}`) as HTMLDivElement;
            parent.style.cssText = cssVisuallyHidden;
        } else {
            _logger.error(`> HubSpot embedded ${formData.formIdentifier} input field does not exists. Check the source form in HubSpot!`)
        }

    }
}

function handleHsBidderSupportForm() {

    const hsScriptUrl = 'https://js.hsforms.net/forms/embed/v2.js';
    const hsFormIdScoped = '09bc2b35-235a-48f4-8f06-edfaaf92318e';

    const onLoadFn = () => {
        // https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options

        hbspt.forms.create({
            portalId: '22310321',
            formId: hsFormIdScoped,
            target: '#bidder_form_support_form',
            onFormReady: function ($form: HTMLFormElement) {


                const hsFormLoadingElem: HTMLDivElement = document.querySelector('#bidder_form_support_form_loading');
                hsFormLoadingElem.style.display = 'none';

                hsBidderSupportFormPrefillIfLoggedIn({
                    formElem: $form,
                    hsFormIdScoped: hsFormIdScoped,
                    formAndData:[
                        {
                            formIdentifier: 'firstname',
                            dataIdentifier: NextLotJSDATA.bidder_first_name
                        },
                        {
                            formIdentifier: 'lastname',
                            dataIdentifier: NextLotJSDATA.bidder_last_name
                        },
                        {
                            formIdentifier: 'email',
                            dataIdentifier: NextLotJSDATA.bidder_email
                        },
                        {
                            formIdentifier: 'phone',
                            dataIdentifier: NextLotJSDATA.bidder_phone_number
                        },
                    ]
                });
            }
        });
    }

    loadExternalJsScript(hsScriptUrl, onLoadFn);
}


