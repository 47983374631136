import React from "react";
import {TaggedLogger} from "@nextlot/core/utilities";
import {
    useRemoteUpdatingAuctionLotsChangeListener,
    useRemoteUpdatingLotsInterestChangeListener
} from "@nextlot/core/hooks/CurrentAuctionLotsListHook";
import {useCurrentAuction} from "@nextlot/core/hooks/CurrentAuctionHooks";
import LotCard, {BiddingContextViewEnum} from "../components/LotCard";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "@nextlot/core/rematch/store";
import AppController from "@nextlot/core/AppController";
import {CurrentAuctionLotsModelState} from "@nextlot/core/rematch/models/currentAuctionLotsModel";


const _logger = TaggedLogger.get('TimedAuctionBiddingStaggeredLotsPage');



export default function TimedAuctionBiddingStaggeredLotsPage() {

    const auction = useCurrentAuction();
    const {id:auctionId, timedLotsStaggeringGroupSize, timedLotsStaggeringOffsetSeconds} = auction;

    const dispatchStore = useDispatch<Dispatch>();

    const currentAuctionLotsModel = useSelector<RootState, CurrentAuctionLotsModelState>((state: RootState) => state.currentAuctionLots);

    React.useEffect(() => {
        dispatchStore.currentAuctionLots.asyncInitLoadForTimedStaggeredFastBiddingView();
    }, [auctionId]);

    useRemoteUpdatingAuctionLotsChangeListener(auctionId);
    useRemoteUpdatingLotsInterestChangeListener(auctionId);

    const {isInitialFetching, isFetching, hasFetchError, lotsList, totalCount, windowCount, refTimestamp, cursorTimestamp, nextCursorTimestamp} = currentAuctionLotsModel;

    const hasMoreLotsToFetch = lotsList.length < totalCount;

    const [changeIdx, setChangeIdx] = React.useState<number>(0);

    const fetchMoreLots = React.useCallback(async () => {
        await dispatchStore.currentAuctionLots.asyncLoadMoreForTimedStaggeredFastBiddingView();
        setChangeIdx(pv => (pv+1));
    }, [auctionId]);


    const lotsInView = lotsList.filter((lot) => (! lot.isHiddenFromLotsListView));


    React.useEffect(() => {
        // this effect is triggered (most often) when the `changeIdx`

        // if there are less than (12 or staggering_group).max lots in view, then fetch more, if there are more lots to fetch
        if (lotsInView.length < (Math.min(timedLotsStaggeringGroupSize * 3, 12) + 1) && hasMoreLotsToFetch) {
            fetchMoreLots();
        }
    }, [auctionId, timedLotsStaggeringGroupSize, hasMoreLotsToFetch, lotsInView.length, changeIdx]);



    React.useEffect(() => {
        // register a timer every staggering interval offset to trigger the logic
        const removeListener = AppController.instance.timerEventsEmitter.onEvery(timedLotsStaggeringOffsetSeconds, () => {
            setChangeIdx(pv => (pv + 1));
        });
        return () => {
            removeListener();
        }
    }, [auctionId, timedLotsStaggeringOffsetSeconds]);



    const handleClick_loadMore = (evt) => {
        fetchMoreLots();
    }




    if (isInitialFetching) {
        return <div>Loading lots ...</div>;
    }
    if (hasFetchError) {
        return <div>Error ...</div>
    }

    return (
        <div className="row">
            <div className="col py-3">

                <div className='lots-view lots-view--grid'>
                    {lotsInView.map((lot) => <LotCard
                        key={lot.id}
                        auction={auction}
                        lot={lot}
                        biddingContextView={BiddingContextViewEnum.fast_bidding}
                    />)}
                </div>

                {
                    isFetching
                        ? <div className='py-3 text-center'>Loading more lots ...</div>
                        : (
                            hasMoreLotsToFetch // if there are more lots to fetch, then show the 'Load more' button
                            && <div className='py-5 d-flex flex-column align-items-center'>
                                <p>&nbsp;</p>
                                <button type="button" onClick={handleClick_loadMore} className="btn btn-outline-secondary">Load more lots &nbsp; <i className="fa-regular fa-down-from-dotted-line" /></button>
                            </div>
                        )

                }



            </div>
        </div>
    )
}
