import React from "react";
import {Link, useNavigate} from "react-router-dom";
import RoutesPathDefs from "../RoutesPathDefs";
import AppController from "@nextlot/core/AppController";
import {CatalogLot_ModelTypeDef, LotsPaginatedList_TypeDef} from "@nextlot/core/type_defs/model";
import AnimatedSkeletons from "../components/AnimatedSkeletons";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import LotTimelineStatusLines from "../components/lot_bidding_area/LotTimelineStatusLines";
import {useAuctionLotUpdatingStatus} from "@nextlot/core/hooks/LotHooks";
import useListingUrlParams, {parseFiltersString, parseSortString, serializeFilterStruct, useLotsListingUrlParamsResultTypeDef} from "../hooks/useListingUrlParams";
import {helpers, pluralize} from "@nextlot/core";
import classNames from "classnames";
import { formatDateTime, DATETIME_FORMAT_STRINGS } from "@nextlot/core/helpers";
import SearchLotsForm from "src/components/SearchLotsForm";
import LotBiddingAreaCmp from "src/components/lot_bidding_area/LotBiddingAreaCmp";

enum FetchingStatesEnum {
    isFetching = 'isFetching',
    fetchFailed = 'fetchFailed',
    fetchSuccessful = 'fetchSuccessful',
    fetchSkipped = 'fetchSkipped',
}

type ReducerActionType = {
    type: FetchingStatesEnum,
    payload?: any,
}

type SearchResultsReducerStateDataType = {
    fetchingState: FetchingStatesEnum,
    resultsData?: LotsPaginatedList_TypeDef<CatalogLot_ModelTypeDef>,
    errorMessage?: string,
}

type BackToAuctionType = {
    url: string,
    label: string,
}

type AuctionTypesFilterStateType = {
    isFilterAuctionsRunningActive: boolean;
    isFilterAuctionsUpcomingActive: boolean;
};

export default function SearchLotsPage() {

    const navigate = useNavigate();

    const lotsListingUrlParams = useListingUrlParams();
    const { filters, sortingCriteria, pageNumber, generatePathWithParams} = lotsListingUrlParams;
    const sortingStruct = parseSortString(sortingCriteria) || {};
    const paramSortAuctionsStartsAt = sortingStruct?.auctions_starts_at;

    const filtersStruct = parseFiltersString(filters) || {};
    const paramTextSearch = filtersStruct?.text_search;
    const paramAuctionCompletesAt = filtersStruct?.auction_completes_at;

    const isAuctionsActiveView: boolean = parseInt(paramAuctionCompletesAt) > 0;
    const backToAuctionType: BackToAuctionType = {
        url: isAuctionsActiveView ? RoutesPathDefs.toHomePage.generatePath()
                                  : RoutesPathDefs.toAuctionsListCompletedPage.generatePath(),
        label: isAuctionsActiveView ? 'Active' : 'Completed'
    };


    const [auctionTypesListing, setAuctionTypesListing] = React.useState<AuctionTypesFilterStateType>({
        isFilterAuctionsRunningActive: !!filtersStruct.auctions_running,
        isFilterAuctionsUpcomingActive: !!filtersStruct.auctions_upcoming,
    });

    const [isFiltersSectionOpen, setIsFilterSectionOpen] = React.useState<boolean>(false);

    const isFiltersOrSortingActive: boolean = (auctionTypesListing.isFilterAuctionsRunningActive || auctionTypesListing.isFilterAuctionsUpcomingActive) ||
                                            Object.keys(sortingStruct).length > 0

    const [searchResults, dispatchSearchResults] =
        React.useReducer((state:SearchResultsReducerStateDataType, action:ReducerActionType):SearchResultsReducerStateDataType => {
            const actionType = action?.type;

            if (actionType === FetchingStatesEnum.fetchFailed) {
                return {
                    fetchingState: FetchingStatesEnum.fetchFailed,
                    errorMessage: String(action.payload),
                }
            }
            else if (actionType === FetchingStatesEnum.fetchSuccessful) {
                return {
                    fetchingState: FetchingStatesEnum.fetchSuccessful,
                    resultsData: action.payload,
                }
            }
            else if (actionType === FetchingStatesEnum.fetchSkipped) {
                return {
                    fetchingState: FetchingStatesEnum.fetchSkipped,
                }
            }
            else if (actionType) {
                return {
                    fetchingState: FetchingStatesEnum.isFetching,
                }
            }

            return state;
        }, {
            fetchingState: FetchingStatesEnum.isFetching,
        });


    React.useEffect(() => {
        const apiTextQuery = paramTextSearch?.trim();

        if ( ! apiTextQuery) {
            dispatchSearchResults({ type: FetchingStatesEnum.fetchSkipped });
            return;
        }

        dispatchSearchResults({ type: FetchingStatesEnum.isFetching });

        AppController.instance.remoteDataHandler.fetchSearchLotsListBrowsable({ pageNumber, filters, sortingCriteria })
            .then((responseData) => {
                dispatchSearchResults({ type: FetchingStatesEnum.fetchSuccessful, payload: responseData });
            })
            .catch((error) => {
                dispatchSearchResults({ type: FetchingStatesEnum.fetchFailed, payload: error });
            })

        setAuctionTypesListing({
          isFilterAuctionsRunningActive: !!filtersStruct.auctions_running,
          isFilterAuctionsUpcomingActive: !!filtersStruct.auctions_upcoming,
        });
    }, [filters, sortingCriteria, pageNumber])

    React.useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }, [pageNumber]);

    const [inputLotsSearchText, setInputLotsSearchText] = React.useState(paramTextSearch || '')
    const handleChange_inputLotsSearchText = (evt) => {
        setInputLotsSearchText(evt.target.value);
    }
    const handleSubmit_searchLots = (evt) => {
        evt.preventDefault();
        navigate(generatePathWithParams({ filters: { text_search: inputLotsSearchText }}));
    }

    return (
        <div className="container-xxl">

            <div className="row py-4">
                <div className="col">
                    <Link to={backToAuctionType.url}>
                        Back to { backToAuctionType.label } Auctions
                    </Link>
                </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-12">
                  <h3>Search Lots
                      {
                          paramTextSearch && <span className="fw-light text-body-secondary">&nbsp; matching &quot;{paramTextSearch}&quot;</span>
                      }
                  </h3>
              </div>

              <div className="col-lg-4 col-12 d-flex justify-content-lg-end justify-content-start align-items-center">
                  <SearchLotsForm
                    handleSubmit={handleSubmit_searchLots}
                    handleInputChange={handleChange_inputLotsSearchText}
                    inputValue={inputLotsSearchText}
                  />
              </div>
            </div>

            {
              isAuctionsActiveView
              &&
              <>
              <div className="row pt-4">
                <div className="col">
                  <div className="hstack gap-3 flex-wrap mb-3">
                    <button className={classNames('btn btn-sm text-nowrap', (isFiltersOrSortingActive || isFiltersSectionOpen) ? 'btn-dark' : 'btn-outline-dark')}
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            onClick={() => setIsFilterSectionOpen(!isFiltersSectionOpen)}
                    >
                        <i className="fa-sharp fa-solid fa-bars-filter"></i>
                        &nbsp; {isFiltersSectionOpen ? 'Hide': 'Show'} filters
                    </button>
                    {
                      isFiltersOrSortingActive
                      &&
                        <Link to={RoutesPathDefs.toSearchLotsPage.generatePath() + '?' + new URLSearchParams({ filters: serializeFilterStruct({ text_search: paramTextSearch, auction_completes_at: paramAuctionCompletesAt }) }).toString()}
                          className='btn btn-sm btn-dark'>
                          Reset filters
                        </Link>
                    }
                  </div>


                  <div className="collapse" id="collapseExample">
                    <div className="hstack flex-wrap gap-3 py-3">
                        <Link to={generatePathWithParams({ pageNumber: 1, filters: { auctions_running: !auctionTypesListing.isFilterAuctionsRunningActive }})}
                              className={classNames('btn btn-sm text-nowrap', auctionTypesListing.isFilterAuctionsRunningActive ? 'btn-dark' : 'btn-outline-dark')}>
                            {
                                auctionTypesListing.isFilterAuctionsRunningActive
                                    ? <i className='fa-regular fa-square-check' />
                                    : <i className='fa-regular fa-square' />
                            }
                            &nbsp; Running auctions
                        </Link>

                        <Link to={generatePathWithParams({ pageNumber: 1, filters: { auctions_upcoming: !auctionTypesListing.isFilterAuctionsUpcomingActive }})}
                              className={classNames('btn btn-sm text-nowrap', auctionTypesListing.isFilterAuctionsUpcomingActive ? 'btn-dark' : 'btn-outline-dark')}>
                            {
                                auctionTypesListing.isFilterAuctionsUpcomingActive
                                    ? <i className='fa-regular fa-square-check' />
                                    : <i className='fa-regular fa-square' />
                            }
                            &nbsp; Upcoming auctions
                        </Link>

                        <div className="dropdown">
                          <button className="btn btn-sm btn-outline-dark dropdown-toggle" type="button" id="sortSearch" data-bs-toggle="dropdown" aria-expanded="false">
                              Sorted by&nbsp;
                              <b>
                              {
                                paramSortAuctionsStartsAt
                                    ? <span>Start date <i className="fa fa-arrow-up-short-wide" /></span>
                                    : <span>Closing date <i className="fa fa-arrow-up-short-wide" /></span>
                              }
                              </b>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="sortSearch">
                              <li>
                                  <Link to={generatePathWithParams({ sortingCriteria: { lots_completes_at: '1', auctions_starts_at: '' }})}
                                        className={classNames('dropdown-item ', !paramSortAuctionsStartsAt ? 'active' : null)}>
                                      Closing date <i className="fa fa-arrow-up-short-wide" />
                                  </Link>
                              </li>
                              <li>
                                  <Link to={generatePathWithParams({ sortingCriteria: { auctions_starts_at: '1', lots_completes_at: ''}})}
                                        className={classNames('dropdown-item ', paramSortAuctionsStartsAt ? 'active' : null)}>
                                      Starting date <i className="fa fa-arrow-up-short-wide" />
                                  </Link>
                              </li>
                          </ul>
                        </div>
                    </div>

                  </div>

                </div>
              </div>
              </>
            }

            <SearchResultsLotsListing
                searchResults={searchResults}
                textQuery={paramTextSearch}
                lotsListingUrlParams={lotsListingUrlParams}
            />


        </div>
    )
}


function SearchResultsLotsListing({searchResults, textQuery, lotsListingUrlParams }:
                                      { searchResults:SearchResultsReducerStateDataType, textQuery:string, lotsListingUrlParams:useLotsListingUrlParamsResultTypeDef }) {
    const navigate = useNavigate();

    const { generatePathWithParams, pageNumber, pageSize} = lotsListingUrlParams;

    const resultsPagesCount = searchResults.resultsData?.pagesCount || 1;

    const validSafePageNumber = React.useCallback((reqPageNumber:number) => {
        // ensure reqPageNumber is between 1 and resultsPagesCount
        return Math.min(resultsPagesCount, Math.max(1, reqPageNumber));
    }, [searchResults, resultsPagesCount]);



    const handleChange_PageNumberDropdown = (evt) => {
        const selectedPageNumber = evt.target.value;
        navigate(generatePathWithParams({ pageNumber: selectedPageNumber }));
    };



    if (searchResults.fetchingState === FetchingStatesEnum.isFetching) {
        return (
            <div className="row py-4">
                <div className="col">
                    <AnimatedSkeletons.LotsHorizontalListSkeleton/>
                </div>
            </div>
        )
    }
    else if (searchResults.fetchingState === FetchingStatesEnum.fetchFailed) {
        return <h4 className='text-danger'>Fetch failed: {searchResults.errorMessage}</h4>
    }
    else if (searchResults.fetchingState === FetchingStatesEnum.fetchSkipped) {
        return (
            <div className='my-4 alert alert-info'>Type one or more search keywords to search.</div>
        )
    }

    // fetchSuccessful

    const resultsTotalCount = searchResults.resultsData.totalCount;
    const resultsPageSize = searchResults.resultsData.pageSize;


    if (searchResults.resultsData.list.length) {
        return (
            <>
                <div className="row py-4">
                    <div className="col">
                        <div className='lots-view lots-view--list'>
                            {
                                searchResults.resultsData.list.map((lot) => <LotListItem key={lot.id} lot={lot} textQuery={textQuery}/>)
                            }
                        </div>
                    </div>
                </div>


                <div className="hstack flex-wrap gap-3 py-4">
                    <div className="col-md-auto">
                        <span className='text-nowrap'>Found {pluralize(resultsTotalCount, 'Lots', 'Lot')}</span>
                        <div className='mx-2 vr'></div>

                        Showing {resultsPageSize} Lots per page

                        &nbsp;

                    </div>
                    <div className="col-sm-12 col-md-auto d-flex flex-row align-items-center justify-content-start text-md-start">
                      <Link to={generatePathWithParams({pageNumber: validSafePageNumber(pageNumber - 1)})}
                            className="mx-1 btn btn-sm btn-outline-secondary px-3 px-lg-2"><i className="fa-regular fa-chevron-left"/></Link>

                      <select onChange={handleChange_PageNumberDropdown} value={pageNumber} className='form-select form-select-sm' style={{ width: '5em' }}>
                          {
                              Array.from({length: resultsPagesCount}, (_, i) => i + 1).map((page) => {
                                  return <option key={page.toString()}>{page}</option>
                              })
                          })
                      </select>

                      <div className={'text-nowrap'}>&nbsp; of {resultsPagesCount}&nbsp;</div>

                      <Link to={generatePathWithParams({pageNumber: validSafePageNumber(pageNumber + 1)})}
                            className="mx-1 btn btn-sm btn-outline-secondary px-3 px-lg-2"><i className="fa-regular fa-chevron-right"/></Link>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='my-4 alert alert-warning'>No lots were found matching your search.</div>
    )
}




function LotListItem({lot, textQuery}: { lot: CatalogLot_ModelTypeDef, textQuery: string }) {

    const auction = lot.auction;

    const handleClick_openLotDetails = (evt) => {
        // open in a new tab/window
        window.open(window.location.origin + NextLotJSDATA.webapp_root_path + RoutesPathDefs.toLotDetailsPage.generatePath(lot.id, lot.auctionId), '_blank');
    }

    const lotStatus = useAuctionLotUpdatingStatus(lot, auction);


    return (
        <div className="lot-card">
            <div className="lot__header">
                <div className="lot-number has-cursor-pointer" onClick={handleClick_openLotDetails}>
                    <span style={{opacity: 0.5}}>#</span><strong>{lot.number}</strong>
                </div>

                <div className="lot-actions">
                </div>
            </div>

            <div className="lot__name has-cursor-pointer" onClick={handleClick_openLotDetails}>
                {lot.name}
            </div>

            <div className="lot__image has-cursor-pointer">
                <img src={lot.focalMediaFileThumbImageUrl} alt={lot.number} onClick={handleClick_openLotDetails}/>
                {
                    (!!lot.videoUrl)
                    &&
                    <div style={{
                        position: "absolute",
                        top: 10,
                        left: 10,
                        width: 60,
                        height: 60,
                    }}><i className='fas fa-circle-video fa-lg'/></div>
                }
            </div>

            <div className='lot__description' dangerouslySetInnerHTML={{__html: lot.description as string}}/>


            <div className='lot__bidding'>
                {
                  lot.auction.timelineState.isActive
                  ? <LotTimelineStatusLines
                      auction={lot.auction}
                      lotStatus={lotStatus}
                      isLotInExtension={lot.closesAt < lot.completesAt}
                    />
                  : <LotBiddingAreaCmp
                        auction={lot.auction}
                        lot={lot}
                    />
                }

                <ul className="m-0 list-unstyled small text-center">
                  {
                      lot.auction.timelineState.isActive
                      &&
                      <>
                        {
                          lot.auction.timelineState.isLive
                          &&
                          <li><b>Started:</b> {formatDateTime(lot.auction.startsAt, DATETIME_FORMAT_STRINGS.LOT_CARD_SEARCH)}</li>

                        }
                        {
                          lot.auction.timelineState.isUpcoming
                          &&
                          <li><b>{lot.auction.timelineState.isCompleted ? null : 'Closes:'}</b> {formatDateTime(lot.completesAt, DATETIME_FORMAT_STRINGS.LOT_CARD_SEARCH)}</li>
                        }
                      </>
                  }
                </ul>
            </div>

            <div style={{ gridColumnStart: 'span 3' }} className='pt-2 border-top'>
                <div className="hstack gap-1">
                  <div className="pt-2">

                    <Link className="text-decoration-none text-secondary fs-6"
                          to={`/${auction.id}/lots?filters=text_search%3A${encodeURIComponent(textQuery)}`}
                          target='_blank'><i className={'fa-solid fa-gavel'} /> &nbsp; {auction.name}</Link>

                    <div className="hstack flex-wrap gap-1 pt-2">
                      <span className={classNames('badge small', lot.auction.timelineState.isUpcoming || lot.auction.timelineState.isCompleted ? 'bg-light text-black-50' : 'text-bg-success')}>{lot.auction.timelineState.name}</span>
                      <span className="badge small text-bg-light">{lot.auction.auctionType.name}</span>
                      {
                          lot.auction.timelineState.isCompleted
                          &&
                          <span className="badge small text-dark border border-1" style={{backgroundColor: 'transparent'}}>
                            {helpers.buildAuctionTimelineLabels(lot.auction, helpers.formatDateTime).map(
                                (label, idx, labels) => <div key={idx}>{label}</div>)}
                          </span>
                      }
                    </div>
                  </div>

                </div>
            </div>
        </div>
    )
}
