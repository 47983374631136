import React from "react";
import {AuctionLotUpdatingStatus_TypeDef, LotStatusCodesEnum} from "@nextlot/core/hooks/LotHooks";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import classNames from "classnames";

type LotTimelineStatus_Props = {
    auction: Auction_ModelTypeDef,
    lotStatus: AuctionLotUpdatingStatus_TypeDef,
    isLotInExtension?:boolean,
}


function biddingTextForAuctionType(auction:Auction_ModelTypeDef, lotStatusCode?:LotStatusCodesEnum):string {
    const { auctionType, isCompleted } = auction;

    if (auctionType.isCombinedAny && !isCompleted) {
        if (lotStatusCode === LotStatusCodesEnum.closed_webcast_lot) {
            return 'Lot';
        }
        if (auctionType.isCombinedPrebid) {
            return 'Pre-bidding';
        }
        else { // Absentee
            return 'Absentee bidding';
        }
    }
    else if (lotStatusCode === LotStatusCodesEnum.closed_completed_auction) {
        return auctionType.hasWebcastBidding
            ? 'Live webcast'
            : 'Bidding'
    }
    else {
        return 'Lot'
    }
}

export default function LotTimelineStatusLines({ lotStatus, auction, isLotInExtension }:LotTimelineStatus_Props ) {

    const {statusCode, textDate, textTime, textDuration, secondsRemaining} = lotStatus;

    switch (statusCode) {
        case LotStatusCodesEnum.upcoming: {
            return (
                <div className='py-3 text-center fw-bold'>
                    {biddingTextForAuctionType(auction)} opens on
                    <br />
                    {textDate}
                    <br />
                    at {textTime}
                </div>
            )
        }

        case LotStatusCodesEnum.closed_time_expired:
        case LotStatusCodesEnum.closed_webcast_lot:
        case LotStatusCodesEnum.closed_completed_lot: {
            return (
                <div className='py-3 text-center fw-bold'>
                    {biddingTextForAuctionType(auction, statusCode)} CLOSED
                </div>
            )
        }

        case LotStatusCodesEnum.closing_now: {
            return (
                <div className='py-3 text-center text-danger fw-bold'>
                    Closing NOW!
                </div>
            )
        }

        case LotStatusCodesEnum.closed_stalled_auction: {
            return (
                <div className='py-3 text-center fw-bold'>
                    Webcast starting on
                    <br />
                    {textDate}
                    <br/>
                    at {textTime}
                </div>
            )
        }

        case LotStatusCodesEnum.closed_completed_auction: {
            return (
                <div className='py-3 text-center fw-bold'>
                    Bidding Closed
                </div>
            )
        }

        case LotStatusCodesEnum.closed_webcast_preparing: {
            return (
                <div className='py-3 text-center fw-bold'>
                    Webcast starting soon
                </div>
            )
        }

        case LotStatusCodesEnum.active_webcast_running: {
            return (
                <div className='py-3 text-center fw-bold'>
                    <span className=''>Webcast running</span>
                </div>
            )
        }

        default: { //  LotStatusCodesEnum.active
            const auctionTypeClosingPrefix = `${biddingTextForAuctionType(auction)} closes`;

            if (statusCode === LotStatusCodesEnum.active_with_counter) {
                return (
                    <div className='py-3 text-center fw-bold'>
                        {auctionTypeClosingPrefix} at
                        <br />
                        {textTime}
                        <br/>
                        <div className='d-flex flex-row align-items-center justify-content-center'>
                            <span
                                className={classNames('badge',
                                    secondsRemaining < 60 ? 'text-white bg-danger' : 'text-black bg-light')}
                            >{textDuration}</span>

                            {
                                isLotInExtension && <span className='badge text-success bg-light'>extended</span>
                            }
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className='py-3 text-center fw-bold'>
                        {auctionTypeClosingPrefix} on
                        <br />
                        {textDate}
                        <br />
                        at {textTime}
                    </div>
                )
            }
        }

    }
}

