import React from "react";
import { createPortal } from 'react-dom';
import {BrowserRouter, createBrowserRouter, createRoutesFromElements, Navigate, Route, Routes, useLocation} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AuctionLotsPageRoot from './pages/AuctionLotsPageRoot';
import {AuctionPageRoot} from "./pages/AuctionPageRoot";
import AuctionWarrantPageDispatcher from "./pages/AuctionWarrantPageDispatcher";
import RoutesPathDefs from "./RoutesPathDefs";
import {AppController, store, TaggedLogger} from "@nextlot/core";
import {Provider} from "react-redux";
import {CatalogLot_ModelTypeDef} from "@nextlot/core/type_defs/model";
import LotDetailsModalCmp from "./components/LotDetailsModalCmp";
import {LotDetailsFullPage} from "./pages/LotDetailsFullPage";
import {MyWatchlistPage} from "./pages/MyWatchlistPage";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {useIsMobileDevice} from "@nextlot/core/src/hooks/useIsMobileDevice";
import useCheckClockDesynced from "./hooks/useCheckClockDesynced";
import SearchLotsPage from "./pages/SearchLotsPage";
import AuctionsListActive from "./components/AuctionsListActive";
import AuctionsListCompleted from "./components/AuctionsListCompleted";


const _logger = TaggedLogger.get('BidWebRoot');


export default function BidWebRoot({ currentBidder }) {

    const isMobileDevice = useIsMobileDevice();
    const isClockDesynced = useCheckClockDesynced();
    const [hasNetworkFailure, setHasNetworkFailure] = React.useState<boolean>(false);

    React.useEffect(() => {
        AppController.instance.remoteEventsEmitter.onConnectionError(() => {
            setHasNetworkFailure(true);
        })

        // cleanup on app close
        return () => {
            AppController.instance.destroy();
        }
    }, []);



    // handle mobile-device webpage refresh when the browser was in background
    // see https://nextlot.nolt.io/341
    React.useEffect(() => {
        if ( ! isMobileDevice) {
            // don't register any listeners on the web (???)
            return;
        }

        const handleVisibilityChange = (evt) => {
            // _logger.debug('>>effect[isMobileDevice].handleVisibilityChange: document.visibilityState', document.visibilityState);
            if (! document.hidden) {
                // _logger.debug('>>effect[isMobileDevice].handleVisibilityChange: document NOT hidden');
                // TODO: major future change: try to "reload" only the lots or auctions in view (re-connect pusher, refresh current in-view lots)
                window.location.reload();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    }, [isMobileDevice]);


    return (
        <Provider store={store}>
            {
                hasNetworkFailure
                    ? (
                        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 99999999 }}
                             className='d-flex flex-column justify-content-center align-items-center px-3 text-center bg-light'>
                            <h3>Connection lost!</h3>
                            <p className='d-md-block d-lg-none'>If you are on a mobile or tablet this can happen when the device goes into sleep mode.</p>
                            <p className=''>Please check your internet connection, then attempt to reconnect.</p>
                            <p>
                                <button type='button' onClick={() => { window.location.reload() }} className='btn btn-outline-dark'>Retry</button>
                            </p>
                        </div>
                    )
                    : (
                        <BrowserRouter basename={NextLotJSDATA.webapp_root_path}>
                            <AppRoutes />
                        </BrowserRouter>
                        // <RouterProvider router={router} />
                    )
            }
            {
                isClockDesynced
                    && (
                      createPortal(
                        <div className="w-100 position-fixed top-0 end-0 p-1 text-center shadow text-bg-danger">
                          <i className="fa-regular fa-triangle-exclamation pe-2"></i>
                          Your clock is out of sync.
                          <a
                            href="https://knowledgebase.nextlot.com/knowledge/how-to-adjust-your-computers-clock"
                            target="_blank"
                            className="ms-2 text-white"
                          >
                            Sync your clock
                            <span
                              className="ps-1"
                              style={{
                                content: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC7SURBVHgBlZE9CgIxEIUn/oBWizdY7dRSOytrC/UggngkwWNo6RVEwd5S2C1U0PgNZjFqdsEHHwkzeS8hY6y1JRHpQSQvXWFrjLnT27AfylsnobiCMxwde4i1yxpBCxbwULR4g47kiN4EUphnBpslBg6PIHGmZqGB2tQlj73nrYMGPzl07YeBfddP9uoxlEOGGrQDwQcYVL4b/P+FZSe/qiol+VN6Qwp9N/E81aEBib5t5n6kSDrcpYY+AY4T7Aqsl3EeAAAAAElFTkSuQmCC)',
                                margin: '0 3px 0 5px',
                              }}></span>
                          </a>
                        </div>,
                        document.body
                      )
                    )
            }
        </Provider>
    )
}

//TODO: update to react-router-dom v6.4
// const router = createBrowserRouter([
//     {
//         path: RoutesPathDefs.toHomePage.pathPattern, // path: '/'
//         element: <HomePage />,
//     },
//
//     {
//         path: '/:auctionId',
//         element: <AuctionPageRoot />,
//         children: [
//             {
//                 path: 'warrant',
//                 element: <AuctionWarrantPageDispatcher />,
//             },
//             {
//                 path: 'lots',
//                 element: <AuctionLotsPageRoot />,
//             },
//             {
//                 path: RoutesPathDefs.toLotDetailsPage.pathPattern,
//                 element: <LotDetailsFullPage />
//             }
//         ]
//     }
//
// ], {
//     basename: NextLotJSDATA.webapp_root_path,
// });


function AppRoutes() {
    const location = useLocation();
    const state = location.state as { backgroundLocation?: Location, preloadedLot?: CatalogLot_ModelTypeDef } || {};
    const {backgroundLocation, preloadedLot} = state;


    return (
        <>
            <Routes location={backgroundLocation || location}>
                <Route path={RoutesPathDefs.toHomePage.pathPattern} // path: '/'
                       element={<HomePage />} // TODO: Maybe rename to `AuctionListPageRoot`?
                >
                    <Route index
                           element={<AuctionsListActive />} />

                    <Route path={RoutesPathDefs.toAuctionsListCompletedPage.pathPattern}
                       element={<AuctionsListCompleted />} />

                </Route>



                <Route path={RoutesPathDefs.toSearchLotsPage.pathPattern} // path: '/search/lots'
                       element={<SearchLotsPage />}
                />

                <Route path={'/watchlist/running'}
                       element={<MyWatchlistPage />}
                />

                <Route path='/:auctionId'
                       element={<AuctionPageRoot />}
                >
                    <Route index
                           element={<Navigate replace to="lots" />} />

                    <Route path='warrant'
                           element={<AuctionWarrantPageDispatcher />} />

                    <Route path='lots'
                           element={<AuctionLotsPageRoot preloadedLotId={preloadedLot?.id} />} />

                    <Route path={RoutesPathDefs.toLotDetailsPage.pathPattern}
                           element={<LotDetailsFullPage />} />

                </Route>


                {/* TODO? maybe have a 404 page? */}
            </Routes>


            {
                preloadedLot
                &&
                <Routes>
                    <Route path={RoutesPathDefs.toLotDetailsPage.pathPattern}
                           element={<LotDetailsModalCmp preloadedLot={preloadedLot} />} />
                </Routes>
            }
        </>
    )
}
