import {Bid, OnlineBid} from "./data_types_definitions";

export enum WebcastStateEnum {
    WEBCAST_STATE__LOADING = -9,
    WEBCAST_STATE__LOBBY =  0,

    WEBCAST_STATE__BETWEEN_ROUNDS = 1,
    WEBCAST_STATE__DURING_ROUND_BIDDING = 2,
    WEBCAST_STATE__CHOICE_ROUND_LOTS_SELECT = 3,

    WEBCAST_STATE__ROUND_STOPPING_TRANSITIONAL = 15, // this state is only present on the client side, the server doesn't have it

    WEBCAST_STATE__CLOSED = 99,
}

export const WEBCAST_STATES_MAP = Object.freeze(new Map<number, string>([
    [WebcastStateEnum.WEBCAST_STATE__LOADING, 'Loading'],
    [WebcastStateEnum.WEBCAST_STATE__LOBBY, 'Lobby'],
    [WebcastStateEnum.WEBCAST_STATE__BETWEEN_ROUNDS, 'Running: Between rounds'],
    [WebcastStateEnum.WEBCAST_STATE__DURING_ROUND_BIDDING, 'Running: Round bidding'],
    [WebcastStateEnum.WEBCAST_STATE__CHOICE_ROUND_LOTS_SELECT, 'Running: Choice select lots'],
    [WebcastStateEnum.WEBCAST_STATE__CLOSED, 'Finished'],
]));



export const LotStateEnum = Object.freeze({
    OPEN: -1,
    CLOSED_SOLD: 0,
    CLOSED_NO_SALE: 1,
    CLOSED_PENDING: 2
});


export const LOT_STATES_MAP = Object.freeze(new Map<number, string>([
    [LotStateEnum.OPEN, 'OPEN'],
    [LotStateEnum.CLOSED_SOLD, 'CLOSED_SOLD'],
    [LotStateEnum.CLOSED_NO_SALE, 'CLOSED_NO_SALE'],
    [LotStateEnum.CLOSED_PENDING, 'CLOSED_PENDING'],
]));



export const RoundTypeEnum = Object.freeze({
    SINGLE_NORMAL: 11,
    SINGLE_BOXLOT: 12,
    GROUP_ONEMONEY: 21,
    GROUP_CHOICE: 22,
    GROUP_CHOICE_REOPEN: 23,
    GROUP_TIMESTHEMONEY: 25,
});


export const ROUND_TYPES_MAP = Object.freeze(new Map<number, string>([
    [RoundTypeEnum.SINGLE_NORMAL, 'SINGLE_NORMAL'],
    [RoundTypeEnum.SINGLE_BOXLOT, 'SINGLE_BOXLOT'],
    [RoundTypeEnum.GROUP_ONEMONEY, 'GROUP_ONEMONEY'],
    [RoundTypeEnum.GROUP_CHOICE, 'GROUP_CHOICE'],
    [RoundTypeEnum.GROUP_CHOICE_REOPEN, 'GROUP_CHOICE_REOPEN'],
    [RoundTypeEnum.GROUP_TIMESTHEMONEY, 'GROUP_TIMESTHEMONEY'],
]));

export const ROUND_TYPES_SINGLE = Object.freeze([ RoundTypeEnum.SINGLE_NORMAL, RoundTypeEnum.SINGLE_BOXLOT ]);
export const ROUND_TYPES_GROUP = Object.freeze([ RoundTypeEnum.GROUP_ONEMONEY, RoundTypeEnum.GROUP_TIMESTHEMONEY, RoundTypeEnum.GROUP_CHOICE ]);

export const ROUND_TYPES_DISPLAY_NAMES_MAP = Object.freeze(new Map<number, string>([
    [RoundTypeEnum.SINGLE_NORMAL, 'Single Lot'],
    [RoundTypeEnum.SINGLE_BOXLOT, 'Boxlot'],
    [RoundTypeEnum.GROUP_ONEMONEY, 'One-money'],
    [RoundTypeEnum.GROUP_TIMESTHEMONEY, 'Times the Money'],
    [RoundTypeEnum.GROUP_CHOICE, 'Choice'],
    [RoundTypeEnum.GROUP_CHOICE_REOPEN, 'Choice (re-open)'],
]));



export enum RoundStopTypeEnum {
    CANCEL = -1,
    SOLD = 2,
    NO_SALE = 11,
    PENDING = 4,
}


export const ROUND_STOP_TYPES_MAP = Object.freeze(new Map<number, string>([
    [RoundStopTypeEnum.CANCEL, 'CANCEL'],
    [RoundStopTypeEnum.SOLD, 'SOLD'],
    [RoundStopTypeEnum.NO_SALE, 'NO_SALE'],
    [RoundStopTypeEnum.PENDING, 'PENDING'],
]));



export const BidReviseTypeEnum = Object.freeze({
    REVERSE: -1,
    OVERRIDE_BY_FLOOR: 1,
});


export function computeBidderDisplay(bid:Bid|OnlineBid):string {
    if (bid.bidderNo === '0' ) {
        return 'FLOOR';
    }
    else if (bid.bidderLabel && bid.bidderLabel.length) {
        return `${bid.bidderNo} (${bid.bidderLabel})`;
    }
    else {
        return bid.bidderNo
    }
}


export function decorateBid<T extends Bid|OnlineBid>(bid:T):T {
    if (bid) {
        return <T>{
            ... bid,
            bidderDisplay: computeBidderDisplay(bid)
        }
    }
    else {
        return null
    }
}
