import React from "react";
import {Auction_ModelTypeDef, LotMetal_ModelTypeDef, RunningCatalogLot_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {TaggedLogger} from "@nextlot/core";
import {getMoneyHelperForCurrencyCode} from "@nextlot/core/services/MoneyHelper";
import {LotReserveStatusCode_Enum} from "@nextlot/core/type_defs/api";
import LotPlaceOnlineBidWithConfirmationModal from "./LotPlaceOnlineBidWithConfirmationModal";
import {AuctionLotUpdatingStatus_TypeDef, LotStatusCodesEnum, useAuctionLotUpdatingStatus} from "@nextlot/core/hooks/LotHooks";
import {MyBiddingWarrantStateButtonLabel} from "../MyBiddingWarrantStateButtonLabel";
import {WebcastEnterButtons} from "../WebcastEnterButtons";
import LotTimelineStatusLines from "./LotTimelineStatusLines";
import LotBidsCountLabelAndHistoryPopover from "./LotBidsCountLabelAndHistoryPopover";

const _logger = TaggedLogger.get('LotBiddingArea');



function useCalculateCanPlaceOnlineBidsNow(auction:Auction_ModelTypeDef, lotStatusCode:LotStatusCodesEnum):boolean {
    return React.useMemo(() => {
        return !!(
            auction.auctionType.hasOnlineBidding
            && auction.timelineState.isLive
            && auction.currentSegmentType.isOnline
            && auction.myBiddingWarrantState?.isAllowed
            && [LotStatusCodesEnum.active, LotStatusCodesEnum.active_with_counter, LotStatusCodesEnum.closing_now].includes(lotStatusCode)
        )
    }, [auction.id, auction.timelineState, auction.currentSegmentType, auction.myBiddingWarrantState, lotStatusCode]);
}


type LotBiddingArea_PropsTypeDef = {
    auction: Auction_ModelTypeDef,
    lot: RunningCatalogLot_ModelTypeDef,
}

export default function LotBiddingAreaCmp({ lot, auction }:LotBiddingArea_PropsTypeDef) {

    const lotStatus = useAuctionLotUpdatingStatus(lot, auction);

    return (
        <div className="">
            <ActiveBiddingArea
                auction={auction}
                lot={lot}
                lotStatus={lotStatus}
            />

            <LotTimelineStatusLines
                auction={auction}
                lotStatus={lotStatus}
                isLotInExtension={lot.closesAt < lot.completesAt}
            />
        </div>
    )
}



function CurrentBidLines({ renderCurrentBidLineContent, lotQuantity, bidAmountCents, startingBidAmountCents, currencyCode }:
                             { renderCurrentBidLineContent:()=>JSX.Element, lotQuantity:number, bidAmountCents:number, startingBidAmountCents:number, currencyCode:string }) {
    const moneyHelper = getMoneyHelperForCurrencyCode(currencyCode);
    const amountCents = bidAmountCents || startingBidAmountCents;

    return (
        <>
            <div className="d-flex flex-row align-items-center">
                {
                    (bidAmountCents)
                        ? renderCurrentBidLineContent()
                        : (startingBidAmountCents)
                            ? <small className="fw-light">STARTING BID</small>
                            : <>&nbsp;</>
                }
            </div>

            {
                (amountCents > 0)
                    ? <div>
                        <span className='fs-4 fw-bold'>{moneyHelper.formatFromCents(amountCents)}</span>
                        {
                            (lotQuantity > 1)
                            &&
                            <span className="fw-light"> &times; {lotQuantity}</span>
                        }
                    </div>
                    : <div className='fs-4 fw-bold'>&nbsp;</div>
            }

            <div className="mb-2">
                {
                    (amountCents > 0 && lotQuantity > 1)
                        ? <small className="fw-light">
                            <abbr title="The TOTAL BID is the bid amount multiplied by the quantity">TOTAL BID:</abbr>
                            &nbsp;
                            <strong>{moneyHelper.formatFromCents(lotQuantity * amountCents)}</strong>
                        </small>
                        : <small>&nbsp; {/* intentional empty tag to vertically align the bid button below */}</small>
                }
            </div>
        </>
    )
}


type ActiveBiddingArea_PropsTypeDef = {
    auction:Auction_ModelTypeDef,
    lot:LotMetal_ModelTypeDef,
    lotStatus:AuctionLotUpdatingStatus_TypeDef
}

function ActiveBiddingArea({auction, lot, lotStatus}:ActiveBiddingArea_PropsTypeDef) {

    const lotStatusCode = lotStatus.statusCode;
    const canPlaceOnlineBidsNow = useCalculateCanPlaceOnlineBidsNow(auction, lotStatusCode);

    if (lotStatusCode === LotStatusCodesEnum.upcoming) {
        return (
            <div className="d-flex flex-column align-items-stretch align-items-lg-center gap-3">
                <MyBiddingWarrantStateButtonLabel auction={auction} />

                <div className="mb-2 d-flex flex-column align-items-center">
                    <CurrentBidLines
                        renderCurrentBidLineContent={() => (<small className="fw-light">STARTING BID</small>)}
                        lotQuantity={lot.quantity}
                        bidAmountCents={0}
                        startingBidAmountCents={lot.startingBidAmountCents}
                        currencyCode={auction.currencyCode}
                    />
                </div>
            </div>
        )
    }


    return (
        <>
            {
                (auction.auctionType.hasTimedBidding && auction.currentSegmentType.isOnline)
                &&
                <div className="mb-3 d-flex flex-column align-items-center">

                    <CurrentBidLines
                        renderCurrentBidLineContent={() => (<>
                            <small className="fw-light">CURRENT BID</small>
                            {
                                (lot.myBidIsLeadingBid)
                                    ? <span className='ms-2 badge bg-success'>LEADING</span>
                                    : (lot.myBidExistsInHistory)
                                        ? <span className='ms-2 badge bg-danger'>OUTBID</span>
                                        : null
                            }
                        </>)}
                        bidAmountCents={lot.leadingBidAmountCents}
                        startingBidAmountCents={lot.startingBidAmountCents}
                        lotQuantity={lot.quantity}
                        currencyCode={auction.currencyCode}
                    />

                    <LotBidsCountLabelAndHistoryPopover lot={lot} currencyCode={auction.currencyCode} />

                    {
                        (lotStatusCode !== LotStatusCodesEnum.active_webcast_running)
                        &&
                        <div className='w-100 py-2 text-center'>
                            <MyBiddingWarrantStateButtonLabel auction={auction}>
                                {
                                    (canPlaceOnlineBidsNow)
                                    &&
                                    <LotPlaceOnlineBidWithConfirmationModal
                                        auction={auction}
                                        lot={lot}
                                    />
                                }
                            </MyBiddingWarrantStateButtonLabel>
                        </div>
                    }
                </div>
            }


            {
                (auction.auctionType.hasSecretBidding && auction.currentSegmentType.isOnline)
                &&
                <div className="mb-3 d-flex flex-column align-items-center">

                    <CurrentBidLines
                        renderCurrentBidLineContent={() => (<small className="fw-light">YOUR {auction.auctionType.isCombinedAbsentee ? 'ABSENTEE' : 'SEALED'} BID</small>)}
                        bidAmountCents={lot.myMaxBidAmountCents}
                        startingBidAmountCents={lot.startingBidAmountCents}
                        lotQuantity={lot.quantity}
                        currencyCode={auction.currencyCode}
                    />

                    {
                        (lotStatusCode !== LotStatusCodesEnum.active_webcast_running)
                        &&
                        <div className='w-100 py-2 text-center'>
                            <MyBiddingWarrantStateButtonLabel auction={auction}>
                                {
                                    (canPlaceOnlineBidsNow)
                                    &&
                                    <LotPlaceOnlineBidWithConfirmationModal
                                        auction={auction}
                                        lot={lot}
                                    />
                                }
                            </MyBiddingWarrantStateButtonLabel>
                        </div>
                    }
                </div>
            }


            {
                (auction.auctionType.hasWebcastBidding && auction.currentSegmentType.isWebcast)
                &&
                <div className="mb-3 d-flex flex-column align-items-center">

                    <CurrentBidLines
                        renderCurrentBidLineContent={() => (<>
                            <small className="fw-light">CURRENT BID</small>
                            {
                                (lot.myBidIsLeadingBid)
                                    ? <span className='ms-2 badge bg-success'>LEADING</span>
                                    : null
                            }
                        </>)}
                        bidAmountCents={lot.leadingBidAmountCents}
                        startingBidAmountCents={lot.startingBidAmountCents}
                        lotQuantity={lot.quantity}
                        currencyCode={auction.currencyCode}
                    />


                    <LotBidsCountLabelAndHistoryPopover lot={lot} currencyCode={auction.currencyCode} />

                    {
                        (
                          lotStatusCode == LotStatusCodesEnum.closed_stalled_auction
                          ||
                          lotStatusCode == LotStatusCodesEnum.closed_webcast_preparing
                        )
                        &&
                        <div className='w-100 py-2 text-center'>
                            <MyBiddingWarrantStateButtonLabel auction={auction} />
                        </div>
                    }

                </div>
            }


            {
                (lotStatusCode === LotStatusCodesEnum.active_webcast_running)
                &&
                <div className="d-flex flex-column align-items-stretch align-items-lg-center gap-3">
                    <MyBiddingWarrantStateButtonLabel auction={auction} />
                    <WebcastEnterButtons auction={auction} />
                </div>
            }


            {
                (auction.auctionType.isTimed && lot.reservePriceStatusCode)
                &&
                <div className='text-center'>
                    {
                        (lot.reservePriceStatusCode === LotReserveStatusCode_Enum.reserve_not_met)
                            ?
                            <span className='badge bg-light text-danger'>Reserve not met</span>
                            : (lot.reservePriceStatusCode === LotReserveStatusCode_Enum.reserve_met)
                                ? <span className='badge bg-light text-success'>Reserve met</span>
                                : (lot.reservePriceStatusCode === LotReserveStatusCode_Enum.no_reserve)
                                    ? <span className='badge bg-light text-dark'>No reserve</span>
                                    : null // do not render anything
                    }
                </div>
            }
        </>
    )
}



