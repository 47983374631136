import {DateTime} from 'luxon';
import StaticModelsAndConstants from "../StaticModelsAndConstants";
import {
    ApiResource_Auction,
    ApiResource_Bid_TypeDef,
    ApiResource_ItemMediaFile_TypeDef,
    ApiResource_LotMetal,
    ApiResourceDetailsLevelsEnum
} from "../type_defs/api";
import {Auction_ModelTypeDef, Bid_ModelTypeDef, LotItemMediaFile_ModelTypeDef, LotMetal_ModelTypeDef} from "../type_defs/model";
import {formatBidExtensionDurationSeconds} from "../helpers";


const ApiDtoConvertors = {

    convertAuction: (dto:ApiResource_Auction, meta?:{ _detailsLevel?:ApiResourceDetailsLevelsEnum, _class?:string, }):Auction_ModelTypeDef => {
        return {
            _class: dto._class,
            ... meta,

            id: dto.id,
            siteId: dto.site_id,

            auctionType: StaticModelsAndConstants.AuctionTypes.getById(dto.auction_type_id),

            name: dto.name,
            urlThumbImage: dto.url_thumb_image,

            locationInfo: dto.location_info_text,
            summary: dto.summary_html,
            viewingInfo: dto.viewing_info_text,
            detailsPageHtml: dto.details_page_html,

            internetBuyerPremiumDisplayText: dto.internet_buyer_premium_display_text,
            onsiteBuyerPremiumDisplayText: dto.onsite_buyer_premium_display_text,

            auctionTimezoneName: dto.auction_timezone_name,

            startsAt: DateTime.fromSeconds(dto.starts_at).setZone(dto.auction_timezone_name),
            webcastStartsAt: dto.webcast_starts_at && DateTime.fromSeconds(dto.webcast_starts_at).setZone(dto.auction_timezone_name),
            closingStartsAt: DateTime.fromSeconds(dto.closing_starts_at).setZone(dto.auction_timezone_name),
            closingEndsAt: DateTime.fromSeconds(dto.closing_ends_at).setZone(dto.auction_timezone_name),
            completesAt: DateTime.fromSeconds(dto.completes_at).setZone(dto.auction_timezone_name),
            isCompleted: dto.is_completed,

            timelineState: StaticModelsAndConstants.AuctionTimelineStates.getById(dto.timeline_state_id),
            currentSegmentType: StaticModelsAndConstants.AuctionSegmentTypes.getByCode(dto.current_segment_type_code),

            currencyCode: dto.currency_code,

            lotsCount: dto.lots_count,

            biddingApprovalMode: StaticModelsAndConstants.AuctionBiddingApprovalModes.getByCode(dto.bidding_approval_mode_code),

            bidRequestNoteText: dto.bid_request_note_text,

            bidIncrementsTable: dto.bid_increments_table,

            myBiddingWarrantState: StaticModelsAndConstants.BiddingWarrantStates.getByCode(dto.my_bidding_warrant_state_code),

            timedJumpBidsAllowed: dto.timed_jump_bids_allowed,

            timedBiddingExtensionSeconds: dto.timed_bidding_extension,
            timedBiddingExtensionDurationDisplayText: formatBidExtensionDurationSeconds(dto.timed_bidding_extension),

            timedLotsStaggeringGroupSize: dto.timed_lots_staggering_ruleset ? dto.timed_lots_staggering_ruleset.s1_group_size : undefined,
            timedLotsStaggeringOffsetSeconds: dto.timed_lots_staggering_ruleset ? dto.timed_lots_staggering_ruleset.s1_time_offset_seconds : undefined,

            webcastIsPrepared: dto.webcast_is_prepared,

            enterWebcastScreenAppLinkUrl: dto.enter_webcast_screen_applink_url,

        } as Auction_ModelTypeDef;
    },


    convertLot: <T_ApiLot extends ApiResource_LotMetal, T_ModelLot extends LotMetal_ModelTypeDef>(dto:T_ApiLot, meta?:{ _detailsLevel?:ApiResourceDetailsLevelsEnum, _class?:string }):T_ModelLot => {

        return {
            _class: dto._class,
            ... meta,

            id: dto.id,
            createdAt: DateTime.fromSeconds(dto.created_at) as DateTime,

            auctionId: dto.auction_id,

            number: dto.number,
            numberSort: dto.number_sort,

            customSort: dto.custom_sort,

            name: dto.name,
            quantity: dto.quantity,
            description: dto.description_html,
            focalMediaFileThumbImageUrl: dto.focal_media_file_url_thumb_image,
            videoUrlThumbImage: dto.video_url_thumb_image,
            videoUrl: dto.video_url,

            updatedAt: dto.updated_at ? DateTime.fromSeconds(dto.updated_at).setZone(dto.auction_timezone_name) : undefined,
            closesAt: DateTime.fromSeconds(dto.closes_at).setZone(dto.auction_timezone_name),
            completesAt: DateTime.fromSeconds(dto.completes_at).setZone(dto.auction_timezone_name) as DateTime,
            isCompleted: dto.is_completed,
            hasRound: dto.has_round,

            linkedLotsGroupId: dto.linked_lots_group_id,


            bidsCount: dto.bids_count,

            leadingBidAmountCents: dto.leading_bid_amount_cents,

            leadingBidBidderNumber: dto.leading_bid_bidder_number,
            leadingBidBidderLabel: dto.leading_bid_bidder_label,
            leadingBidBidderDisplayText: buildBidBidderDisplayText(dto.leading_bid_bidder_number, dto.leading_bid_bidder_label),

            myBidIsLeadingBid: dto.my_bid_is_leading_bid,

            // buy_now_price_cents: dto.buy_now_price_cents,
            reservePriceStatusCode: dto.reserve_price_status_code,

            startingBidAmountCents: dto.starting_bid_amount_cents,
            askingBidAmountCents: dto.asking_bid_amount_cents,

            myMaxBidAmountCents: dto.my_max_bid_amount_cents,
            myBidExistsInHistory: dto.my_bid_exists_in_history,

            myWatched: dto.my_watched,
        } as T_ModelLot;
    },





    convertItemMediaFile: (dto:ApiResource_ItemMediaFile_TypeDef, extraFields?:Partial<LotItemMediaFile_ModelTypeDef> & Pick<LotItemMediaFile_ModelTypeDef, "auctionId"|"lotId">):LotItemMediaFile_ModelTypeDef => {
        return <LotItemMediaFile_ModelTypeDef>{
            id: dto.id,

            sortIdx: dto.sort_idx,

            mediaType: StaticModelsAndConstants.ItemMediaTypes.getById(dto.media_type_id),

            url: dto.url,
            urlThumbImage: dto.url_thumb_image,
            urlHighDef: dto.url_high_def,

            ... extraFields
        }
    },




    convertBid: (dto:ApiResource_Bid_TypeDef, auctionTimezoneName:string):Bid_ModelTypeDef => {
        return {
            id: dto.id,

            recipientId: dto.recipient_id,
            auctionId: dto.auction_id,

            amountCents: dto.amount_cents,
            createdAt: DateTime.fromSeconds(dto.created_at).setZone(auctionTimezoneName),
            placedAt: DateTime.fromSeconds(dto.placed_at).setZone(auctionTimezoneName),

            bidderNumber: dto.bidder_number,
            bidderLabel: dto.bidder_label,
            bidderDisplayText: buildBidBidderDisplayText(dto.bidder_number, dto.bidder_label),

            isAuto: dto.is_auto,
            isOverridden: dto.is_overridden,
        }
    },
}

export default ApiDtoConvertors;


function buildBidBidderDisplayText(bidderNumber:string|undefined|null, bidderLabel:string|undefined):string {
    if (!bidderNumber) {
        return ''; // should NOT happen
    }

    if (bidderNumber === '0' || bidderNumber.startsWith('#')) {
        return bidderLabel as string;
    }

    if (bidderLabel) {
        return `${bidderNumber} (${bidderLabel})`;
    }
    else {
        return `${bidderNumber}`;
    }
}

