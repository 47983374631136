import {generatePath} from "react-router-dom";

interface RoutesPathDefsType {
    [toPage:string]: {
        pathPattern: string,
        generatePath: (...args:any)=>string,
    }
}

const RoutesPathDefs: RoutesPathDefsType = {
    toHomePage: {
        pathPattern: '/',
        generatePath: () => generatePath('/'),
    },

    toAuctionsListCompletedPage: {
        pathPattern: '/completed',
        generatePath: () => generatePath(RoutesPathDefs.toAuctionsListCompletedPage.pathPattern),
    },

    toWatchlistPage: {
        pathPattern: '/watchlist/running',
        generatePath: () => generatePath(RoutesPathDefs.toWatchlistPage.pathPattern),
    },

    toSearchLotsPage: {
        pathPattern: '/search/lots',
        generatePath: () => generatePath(RoutesPathDefs.toSearchLotsPage.pathPattern),
    },

    toAuctionBiddingWarrantPage: {
        pathPattern: '/:auctionId/warrant',
        generatePath: (auctionId:number) => generatePath(RoutesPathDefs.toAuctionBiddingWarrantPage.pathPattern, { auctionId: auctionId }),
    },


    toAuctionLotsPage: {
        pathPattern: '/:auctionId/lots',
        generatePath: (auctionId:number) => generatePath(RoutesPathDefs.toAuctionLotsPage.pathPattern, { auctionId: auctionId }),
    },


    toLotDetailsPage: {
        pathPattern: '/:auctionId/lots/:lotId',
        generatePath: (lotId, auctionId) => generatePath(RoutesPathDefs.toLotDetailsPage.pathPattern, { auctionId: auctionId, lotId: lotId }),
    }
}

export default RoutesPathDefs;
