import {Participant, PMParticipantsUpdated, PMAuctionDataSnapshot, Round} from "../../data/data_types_definitions";
import { ActionsCreators } from "../actions";
import {createReducer} from "@reduxjs/toolkit";


export type ReduxWebcastParticipantsType = {
    mapByUserId: Map<number, Participant>,
    onlineBidders: Array<Participant>,
    offlineBidders: Array<Participant>,
    onlineViewers: Array<Participant>,
    offlineViewers: Array<Participant>,
};



const initialState:ReduxWebcastParticipantsType  = {
    mapByUserId: new Map(),
    onlineBidders: [],
    offlineBidders: [],
    onlineViewers: [],
    offlineViewers: []
};



const _participantsByUserId = new Map<number, Participant>();



/**
 * combined as `webcastParticipants`
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMAuctionDataSnapshot> {
 *     participants: Array<Participant>,
 * }
 *
 *
 * ===
 * action: WEBCAST_CLERK_PARTICIPANTS_UPDATE
 * payload: <> {
 *     online?: Array<Participant>,
 *     offline?: Array<integer>
 * }
 *
 *
 *
 * @param state
 * @param action
 * @returns {{
 *     mapByUserId: Map<number, Participant>,
 *     onlineBidders: [],
 *     offlineBidders: [],
 *     onlineViewers: [],
 *     offlineViewers: []
 * }}
 */

const reducerWebcastParticipants = createReducer<ReduxWebcastParticipantsType>(initialState, (builder) => {

    builder.addCase(ActionsCreators.webcast.webcastAuctionInit, (state, action) => {
            state.mapByUserId = initialState.mapByUserId;
            state.onlineBidders = initialState.onlineBidders;
            state.offlineBidders = initialState.offlineBidders;
            state.onlineViewers = initialState.onlineViewers;
            state.offlineViewers=  initialState.offlineViewers;
    });



    builder.addCase(ActionsCreators.webcast.webcastAuctionDataSnapshotInit, (state, action) => {
        const payload: PMAuctionDataSnapshot = action.payload;
        const participants = payload && payload.participants;

        if (participants) {
            participants.forEach(p => _participantsByUserId.set(p.userId, p));
        }

        _createStateFromParticipantsMap(state);

    });

    builder.addCase(ActionsCreators.webcast.webcastClerkParticipantsUpdate, (state, action) => {
        const payload:PMParticipantsUpdated = action.payload
        if (payload) {
            if (payload.online && payload.online.length) {
                payload.online.forEach((p) => {
                    _participantsByUserId.set(p.userId, p); // just overwrite the Map entry; p.connectedWS should be `true` !
                })
            }
            if (payload.offline && payload.offline.length) {
                payload.offline.forEach(userId => {
                    const existingParticipant = _participantsByUserId.get(userId);
                    if (existingParticipant) {
                        _participantsByUserId.set(userId, {...existingParticipant, connected: false});
                    }
                });
            }
            _createStateFromParticipantsMap(state);
        }
    });

});


function _createStateFromParticipantsMap(state) {
    const onlineBidders = [];
    const offlineBidders = [];
    const onlineViewers = [];
    const offlineViewers = [];
    const mapByUserId = new Map<number, Participant>();


    _participantsByUserId.forEach((p, userId) => {

        mapByUserId.set(userId, decorateParticipant(p));

        if (p.connected) {
            if (p.userRole === 'bidder') {
                onlineBidders.push(p);
            }
            else if (p.userRole === 'viewer') {
                onlineViewers.push(p);
            }
        }
        else {
            if (p.userRole === 'bidder') {
                offlineBidders.push(p);
            }
            else if (p.userRole === 'viewer') {
                offlineViewers.push(p);
            }
        }
    });

    state.mapByUserId = mapByUserId;
    state.onlineBidders = onlineBidders;
    state.offlineBidders = offlineBidders;
    state.onlineViewers = onlineViewers;
    state.offlineViewers = offlineViewers;
}



function decorateParticipant(participant:Participant):Participant {
    return {
        ... participant,
        bidderDisplay: computeBidderDisplay(participant),
    }
}


function computeBidderDisplay(participant:Participant):string {
    if (participant.bidderNo === '0' ) {
        return 'FLOOR';
    }
    else if (participant.bidderLabel && participant.bidderLabel.length) {
        return `${participant.bidderNo} (${participant.bidderLabel})`;
    }
    else {
        return participant.bidderNo
    }
}

export default reducerWebcastParticipants;
