import {createReducer} from "@reduxjs/toolkit";
import {ActionsCreators, CurrentRoundCloseActionPayloadType, CurrentRoundStopActionPayloadType} from "../actions";
import {PMAuctionDataSnapshot, PMWebcastStatusChanged,} from "../../data/data_types_definitions";
import {RoundTypeEnum, WebcastStateEnum} from "../../data/WebcastDataDomain";
import {isDevelopmentEnv} from "../../../utilities";


export type ReduxWebcastStatusType = {
    state: WebcastStateEnum,
    paused: boolean,
    biddingFunctionsEnabled: boolean
};



const initialState = {
    state: WebcastStateEnum.WEBCAST_STATE__LOADING,
    paused: false,
    biddingFunctionsEnabled: false,
};


/**
 * combined as `webcastStatus`
 *
 * ===
 * action: WEBCAST_AUCTION_INIT
 * payload: <PMWebcastStatusChanged> {
 *     state: number,
 *     paused: boolean,
 *     biddingFunctionsEnabled: boolean
 * }
 *
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMWebcastStatusChanged> {
 *     state: number,
 *     paused: boolean,
 *     biddingFunctionsEnabled: boolean
 * }
 *
 *
 * ===
 * action: WEBCAST_STATUS_CHANGE
 * payload: <PMWebcastStatusChanged> {
 *     started?
 *     closed?
 *     paused?
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_BIDDING_UPDATE
 * payload: *
 *
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_STOP
 * payload: <CurrentRoundStopActionPayloadType> {
 *     roundId: string // uuid
 *     roundType: number
 *     stopType: integer // see RoundStopTypeEnum
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_CLOSE
 * payload: <CurrentRoundCloseActionPayloadType> {
 *     roundId: string // uuid
 *     stopType: integer // see RoundStopTypeEnum
 *     nextRound: ?<Round>
 * }
 *
 *
 *
 * @param state
 * @param action
 * @returns {{
 *  state: number
 *  paused: boolean,
 *  biddingFunctionsEnabled: boolean
 * }}
 */


const reducerWebcastStatus = createReducer<ReduxWebcastStatusType>(initialState, (builder) => {

    builder.addCase(ActionsCreators.webcast.webcastAuctionInit, (state, action) => {
        state.state = WebcastStateEnum.WEBCAST_STATE__LOADING;
        state.paused = false;
        state.biddingFunctionsEnabled = false;
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionClear, (state, action) => {
        state.state = WebcastStateEnum.WEBCAST_STATE__LOADING;
        state.paused = false;
        state.biddingFunctionsEnabled = false;
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionDataSnapshotInit, (state, action) => {
        const payload: PMAuctionDataSnapshot = action.payload;
        state.state = payload.webcastState;
        state.paused = payload.webcastIsPaused;
        state.biddingFunctionsEnabled = (payload.webcastState > WebcastStateEnum.WEBCAST_STATE__LOBBY && !payload.webcastIsPaused) // must be out of LOBBY and must not be paused
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionDataLotsDetailsUpdate, (state, action) => {
        if (action.payload?.isDataSync) {
            // unpause after a data sync
            state.paused = false;
            state.biddingFunctionsEnabled = state.state !== WebcastStateEnum.WEBCAST_STATE__LOBBY
        }
    });


    builder.addCase(ActionsCreators.webcast.webcastStatusChange, (state, action) => {
        const payload: PMWebcastStatusChanged = action.payload;

        if (payload.started) {
            state.state = WebcastStateEnum.WEBCAST_STATE__BETWEEN_ROUNDS;
            state.paused = false;
            state.biddingFunctionsEnabled = true;
        }
        else if (payload.closed) {
            state.state = WebcastStateEnum.WEBCAST_STATE__CLOSED;
            state.paused = false;
            state.biddingFunctionsEnabled = false;
        }
        else if (payload.paused !== undefined) {
            state.paused = !!payload.paused;
            state.biddingFunctionsEnabled = !payload.paused;
        }
    });


    builder.addCase(ActionsCreators.webcast.webcastCurrentRoundBiddingUpdate, (state, action) => {
        state.state = WebcastStateEnum.WEBCAST_STATE__DURING_ROUND_BIDDING
    });


    builder.addCase(ActionsCreators.webcast.webcastCurrentRoundStop, (state, action) => {
        const payload: CurrentRoundStopActionPayloadType = action.payload;
        if (payload.isTransitional) {
            state.state = WebcastStateEnum.WEBCAST_STATE__ROUND_STOPPING_TRANSITIONAL
        }
        else {
            // NOT transitional

            // if nextRound is missing, then currentRound is CHOICE and it's stopped as SOLD|PENDING  => CHOICE_LOTS_SELECT
            if ( ! payload.nextRound) {
                state.state = WebcastStateEnum.WEBCAST_STATE__CHOICE_ROUND_LOTS_SELECT
            }
            else {
                // *not* a CHOICE round stopped SOLD|PENDING
                // don't change any status, it will get changed later with ROUND_CLOSE
            }
        }

    });

    builder.addCase(ActionsCreators.webcast.webcastCurrentRoundClose, (state, action) => {
        const payload:CurrentRoundCloseActionPayloadType = action.payload;
        // payload for ROUND_CLOSE must have the `nextRound` present
        if (isDevelopmentEnv) {
            if (!payload.nextRound) {
                throw new Error('reducerWebcastStatus: WEBCAST_CURRENT_ROUND_CLOSE: nextRound may not be null');
            }
        }

        if (payload.nextRound.roundType === RoundTypeEnum.GROUP_CHOICE_REOPEN) {
            state.state = WebcastStateEnum.WEBCAST_STATE__CHOICE_ROUND_LOTS_SELECT;
        }
        else {
            state.state = WebcastStateEnum.WEBCAST_STATE__BETWEEN_ROUNDS;
        }
    });
});

export default reducerWebcastStatus;
