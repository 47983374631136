import {Store} from "redux";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import reducerActiveWebcast from "../redux/reducerActiveWebcast";
import {NextLotJSDATA_TypeDef} from "../../NextLotJSDATA";

const rootReducer = combineReducers({
    activeWebcast: reducerActiveWebcast,
});


const reduxStore = configureStore({

    reducer: rootReducer,

    devTools: __DEV__
        ? {
            serialize: {
                options: true
            }
        }
        : false,

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                // ignoredActions: ['your/action/type'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['activeWebcast'],
            },
        }),
});



let _configBucket = null;


export type ReduxStateType = ReturnType<typeof rootReducer>;
export type AppDispatchType = typeof reduxStore.dispatch;


export default class DataStore {

    static init(configData:NextLotJSDATA_TypeDef) {
        _configBucket = Object.freeze({
            ... configData
        });
    }


    static get reduxStore():Store<ReduxStateType> {
        return reduxStore;
    }

    static get config():NextLotJSDATA_TypeDef {
        return _configBucket;
    }
}

