import React from "react";
import {useDispatch, useSelector} from "react-redux"
import {Dispatch, RootState} from "../rematch/store";
import AppController from "../AppController";
import {TaggedLogger} from "../utilities";
import {AuctionsModelListSubState, AuctionsModelState} from "../rematch/models/auctionsListsModel";
import { ListingBrowserParams } from "type_defs/model";


const _logger = TaggedLogger.get('AuctionsListHook');


export function useLoadActiveAuctionsList(browserParams: ListingBrowserParams, loadOnFirstRender:boolean = false):[AuctionsModelListSubState, ()=>void] {
    const auctionsList = useSelector((state: RootState) => state.auctionsLists.active);
    const dispatch = useDispatch<Dispatch>();

    const loadAuctionsList = React.useCallback(dispatch.auctionsLists.loadActiveAuctionsList, []);

    React.useEffect(() => {
        _logger.debug('.useLoadActiveAuctionsList>>useEffect: setup');

        if (loadOnFirstRender) {
            loadAuctionsList(browserParams);
        }

        return () => {
            _logger.debug('.useLoadActiveAuctionsList>>useEffect: cleanup');
        }
    }, [loadOnFirstRender]);

    return [auctionsList, loadAuctionsList];
}



export function useLoadCompletedAuctionsList(browserParams: ListingBrowserParams, loadOnFirstRender:boolean = false):[AuctionsModelListSubState, ()=>void] {
    const auctionsList = useSelector((state: RootState) => state.auctionsLists.completed);
    const dispatch = useDispatch<Dispatch>();

    const loadAuctionsList = React.useCallback(dispatch.auctionsLists.loadCompletedAuctionsList, []);

    React.useEffect(() => {
        _logger.debug('.useLoadCompletedAuctionsList>>useEffect: setup');

        if (loadOnFirstRender) {
            loadAuctionsList(browserParams);
        }

        return () => {
            _logger.debug('.useLoadCompletedAuctionsList>>useEffect: cleanup');
        }
    }, [loadOnFirstRender]);

    return [auctionsList, loadAuctionsList];
}





export function useRealtimeUpdatingAuctionsList(browserParams: ListingBrowserParams):AuctionsModelState {

    const {pageNumber, pageSize} = browserParams;

    const auctionsLists = useSelector((state: RootState) => state.auctionsLists);
    const dispatch = useDispatch<Dispatch>();

    React.useEffect(() => {
        dispatch.auctionsLists.loadActiveAuctionsList(browserParams);
        dispatch.auctionsLists.loadCompletedAuctionsList(browserParams);
    }, [pageNumber, pageSize]);

    React.useEffect(() => {
        _logger.debug('.useRealtimeUpdatingAuctionsList>useEffect: setup');


        // TODO: register for remote events:
        // - webcast status changes

        const cleanupHandlerForBiddingWarrantChanges = AppController.instance.remoteEventsEmitter
            .onAuctionBiddingWarrantStateChange((change) => {
                _logger.debug('.useEffect>onAuctionBiddingWarrantStateChange: ', change);
                dispatch.auctionsLists.reloadAuction({ auctionId: change.auctionId });
            });

        const cleanupHandlerForAuctionVisibility = AppController.instance.remoteEventsEmitter
            .onAuctionVisibilityChange(async (change) => {
                _logger.debug('.useEffect>onAuctionVisibilityChange: ', change);
                if (change.isVisible) {
                    dispatch.auctionsLists.reloadAuction({ auctionId: change.auctionId });
                }
                else {
                    dispatch.auctionsLists.removeAuction({ auctionId: change.auctionId });
                }
            });

        const cleanupHandlerForAuctionDescriptiveUpdate = AppController.instance.remoteEventsEmitter
            .onAuctionDescriptiveChange(async (update) => {
                _logger.debug('.useEffect>onDescriptiveUpdate: ', update.auctionId);
                dispatch.auctionsLists.reloadAuction({ auctionId: update.auctionId });
            });


        return () => {
            _logger.debug('.useEffect: cleanup');
            cleanupHandlerForBiddingWarrantChanges();
            cleanupHandlerForAuctionVisibility();
            cleanupHandlerForAuctionDescriptiveUpdate();
        }

    }, []);


    return auctionsLists;
}

