import React from "react";
import {Link, useNavigate} from "react-router-dom";
import AnimatedSkeletons from "../components/AnimatedSkeletons";
import RoutesPathDefs from "../RoutesPathDefs";
import {hooks, TaggedLogger} from "@nextlot/core";
import useListingUrlParams, {serializeFilterStruct} from "../hooks/useListingUrlParams";
import AuctionCard from "./auction/AuctionCardCmp";
import ListingsPagination from "./ListingsPagination";
import SearchLotsForm from "./SearchLotsForm";


const _logger = TaggedLogger.get('AuctionsListActive');

export default function AuctionsListActive() {

  const navigate = useNavigate();

  const {
    pageNumber, pageSize,
    filters, sortingCriteria,
    generatePathWithParams} = useListingUrlParams();

  const { active:activeAuctions } = hooks.useRealtimeUpdatingAuctionsList({pageNumber, pageSize,
    filters, sortingCriteria});

  const pagesCount = activeAuctions?.pagesCount || 1;
  const validSafePageNumber = React.useCallback((reqPageNumber:number) => {
    // ensure reqPageNumber is between 1 and pagesCount
    return Math.min(pagesCount, Math.max(1, reqPageNumber));
  }, [activeAuctions, pagesCount]);

  const [inputLotsSearchText, setInputLotsSearchText] = React.useState('')
  const handleChange_inputLotsSearchText = (evt) => {
      setInputLotsSearchText(evt.target.value);
  }
  const handleSubmit_searchLots = (evt) => {
      evt.preventDefault();
      if (inputLotsSearchText) {
          const urlSearchParams = new URLSearchParams({ filters: serializeFilterStruct({ text_search: inputLotsSearchText, auction_completes_at: '1' }) });
          const navigatePathWithQuery = RoutesPathDefs.toSearchLotsPage.generatePath() + '?' + urlSearchParams.toString();
          navigate(navigatePathWithQuery);
      }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [pageNumber]);


  return (
    <>
      <div className="row pt-5">
        <div className="col-lg-8 col-12">
            <h1>Active Auctions <small className="fs-5 fw-light text-body-secondary">// currently running or upcoming</small></h1>
        </div>
        <div className="col-lg-4 col-12 d-flex justify-content-start justify-content-lg-end align-items-center">
            <SearchLotsForm
              handleSubmit={handleSubmit_searchLots}
              handleInputChange={handleChange_inputLotsSearchText}
              inputValue={inputLotsSearchText}
            />
        </div>
      </div>

      {
          activeAuctions.isFetching
              ? <AnimatedSkeletons.AuctionsListSkeleton/>
              : activeAuctions.fetchError
                  ? <div>Oops, error! {activeAuctions.fetchError}</div>
                  : (
                      activeAuctions.list?.length
                          ? (
                              <div className="row py-4">
                                <div className="col">

                                    {
                                      activeAuctions.list.map((auction) => <AuctionCard key={auction.id} auction={auction}/>)
                                    }


                                    <div className="py-2 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                      <ListingsPagination
                                        generatePathWithParams={generatePathWithParams}
                                        validSafePageNumber={validSafePageNumber}
                                        pageNumber={activeAuctions.pageNumber}
                                        pagesCount={activeAuctions.pagesCount}
                                      />
                                    </div>

                                </div>
                              </div>
                            )
                          : <div><p className='lead'>There are no active auctions. Please check back again soon.</p></div>
                  )
      }
      <div className="w-100 pt-5 text-center">
        <Link className="btn btn-outline-dark" to={RoutesPathDefs.toAuctionsListCompletedPage.generatePath()}>View Completed Auctions</Link>
      </div>
    </>
  )



}
