import React from "react";
import {NavLink, Outlet} from "react-router-dom";
import RoutesPathDefs from "../RoutesPathDefs";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {TaggedLogger} from "@nextlot/core";

const _logger = TaggedLogger.get('HomePage');
export default function HomePage() {
    const [isBannerVisible, setIsBannerVisible] = React.useState<boolean>(!!NextLotJSDATA.site_homepage_banner);

    return (
        <div className="container-xxl">

            {
                isBannerVisible
                &&
                <div className="row pt-4">
                    <div className="col">
                        <div className="p-3 bg-light">
                            <div className="fr-view" dangerouslySetInnerHTML={{__html: NextLotJSDATA.site_homepage_banner}}/>
                        </div>
                    </div>
                </div>
            }

            <Outlet /> {/* this <Outlet> will render as one of:
            - AuctionsListActive
            - AuctionsListCompleted
               */}

        </div>
    )
}
