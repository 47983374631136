
import UAParser from "https://cdn.jsdelivr.net/npm/ua-parser-js@1/+esm";

function isMobileDevice() {
    const c_touchStart = 'ontouchstart' in document.documentElement;
    const c_touchMulti = navigator.maxTouchPoints > 1;
    return (c_touchStart && c_touchMulti);
}


// Navigator.standalone Non-standard. Returns a boolean indicating whether the browser is running in standalone mode.
// Available on Apple's iOS Safari only. DEPENDANT on meta tag <meta name="apple-mobile-web-app-capable" content="yes">
function isPwaStandaloneIos() {
    return (window as any).navigator.standalone;
}
function isIosDevice() {
    return 'standalone' in window.navigator;
}
function isSafari() {
    return new UAParser().getBrowser().name.includes("Safari");
}
function isPwaStandaloneAndroid() {
    return window.matchMedia('(display-mode: standalone)').matches;
}

export class DetectedBrowserDevice {
    public readonly isMobile: boolean = isMobileDevice();
    public readonly isIos: boolean = isIosDevice();
    public readonly isSafari: boolean = isSafari();
    public readonly isPwaStandaloneAndroid: boolean = isPwaStandaloneAndroid();
    public readonly isPwaStandaloneIos: boolean = isPwaStandaloneIos();

    constructor() {
    }


}
