
declare global {
    // @ts-ignore
    const __DEV__: boolean;
    const __BUILD_TAG__: string;
}

export { TaggedLogger, EventBus, SingleTypeEventBus, TimerEventsEmitter } from "./utilities";

export { default as AppController } from "./AppController";

export { AppRemoteDataHandler } from "./services/AppRemoteDataHandler";
export { ApiResourceDetailsLevelsEnum } from "./type_defs/api";

export type { IPusherAdapter } from "./services/IPusherAdapter";
export { PusherConnectionStatesEnum, PusherChannelNames } from "./services/IPusherAdapter";


import {ROUND_TYPES_DISPLAY_NAMES_MAP, RoundStopTypeEnum} from "./webcast/data/WebcastDataDomain";
import {HEADER_BIDDER_TOKEN} from "./services/FrontendApiAdapter";

export const CONSTANTS = {
    HEADER_BIDDER_TOKEN,
    ROUND_TYPES_DISPLAY_NAMES_MAP
}

export * from "./utilities";

import * as helpers from "./helpers";
import * as hooks from "./hooks";
import {store} from "./rematch/store";
import * as webcast from "./webcast";



export { DataStore as WebcastDataStore, AuctionsManager as WebcastAuctionsManager, WebcastStateEnum, RoundTypeEnum, LotStateEnum, RoundStopTypeEnum, WebcastAuctionHandler } from "./webcast"
export type { WebcastAuctionConnectData_TypeDef, WebcastReduxState_TypeDef, WebcastReduxActiveWebcastSubState_TypeDef } from "./webcast";
export type { Lot as WebcastLot, LotDetails as WebcastLotDetails } from "./webcast/data/data_types_definitions";
export { WbsNetworkStatusConnectionStateEnum } from "./webcast/redux/webcast/reducerWbsNetworkStatus";

export {helpers, hooks, store, webcast};


export type {Dispatch as AppStoreDispatch, RootState as AppRootState} from "./rematch/store";


export const CORE_VERSION = {
    // @ts-ignore
    buildTag: __BUILD_TAG__ || 'dev',
};
