import {ACTION_TYPES, CurrentRoundCloseActionPayloadType, CurrentRoundStopActionPayloadType} from "../actions";
import {PMAuctionDataSnapshot, ReduxAction, Round} from "../../data/data_types_definitions";
import {RoundTypeEnum} from "../../data/WebcastDataDomain";


export type ReduxWebcastCurrentRoundType = {
    roundId?: string,
    roundType: number,
    lotsIds: Array<number>,
    stopType?: number,
};



const initialState:ReduxWebcastCurrentRoundType = {
    roundId: null,
    roundType: null,
    lotsIds: null,
    stopType: null,
};


/**
 * combined as `webcastCurrentRound`
 *
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMAuctionDataSnapshot> {
 *     currentRound: <Round> {
 *          roundId: string // uuid
 *          roundType:
 *          lotsIds:
 *     }
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_CREATE
 * payload: <Round> {
 *     roundId: string // uuid
 *     roundType:
 *     lotsIds:
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_STOP
 * payload: CurrentRoundStopActionPayloadType {
 *     roundId: string // uuid
 *     roundType: number
 *     stopType: integer // see RoundStopTypeEnum
 *     isTransitional: boolean
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_CLOSE
 * payload: CurrentRoundCloseActionPayloadType {
 *      stopType: number
 *      nextRound: ?<Round>
 * }
 *
 *
 * @param state
 * @param action
 * @returns ReduxWebcastCurrentRoundType {{
 *      roundId: string
 *      roundType: number
 *      lotsIds: Array<number>
 *      stopType: ?number
 * }}
 */
export default function reducerWebcastCurrentRound(state:ReduxWebcastCurrentRoundType = initialState, action:ReduxAction):ReduxWebcastCurrentRoundType {
    if (action.error) {
        return state;
    }



    switch(action.type) {

        case ACTION_TYPES.WEBCAST_AUCTION_INIT: {
            return initialState;
        }



        case ACTION_TYPES.WEBCAST_AUCTION_DATA_SNAPSHOT_INIT:
            return handleAction_SALE_DATA_SNAPSHOT_INIT(state, action.payload);


        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_CREATE: {
            const payloadRound:Round = action.payload;
            return {
                ...payloadRound,
                stopType: undefined,
            }
        }


        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_STOP: {
            const payload: CurrentRoundStopActionPayloadType = action.payload;
            return {
                ... state,
                stopType: payload.stopType,
            }
        }

        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_CLOSE: {
            // ROUND_CLOSE always has a `nextRound`
            const payload:CurrentRoundCloseActionPayloadType = action.payload;
            const payloadNextRound:Round = payload.nextRound;
            return {
                roundId: payloadNextRound.roundId,
                roundType: payloadNextRound.roundType,
                lotsIds: payloadNextRound.lotsIds,
                stopType: payloadNextRound.roundType === RoundTypeEnum.GROUP_CHOICE_REOPEN ? payloadNextRound.stopType : undefined, // if nextRound is CHOICE_REOPEN, it's already stopped
            }
        }


    }//switch


    return state;
}






function handleAction_SALE_DATA_SNAPSHOT_INIT(state:ReduxWebcastCurrentRoundType, payload:PMAuctionDataSnapshot):ReduxWebcastCurrentRoundType {
    const payloadCurrentRound:Round = payload && payload.currentRound;

    if (payloadCurrentRound) {
        // remove the `bidsHistory` attribute:  https://stackoverflow.com/a/53443378/317884
        return (({ bidsHistory, ...newState }:Round) => newState)(payloadCurrentRound);
    }
    return state;
}

