import React from "react";
import throttle from "lodash.throttle";


type ZoomableImage_Props_TypeDef = {
    src: string,
    alt: string,
    fullSizeSrc: null|string,
}


export default function ZoomableImage(props:ZoomableImage_Props_TypeDef) {

    const refFullSizeImageContainer = React.useRef<HTMLElement>();

    React.useEffect(() => {
        if (refFullSizeImageContainer.current) {
            return;
        }

        const divEl = document.createElement('div');
        divEl.classList.add('zoomable-image-full-size-image-container');
        document.body.append(divEl);

        divEl.addEventListener('pointermove', throttle((evt) => {
            const reduceX = (window.innerWidth / 8);
            const reduceY = (window.innerHeight / 8);

            const rect = {
                x0: reduceX,
                x1: window.innerWidth - 2 * reduceX,

                y0: reduceY,
                y1: window.innerHeight - 2 * reduceY,
            };
            const x = (evt.clientX - rect.x0) / rect.x1;
            const y = (evt.clientY - rect.y0) / rect.y1;

            divEl.style.backgroundPosition = `${x * 100}% ${y * 100}%`;

        }, 10), { passive: true });

        divEl.addEventListener('click', () => {
            divEl.classList.remove('is-active');
        });

        refFullSizeImageContainer.current = divEl;

        return () => {
            refFullSizeImageContainer.current = null;
            divEl.remove();
        }

    }, [props.fullSizeSrc]);




    const handleClick_zoomedActive = React.useCallback(() => {

        if (! refFullSizeImageContainer.current.style.backgroundImage) {
            // load the image on demand
            refFullSizeImageContainer.current.style.backgroundImage = `url("${props.fullSizeSrc}")`;
        }

        refFullSizeImageContainer.current.classList.add('is-active');

    }, [props.fullSizeSrc]);


    return (
        <img src={props.src}
             draggable={false}
             style={{
                 display: 'block',
                 width: '100%',
             }}
             alt={props.alt}

             onClick={handleClick_zoomedActive}
        />
    )
}
