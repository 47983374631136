export { default as TaggedLogger } from "./TaggedLogger";

export { EventBus, SingleTypeEventBus, SimpleStoredDataEventBus } from "./EventBus";

export { default as TimerEventsEmitter } from "./TimerEventsEmitter";

export function pluralize(count:number, pluralForm:string, singularForm:string = null):string {
    if (count === 1) {
        return `1 ${singularForm || pluralForm}`;
    }
    return `${count} ${pluralForm}`;
}


export function numberAsString(n:number, stringValueWhenNaN:string = ''):string {
    return isNaN(n) ? stringValueWhenNaN : String(n);
}


export const loadExternalJsScript = (url: string, onLoadFn:(args?: any) => any, onLoadArgs?:any) => {

  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.src = url;
  scriptEl.async = true;

  scriptEl.onerror = (err) => {
      console.error('NextLot Nexus]js:script#onerror: ', url, err);
  }

  scriptEl.onload = function() {
    onLoadFn(onLoadArgs);
  }

  document.head.appendChild(scriptEl);
}


export function runOnDOMContentLoaded(callbackFn:(args?:any)=>any, cbArgs?:any) {
    if (document.readyState === 'loading') {
        // loading hasn't finished yet
        document.addEventListener('DOMContentLoaded', function() { callbackFn(cbArgs) });
    }
    else {
        // content already loaded (readyState === 'interactive' or 'complete')
        callbackFn(cbArgs);
    }
}



export const isDevelopmentEnv = false;
