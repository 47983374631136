import React from "react";
import {TaggedLogger} from "@nextlot/core";

type SearchLotsFormProps = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: string;
}

const _logger = TaggedLogger.get('SearchLotsForm');

export default function SearchLotsForm({ handleSubmit, handleInputChange, inputValue }: SearchLotsFormProps) {

    return (
      <form onSubmit={handleSubmit} className='d-flex flex-row'>
          <div className="input-group">
              <input type="text" className="form-control form-control-sm"
                      placeholder="Search lots"
                      aria-label="Search lots"
                      minLength={2}
                      value={inputValue}
                      onChange={handleInputChange}
              />
              <button className="btn btn-sm btn-outline-dark px-5 px-md-2" type="submit">
                  <i className="fa-solid fa-magnifying-glass"/>
              </button>
          </div>

      </form>
)

}
