/**
 *
 * WARNING: this file should contain *only* compile-time constants. Keep in sync with Rails code and other systems
 *
 */
import {
    AuctionBiddingApprovalMode_TypeDef,
    AuctionSegmentType_TypeDef,
    AuctionTimelineState_TypeDef,
    AuctionType_TypeDef,
    BiddingWarrantState_TypeDef, ItemMediaType_TypeDef,
    ObjectWithTypedProperties
} from "./type_defs/model";
import {
    AuctionBiddingApprovalModeCode_Enum,
    AuctionSegmentTypeCode_Enum,
    AuctionTimelineStateCode_Enum,
    AuctionTimelineStateCodeID_Enum,
    BiddingWarrantStateCode_Enum, ItemMediaTypeCodeID_Enum
} from "./type_defs/api";


const LotsSorting_Criteria = {
    LotId: {
        id: 0,
        label: 'Lot ID',
    },

    LotNumber: {
        id: 1,
        label: 'Number',
    },

    LotCustomSort: {
        id: 2,
        label: 'Run Order',
    },

    ItemRefno: {
        id: 21,
        label: 'Inventory RefNo',
    },

    ItemName: {
        id: 22,
        label: 'Name',
    },
}


const LotsSorting = {
    Criteria: LotsSorting_Criteria,
}




const AuctionTimelineStates_DATA:ObjectWithTypedProperties<AuctionTimelineState_TypeDef> = {
    upcoming: {
        id: AuctionTimelineStateCodeID_Enum.upcoming,
        code: AuctionTimelineStateCode_Enum.upcoming,
        name: 'Upcoming',
        isUpcoming: true,
        isActive: true,
    },
    running_stalled: {
        id: AuctionTimelineStateCodeID_Enum.running_stalled,
        code: AuctionTimelineStateCode_Enum.running_stalled,
        name: 'Paused',
        isStalled: true,
        isRunning: true,
        isActive: true,
    },
    running_live: {
        id: AuctionTimelineStateCodeID_Enum.running_live,
        code: AuctionTimelineStateCode_Enum.running_live,
        name: 'Running',
        isLive: true,
        isRunning: true,
        isActive: true,
    },
    completed: {
        id: AuctionTimelineStateCodeID_Enum.completed,
        code: AuctionTimelineStateCode_Enum.completed,
        name: 'Completed',
        isCompleted: true,
    },
}

const AuctionTimelineStates_mapById:Map<number, AuctionTimelineState_TypeDef> = new Map(Object.values(AuctionTimelineStates_DATA).map(v => [v.id, v]));


const AuctionTypeEnum_DATA:ObjectWithTypedProperties<AuctionType_TypeDef> = {
    webcast: {
        id: 11,
        label: 'Webcast',
        name: 'Live Webcast',
        isWebcast: true,
        hasWebcastBidding: true,
    },
    timed: {
        id: 20,
        label: 'Timed',
        name: 'Timed Online',
        isTimed: true,
        isOnlineOnly: true,
        hasOnlineBidding: true,
        hasTimedBidding: true,
    },
    combined_prebid_webcast: {
        id: 21,
        label: 'Prebid+Webcast',
        name: 'Combined Prebid & Webcast',
        isCombinedPrebid: true,
        isCombinedAny: true,
        hasWebcastBidding: true,
        hasOnlineBidding: true,
        hasTimedBidding: true,
    },
    sealed: {
        id: 30,
        label: 'Sealed',
        name: 'Sealed Bid',
        isSealed: true,
        isOnlineOnly: true,
        hasOnlineBidding: true,
        hasSecretBidding: true,
    },
    combined_absentee_webcast: {
        id: 31,
        label: 'Absentee+Webcast',
        name: 'Combined Absentee & Webcast',
        isCombinedAbsentee: true,
        isCombinedAny: true,
        hasWebcastBidding: true,
        hasOnlineBidding: true,
        hasSecretBidding: true,
    },
}

const AuctionTypeEnum_mapById:Map<number, AuctionType_TypeDef> = new Map(Object.values(AuctionTypeEnum_DATA).map(v => [v.id, v]));

const AuctionSegmentTypes_DATA:ObjectWithTypedProperties<AuctionSegmentType_TypeDef> = {
    online: {
        code: AuctionSegmentTypeCode_Enum.online,
        isOnline: true,
    },

    webcast: {
        code: AuctionSegmentTypeCode_Enum.webcast,
        isWebcast: true,
    }
}
const AuctionSegmentTypes_mapByCode:Map<AuctionSegmentTypeCode_Enum, AuctionSegmentType_TypeDef> = new Map(Object.values(AuctionSegmentTypes_DATA).map(v => [v.code, v]));



const BiddingWarrantState_DATA:ObjectWithTypedProperties<BiddingWarrantState_TypeDef> = {
    allowed: {
        code: BiddingWarrantStateCode_Enum.allowed,
        isAllowed: true,
    },

    pending: {
        code: BiddingWarrantStateCode_Enum.pending,
        isPendingResponse: true,
    },

    declined: {
        code: BiddingWarrantStateCode_Enum.declined,
        isDeclined: true,
    },

    must_accept_terms: {
        code: BiddingWarrantStateCode_Enum.must_accept_terms,
        isMustAcceptTerms: true,
        hasRequiredBidderAction: true,
    },

    must_request: {
        code: BiddingWarrantStateCode_Enum.must_request,
        isMustRequest: true,
        hasRequiredBidderAction: true,
    },

    must_setup_account: {
        code: BiddingWarrantStateCode_Enum.must_setup_account,
        isMustSetupAccount: true,
        hasRequiredBidderAction: true,
    },

    must_setup_card: {
        code: BiddingWarrantStateCode_Enum.must_setup_card,
        isMustSetupCard: true,
        hasRequiredBidderAction: true,
    }
}

const BiddingWarrantState_mapByCode:Map<BiddingWarrantStateCode_Enum, BiddingWarrantState_TypeDef> = new Map(Object.values(BiddingWarrantState_DATA).map((v:BiddingWarrantState_TypeDef) => [v.code, v]))




const AuctionBiddingApprovalModes_DATA:ObjectWithTypedProperties<AuctionBiddingApprovalMode_TypeDef> = {
    manual: {
        code: AuctionBiddingApprovalModeCode_Enum.manual,
        isManual: true,
    },

    auto: {
        code: AuctionBiddingApprovalModeCode_Enum.auto_auction,
        isAuto: true,
    }
}

const AuctionBiddingApprovalModes_mapByCode:Map<AuctionBiddingApprovalModeCode_Enum, AuctionBiddingApprovalMode_TypeDef> =
    new Map(Object.values(AuctionBiddingApprovalModes_DATA).map((v:AuctionBiddingApprovalMode_TypeDef) => [v.code, v]))





const ItemMediaTypes_DATA:ObjectWithTypedProperties<ItemMediaType_TypeDef> = {
    image: {
        code: ItemMediaTypeCodeID_Enum.image,
        isImage: true,
    },

    video_youtube: {
        code: ItemMediaTypeCodeID_Enum.video_youtube,
        isVideo: true,
        isVideoYoutube: true,
    },

    video_vimeo: {
        code: ItemMediaTypeCodeID_Enum.video_vimeo,
        isVideo: true,
        isVideoVimeo: true,
    },
}

const ItemMediaTypes_mapByID:Map<ItemMediaTypeCodeID_Enum, ItemMediaType_TypeDef> =
    new Map(Object.values(ItemMediaTypes_DATA).map((v:ItemMediaType_TypeDef) => [v.code, v]))






const ApiParams = {
    DefaultPageSize: 48,  // should divide by [2,3,4]; keep in sync with rails api/PagyGrape::DEFAULT_PAGE_SIZE
}


export enum WebcastScreenTypeCodesEnum {
    clerk= 'clerk',
    auctioneer = 'auctioneer',
    onsite = 'onsite',
    bidder = 'bidder',
    viewer = 'viewer',
    viewer_embedded = 'viewer_embedded'
}

const StaticModelsAndConstants = {

    LotsSorting: LotsSorting,
    ApiParams: ApiParams,

    AuctionTypes: {
        data: AuctionTypeEnum_DATA,
        getById: (id):AuctionType_TypeDef => AuctionTypeEnum_mapById.get(id),
    },

    AuctionTimelineStates: {
        data: AuctionTimelineStates_DATA,
        getById: (id):AuctionTimelineState_TypeDef => AuctionTimelineStates_mapById.get(id),
    },

    AuctionSegmentTypes: {
        data: AuctionSegmentTypes_DATA,
        getByCode: (code):AuctionSegmentType_TypeDef => AuctionSegmentTypes_mapByCode.get(code),
    },

    BiddingWarrantStates: {
        data: BiddingWarrantState_DATA,
        getByCode: (code):BiddingWarrantState_TypeDef => BiddingWarrantState_mapByCode.get(code),
    },

    AuctionBiddingApprovalModes: {
        data: AuctionBiddingApprovalModes_DATA,
        getByCode: (code):AuctionBiddingApprovalMode_TypeDef => AuctionBiddingApprovalModes_mapByCode.get(code),
    },

    ItemMediaTypes: {
        data: ItemMediaTypes_DATA,
        getById: (id:number):ItemMediaType_TypeDef => ItemMediaTypes_mapByID.get(id)
    },
}



export default StaticModelsAndConstants;
