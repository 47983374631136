import {createReducer} from "@reduxjs/toolkit";
import {ActionsCreators, ChannelEnum, WbsCommStatesEnum, WbsNetworkStatusConnectionStateEnum} from "../actions";



export type ReduxWebcastWbsNetworkStatusType = {
    wbsVersionBuildTag: string,
    connectionState: WbsNetworkStatusConnectionStateEnum,
    roundTripMilliseconds: number|false,
    biddingCommStatus: WbsCommStatesEnum,
    biddingCommBusy: boolean,
    chatCommStatus: WbsCommStatesEnum,
    chatCommBusy: boolean,
}


const initialState:ReduxWebcastWbsNetworkStatusType = {
    connectionState: WbsNetworkStatusConnectionStateEnum.connecting,
    roundTripMilliseconds: -1,
    biddingCommStatus: WbsCommStatesEnum.WBS_COMM_STATUS_UNINITIALIZED,
    chatCommStatus: WbsCommStatesEnum.WBS_COMM_STATUS_UNINITIALIZED,
    biddingCommBusy: null,
    chatCommBusy: null,
    wbsVersionBuildTag: null,
};

/**
 * combined as `wbsNetworkStatus`
 *
 *
 * ===
 * action: WEBCAST_AUCTION_INIT
 * payload: null
 *
 *
 * ===
 * action: WEBCAST_AUCTION_CLEAR
 * payload: null
 *
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMAuctionDataSnapshot> {
 *     wbsVersionBuildTag: string
 * }
 *
 *
 * ==
 * action: WEBCAST_WBS_NETWORK_STATUS_UPDATE
 * payload: WbsNetworkStatusUpdatePayloadType {
 *     connected: boolean
 *     roundTripMilliseconds: number|false
 * }
 *
 * ===
 * action: WEBCAST_WBS_COMM_STATE_UPDATE
 * payload: WbsCommStateUpdatePayloadType {
 *     channel: 'bidding'|'chat'
 *     newStatus: 'sending'|'idle' // WbsCommStatesEnum
 * }
 *
 *
 *
 * @param state
 * @param action
 * @returns ReduxWebcastWbsNetworkStatusType {{
 *     connected: boolean,
 *     roundTripMilliseconds:?number,
 *     biddingCommStatus: string,
 *     biddingCommBusy: boolean,
 *     chatCommStatus: string,
 *     chatCommBusy: boolean,
 * }}
 */
const reducerWbsNetworkStatus = createReducer<ReduxWebcastWbsNetworkStatusType>(initialState, (builder) => {

    builder.addCase(ActionsCreators.webcast.webcastAuctionInit, (state, action) => {
        state.biddingCommStatus = WbsCommStatesEnum.WBS_COMM_STATUS_INITIALIZED;
        state.biddingCommBusy = true;
        state.chatCommStatus = WbsCommStatesEnum.WBS_COMM_STATUS_INITIALIZED;
        state.chatCommBusy = true;
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionClear, (state, action) => {
        state.biddingCommStatus = WbsCommStatesEnum.WBS_COMM_STATUS_UNINITIALIZED;
        state.biddingCommBusy = true;
        state.chatCommStatus = WbsCommStatesEnum.WBS_COMM_STATUS_UNINITIALIZED;
        state.chatCommBusy = true;
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionDataSnapshotInit, (state, action) => {
        state.wbsVersionBuildTag = action.payload.wbsVersionBuildTag;
    });


    builder.addCase(ActionsCreators.webcast.wbsNetworkStatusUpdate, (state, action) => {
        const { connectionState, roundTripMilliseconds } = action.payload;
        state.connectionState = connectionState;
        state.roundTripMilliseconds = roundTripMilliseconds;
    });

    builder.addCase(ActionsCreators.webcast.wbsCommStateUpdate, (state, action) => {
        const { channel, newStatus } = action.payload;
        const isBusyStatus = newStatus !== WbsCommStatesEnum.WBS_COMM_STATUS_IDLE;
        if (channel === ChannelEnum.bidding) {
            state.biddingCommStatus = newStatus;
            state.biddingCommBusy = isBusyStatus;
        }
        else if (channel === ChannelEnum.chat) {
            state.chatCommStatus = newStatus;
            state.chatCommBusy = isBusyStatus;
        }
        else {
            // '*' all channels
            state.biddingCommStatus = newStatus;
            state.biddingCommBusy = isBusyStatus;
            state.chatCommStatus = newStatus;
            state.chatCommBusy = isBusyStatus;
        }
    });

});


export default reducerWbsNetworkStatus;
