import {DateTime} from "luxon";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {WEBCAST_ENTER_TIMEFRAME_MS} from "@nextlot/core/hooks/LotHooks";


export function WebcastEnterButtons({auction}:{auction:Auction_ModelTypeDef}) {
    const timeNow = DateTime.now();

    if (auction.webcastIsPrepared && timeNow > auction.webcastStartsAt.minus(WEBCAST_ENTER_TIMEFRAME_MS)) {
        const enterWebcastBidderUrl = `${NextLotJSDATA.webapp_root_base_url}/webcast/${auction.id}/live`;
        const enterWebcastViewerUrl = `${NextLotJSDATA.webapp_root_base_url}/webcast/${auction.id}/live?role=viewer`;
        return auction.myBiddingWarrantState
            ? <a className="btn btn-outline-secondary" href={enterWebcastBidderUrl} target='_blank'>{ auction.myBiddingWarrantState?.isAllowed ? 'Enter webcast' : 'Watch only'}</a>
            : <a className="btn btn-outline-secondary" href={enterWebcastViewerUrl} target='_blank'>Watch only</a>
    }

    return null
}
