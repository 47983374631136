import axios, {AxiosInstance, AxiosPromise} from "axios";
import {SingleTypeEventBus, TaggedLogger} from "../utilities";
import {
    ApiLotsRunningTimedStaggeredList,
    ApiPaginatedList,
    ApiRequestParams_detailsLevel,
    ApiRequestParams_lotsListBrowse,
    ApiRequestParams_pagination,
    ApiResource_Auction,
    ApiResource_Bid_TypeDef,
    ApiResource_ItemMediaFile_TypeDef,
    ApiResource_LotMetal,
    ApiResource_SiteSearchLot,
    ApiResource_WatchlistRunningLot,
    ApiResource_WebcastLot,
    ApiResourceDetailsLevelsEnum,
} from "../type_defs/api";
import {WebcastAuctionConnectData_TypeDef} from "../webcast";

export const HEADER_BIDDER_TOKEN = 'Nextlot-Bidder-Token';


const _logger = TaggedLogger.get('FrontendApiAdapter');


export type OnHandlerFunction<HF_ARGS> =  (handlerFn: (args:HF_ARGS) => void) => () => void;

export default class FrontendApiAdapter {

    private readonly axiosInstance:AxiosInstance;
    private _bidderToken: string;

    private readonly authenticationChangeEventBus: SingleTypeEventBus<boolean|null>;
    public onAuthenticationStateChanged: OnHandlerFunction<boolean>;

    private readonly responseSuccessEventBus: SingleTypeEventBus<{ headers:{} }>;
    public onResponseSuccess: OnHandlerFunction<{headers:{}}>;


    constructor(config:{ frontendApiBaseUrl:string, siteId: number }) {
        _logger.info('.constructor() config:', config);

        this.axiosInstance = axios.create({
            baseURL: `${config.frontendApiBaseUrl}/sites/${config.siteId}`,
            timeout: (1_000 * 30), // 30 seconds
            headers: {
                //  default headers here
            }
        });

        this.authenticationChangeEventBus = new SingleTypeEventBus();
        this.responseSuccessEventBus = new SingleTypeEventBus();

        // short syntax to alias a function
        this.onAuthenticationStateChanged = this.authenticationChangeEventBus.on;
        this.onResponseSuccess = this.responseSuccessEventBus.on;


        // REQUEST interceptors
        this.axiosInstance.interceptors.request.use(
            (axiosRequestConfig) => {
                // _logger.debug('request: ', axiosRequestConfig);
                return axiosRequestConfig;
            },
            (error) => {
                return Promise.reject(error);
            });


        // RESPONSE interceptors
        this.axiosInstance.interceptors.response.use(
            // on success handling
            (axiosResponse) => {
                // _logger.debug('response SUCCESS: ', axiosResponse.request.responseURL, axiosResponse.data);
                this.responseSuccessEventBus.emit({ headers: axiosResponse.headers });
                return axiosResponse;
            },


            (error) => {
                _logger.debug(`response ERROR for request: ` + error.request, error.isAxiosError ? error.toJSON() : JSON.stringify(error));

                // handle authentication errors, usually caused by an invalid token
                if (error.response?.status === 401) {
                    // remove the bidder token for further use
                    this._setBidderTokenHeader(null);
                }

                return Promise.reject(error);
            });


        if (__DEV__) {
            ((window || {}) as any)._dev_FrontendApiService_instance = this;
        }
    }

    // for dev/debug purpose
    get _axiosInstance():AxiosInstance {
        return this.axiosInstance;
    }



    async asyncRemoteVerifyBidderToken(setBidderToken:string|null):Promise<boolean|null> {
        if (setBidderToken || setBidderToken === null) {
            this._setBidderTokenHeader(setBidderToken, true);
        }
        if (! this._bidderToken) {
            // if no token is set, then don't even trigger the remote request
            return false;
        }
        try {
            // NOTE! if this fails with error status 401, it will trigger the removal of the header token from axiosInstance
            await this.getAccountMe();

            _logger.info('.asyncRemoteVerifyBidderToken: token VALID');
            return true;
        }
        catch (ex) {
            _logger.warn('.asyncRemoteVerifyBidderToken: FAILED to `GET /account/me`', ex);
            return false;
        }
    }


    private _setBidderTokenHeader(token:string|null, skipEmitEvent?:true) {
        if (token) {
            this._bidderToken = token;
            this.axiosInstance.defaults.headers[HEADER_BIDDER_TOKEN] = token;
            _logger.info('._setBidderTokenHeader: token PRESENT');
        }
        else {
            this._bidderToken = null;
            this.axiosInstance.defaults.headers[HEADER_BIDDER_TOKEN] = undefined;
            delete this.axiosInstance.defaults.headers[HEADER_BIDDER_TOKEN];
            _logger.info('._setBidderTokenHeader: token NULL');
        }

        if (! skipEmitEvent) {
            this.authenticationChangeEventBus.emit(!!this._bidderToken);
        }
    }


    get bidderToken():string|null {
        return this._bidderToken;
    }


    getAccountMe():AxiosPromise<{id:number|null, email?:string}> {
        return this.axiosInstance.get('/account/me');
    };



    getAuctions(params:ApiRequestParams_detailsLevel & { completes_at?:-1|1 }):AxiosPromise<ApiPaginatedList<ApiResource_Auction>> {
        return this.axiosInstance.get('/auctions', { params });
    };

    getAuctionDetails(auctionId:number, params:ApiRequestParams_detailsLevel):AxiosPromise<ApiResource_Auction> {
        return this.axiosInstance.get(`/auctions/${auctionId}`, { params })
    }

    putAuctionBiddingWarrant(auctionId:number, expectedState:string):AxiosPromise<{current_state:string}> {
        return this.axiosInstance.put(`/auctions/${auctionId}/warrant`, { expected_state: expectedState });
    }



    getAuctionLots<T_ApiLot extends ApiResource_LotMetal>(auctionId:number, params:ApiRequestParams_lotsListBrowse = {}):AxiosPromise<ApiPaginatedList<T_ApiLot>> {
        return this.axiosInstance.get(`/auctions/${auctionId}/lots`, { params });
    };

    getWatchlistLotsRunning(params:ApiRequestParams_pagination = {}):AxiosPromise<ApiPaginatedList<ApiResource_WatchlistRunningLot>> {
        return this.axiosInstance.get(`/watchlist/lots/running`, { params });
    };

    getSearchLots(params:ApiRequestParams_pagination = {}):AxiosPromise<ApiPaginatedList<ApiResource_SiteSearchLot>> {
        return this.axiosInstance.get(`/search/lots`, { params: { ... params } });
    };


    postAuctionWebcastEnter(auctionId:number, viewerId:number = null):AxiosPromise<{ connect_data: WebcastAuctionConnectData_TypeDef }> {
        const data:{ viewer_id?:number } = {};
        if (viewerId) {
            data.viewer_id = viewerId;
        }
        return this.axiosInstance.post(`/auctions/${auctionId}/webcast_enter`, data);
    };

    getAuctionWebcastLots(auctionId:number, params:ApiRequestParams_pagination = {}):AxiosPromise<ApiPaginatedList<ApiResource_WebcastLot>> {
        return this.axiosInstance.get(`/auctions/${auctionId}/webcast_lots`, { params });
    };

    getAuctionRunningLotsTimedStaggered(auctionId:number, refTimestamp?:number, cursorTimestamp?:number):AxiosPromise<ApiLotsRunningTimedStaggeredList> {
        const params = cursorTimestamp > 1 ? { cursor_timestamp: cursorTimestamp, ref_timestamp: refTimestamp } : {};
        return this.axiosInstance.get(`/auctions/${auctionId}/lots/running_timed_staggered`, { params });
    };



    getAuctionLot<T_ApiLot extends ApiResource_LotMetal>(auctionId:number, lotId:number, detailsLevel:ApiResourceDetailsLevelsEnum = ApiResourceDetailsLevelsEnum.metal):AxiosPromise<T_ApiLot> {
        return this.axiosInstance.get(`/auctions/${auctionId}/lots/${lotId}`, { params: { details_level: detailsLevel }});
    }


    getAuctionLotItemMediaFiles(auctionId:number, lotId:number):AxiosPromise<ApiPaginatedList<ApiResource_ItemMediaFile_TypeDef>> {
        return this.axiosInstance.get(`/auctions/${auctionId}/lots/${lotId}/item_media_files`);
    }


    postAuctionLotBidOnline(auctionId:number, lotId:number, maxBidAmountCents:number, raise:boolean):AxiosPromise {
        return this.axiosInstance.post(`/auctions/${auctionId}/lots/${lotId}/bids/online`, { max_amount_cents: maxBidAmountCents, raise: raise });
    }

    getAuctionLotBids(auctionId:number, lotId:number):AxiosPromise<ApiPaginatedList<ApiResource_Bid_TypeDef, { auction_timezone_name: string }>> {
        return this.axiosInstance.get(`/auctions/${auctionId}/lots/${lotId}/bids`);
    }

    postAuctionLotInterest(auctionId:number, lotId:number, setIsWatched:boolean):AxiosPromise {
        return this.axiosInstance.put(`/auctions/${auctionId}/lots/${lotId}/interest`, { watched: setIsWatched });
    }

}

