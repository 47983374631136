import {CurrentBidder} from "../type_defs/model";
import AppController from "../AppController";


export default function getCurrentBidder(args:{ onNotAuthenticated?:()=>void } = {}):CurrentBidder|null {

    const currentBidder = AppController.instance?.currentBidder;

    const {onNotAuthenticated} = args;

    if (!currentBidder?.id && onNotAuthenticated) {
        onNotAuthenticated();
    }

    return currentBidder;
}
