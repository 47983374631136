import {ActionEvent, Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";
import debounce from "lodash.debounce";

import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";

const _logger = TaggedLogger.get('FormWithPhoneNumberInputsController');


export default class FormWithPhoneNumberInputsController extends Controller {

    utilsScriptUrl = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/24.4.0/build/js/utils.min.js";
    static targets = [ "phoneNumberInput"];

    intlTelInput = window['intlTelInput'];

    initialize() {
        this.intlTelInput.loadUtils(this.utilsScriptUrl);

        this.validatePhoneNumberInput = debounce(this.validatePhoneNumberInput, 500).bind(this);
    }

    validatePhoneNumberInput(event) {
        const inputElement = event.target as HTMLInputElement;
        const inputIntInstance = this.intlTelInput.getInstance(inputElement);
        const isAlternativePhoneNumberElement = inputElement === document.getElementById('bidder_alternate_phone_number');

        const isInputPhoneNumberValid = inputIntInstance.isValidNumber() || (inputElement.value.length === 0 && isAlternativePhoneNumberElement);

        if (isInputPhoneNumberValid) {
            inputElement.setCustomValidity("");
            inputElement.classList.remove("is-invalid");
            inputElement.classList.add("is-valid");

        } else {
            inputElement.setCustomValidity("Invalid phone number");
            inputElement.classList.remove("is-valid");
            inputElement.classList.add("is-invalid");
        }
        inputElement.reportValidity();
    }


    phoneNumberInputTargetConnected(inputElement:HTMLInputElement) {
        this.intlTelInput(inputElement, {
            hiddenInput: () => {
                return {
                    phone: `bidder[${inputElement.name}]`,
                };
            },
            countryOrder: ['us', 'ca', 'au', 'mc', 'nl', 'mx'],
            strictMode: true,
            nationalMode: true,
            initialCountry: NextLotJSDATA.current_site?.business_address_country_code || 'us',
            autoPlaceholder: 'aggressive',
            formatAsYouType: true,
            formatOnDisplay: true
        });

        inputElement.addEventListener("input", this.validatePhoneNumberInput);
    }


    phoneNumberInputTargetDisconnected(inputElement:HTMLInputElement) {
        inputElement.removeEventListener("input", this.validatePhoneNumberInput);
    }

}
