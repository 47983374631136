import {
    useLoadActiveAuctionsList,
    useLoadCompletedAuctionsList,
    useRealtimeUpdatingAuctionsList
} from "./AuctionsListHook";


import {
    useCurrentAuction,
    useCurrentAuctionModelState,
    useLoadAuctionAsCurrentAndRegisterForRealTimeUpdates
} from "./CurrentAuctionHooks";


import {
    useCurrentAuctionLotsListBrowsable,
    useCurrentAuctionSingleLot,
    useRemoteUpdatingAuctionLotsChangeListener,
    useRemoteUpdatingLotsInterestChangeListener
} from "./CurrentAuctionLotsListHook";

import {useDispatch} from "react-redux";
import {Dispatch} from "../rematch/store";


const useAppDispatch = useDispatch<Dispatch>;

export {
    useAppDispatch,

    useLoadActiveAuctionsList,
    useLoadCompletedAuctionsList,
    useRealtimeUpdatingAuctionsList,

    useCurrentAuction,
    useCurrentAuctionModelState,
    useLoadAuctionAsCurrentAndRegisterForRealTimeUpdates,

    useCurrentAuctionLotsListBrowsable,
    useRemoteUpdatingAuctionLotsChangeListener,
    useRemoteUpdatingLotsInterestChangeListener,

    useCurrentAuctionSingleLot,
};
