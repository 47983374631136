
export type PropertyMergerFunction<T> = (existingValue:T, updateValue:T)=>T;



export const genericNotUndefinedValueMergerFn:PropertyMergerFunction<string|number|boolean|any> = (existingValue, updateValue) => {
    // the non-undefined value will be resulted
    return (existingValue !== undefined)
        ? (updateValue !== undefined ? updateValue : existingValue)
        : updateValue;
}


