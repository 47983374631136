import {createModel} from "@rematch/core";
import {orderBy} from "lodash";
import {RootModel} from "./index";
import AppController from "../../AppController";
import {
    CatalogLot_ModelTypeDef,
    LotMetal_ModelTypeDef,
    LotsPaginatedList_TypeDef,
    LotsRunningTimedStaggeredList_TypeDef,
    RunningLotMetal_ModelTypeDef,
    WatchlistRunningLot_ModelTypeDef
} from "../../type_defs/model";
import {ApiResource_LotMetal, ApiResourceDetailsLevelsEnum} from "../../type_defs/api";
import {TaggedLogger} from "../../utilities";

const _logger = TaggedLogger.get('watchlistLotsModel');



export type WatchlistModelState = {
    lotsListActive: Array<WatchlistRunningLot_ModelTypeDef>,
    lotsListClosed: Array<WatchlistRunningLot_ModelTypeDef>,

    isInitialFetching: boolean,
    isFetching: boolean,
    hasFetchError?: string|boolean,

    refTimestamp: number|null,

    pageNumber: number,
    pagesCount: number,
    totalCount: number,
}


export const watchlist = createModel<RootModel>()({
    name: 'watchlist',

    state: {
        isInitialFetching: true,
        isFetching: true,

        lotsListActive: [],
        lotsListClosed: [],

        refTimestamp: null,
        pageNumber: 1,
        pagesCount: 1,
        totalCount: 0,
    } as WatchlistModelState,



    reducers: {
        resetDataBeforeInitialFetch: (state, payload?) => {
            state.isInitialFetching = true;
            state.isFetching = true;
            state.hasFetchError = null;

            state.lotsListActive = [];
            state.lotsListClosed = [];

            state.refTimestamp = null;
            state.pageNumber = 1;
            state.pagesCount = 1;
            state.totalCount = 0;
        },

        setIsFetching: (state, payload:boolean = true) => {
            state.isFetching = payload;
        },

        setFetchError: (state, payload:string|boolean) => {
            state.isInitialFetching = false;
            state.isFetching = false;
            state.hasFetchError = payload;
        },

        setViewList: (state, payload:LotsPaginatedList_TypeDef<WatchlistRunningLot_ModelTypeDef>) => {
            state.isInitialFetching = false;
            state.isFetching = false;
            state.hasFetchError = null;

            state.refTimestamp = payload.refTimestamp;
            state.pageNumber = payload.pageNumber;
            state.pagesCount = payload.pagesCount;
            state.totalCount = payload.totalCount;

            const lotsActive:Array<WatchlistRunningLot_ModelTypeDef> = [];
            const lotsClosed:Array<WatchlistRunningLot_ModelTypeDef> = [];

            (payload.list || []).forEach((lot) => {
                if (lot.isCompleted) {
                    lotsClosed.push(lot);
                }
                else {
                    lotsActive.push(lot);
                }
            })

            state.lotsListActive = orderBy(lotsActive, ['closesAt', 'numberSort', 'auctionId'], ['asc', 'asc', 'asc']);
            state.lotsListClosed = orderBy(lotsClosed, ['closesAt', 'numberSort', 'auctionId'], ['desc', 'asc', 'asc']);
        },


        appendViewListLotsActive: (state, payload:LotsPaginatedList_TypeDef<WatchlistRunningLot_ModelTypeDef>) => {
            state.isFetching = false;
            state.hasFetchError = null;

            // refTimestamp should be untouched from the first API call, just update paging
            state.pageNumber = payload.pageNumber;
            state.pagesCount = payload.pagesCount;
            state.totalCount = payload.totalCount;

            const lotsActive:Array<WatchlistRunningLot_ModelTypeDef> = [
                ... state.lotsListActive,
                ... payload.list.filter((pLot) =>
                    // remove all lots from paylod that already exist in the state lots
                    state.lotsListActive.every((sLot) => (sLot.id !== pLot.id))
                )
            ];

            state.lotsListActive = orderBy(lotsActive, ['closesAt', 'numberSort', 'auctionId'], ['asc', 'asc', 'asc']);
        },


        updateLotAsClosed: (state, payloadLotId:number) => {
            const lotsActive: Array<WatchlistRunningLot_ModelTypeDef> = [];

            let closedLot:WatchlistRunningLot_ModelTypeDef = null;
            state.lotsListActive.forEach((lot) => {
                if (lot.id === payloadLotId) {
                    closedLot = lot;
                }
                else {
                    lotsActive.push(lot);
                }
            });

            state.lotsListActive = lotsActive;
            state.lotsListClosed = [ closedLot, ... state.lotsListClosed ];
        },


        updateRunningLotAndExtendLinkedLots: (state, payloadLot:RunningLotMetal_ModelTypeDef) => {
            state.lotsListActive?.forEach((sLot) => {
                mutateRunningLotWithBidAndLinkedGroupExtension(sLot, payloadLot);
            });
        },


        updateLotWithChangedInterest: (state, payloadLot:LotMetal_ModelTypeDef) => {
            const funcSetLotWatched = (sLot) => {
                if (sLot.id === payloadLot.id) {
                    sLot.myWatched = payloadLot.myWatched;
                }
            }
            state.lotsListActive?.forEach(funcSetLotWatched);
            state.lotsListClosed?.forEach(funcSetLotWatched);
        },

    },



    effects: (dispatch) => ({

        async asyncInitialLoadWatchedLots() {
            try {
                dispatch.watchlist.resetDataBeforeInitialFetch();
                let responseData = await AppController.instance.remoteDataHandler
                    .fetchWatchlistLotsListBrowsable({ pageNumber: 1 });

                dispatch.watchlist.setViewList(responseData)
            }
            catch (ex) {
                dispatch.watchlist.setFetchError(ex.toString() || true);
            }
        },


        async asyncLoadMoreWatchedLots(_params?, rootState?) {
            try {
                dispatch.watchlist.setIsFetching(true);

                let responseData = await AppController.instance.remoteDataHandler
                    .fetchWatchlistLotsListBrowsable({
                        refTimestamp: rootState.watchlist.refTimestamp,
                        pageNumber: rootState.watchlist.pageNumber + 1
                    });

                dispatch.watchlist.appendViewListLotsActive(responseData)
            }
            catch (ex) {
                dispatch.watchlist.setFetchError(ex.toString() || true);
            }
        },


        async asyncFetchAndUpdateLotChangedInterest({auctionId, lotId}:{auctionId:number, lotId:number}, rootState) {
            // fetch Lot via API
            const updatedLot:LotMetal_ModelTypeDef =
                await AppController.instance.remoteDataHandler.fetchAuctionLot<LotMetal_ModelTypeDef, ApiResource_LotMetal>(auctionId, lotId, ApiResourceDetailsLevelsEnum.metal);

            dispatch.watchlist.updateLotWithChangedInterest(updatedLot);
        },


    }),
})




function mutateRunningLotWithBidAndLinkedGroupExtension(mutableSourceLot:RunningLotMetal_ModelTypeDef, payloadLot:RunningLotMetal_ModelTypeDef) {
    const { id, linkedLotsGroupId, completesAt, isCompleted } = payloadLot;
    const completesAtSeconds = completesAt.toSeconds();

    if (mutableSourceLot.id === id) {
        mutableSourceLot.bidsCount = payloadLot.bidsCount;
        mutableSourceLot.askingBidAmountCents = payloadLot.askingBidAmountCents;
        mutableSourceLot.leadingBidAmountCents = payloadLot.leadingBidAmountCents;
        mutableSourceLot.myBidIsLeadingBid = payloadLot.myBidIsLeadingBid;
        mutableSourceLot.myMaxBidAmountCents = payloadLot.myMaxBidAmountCents;
        mutableSourceLot.myBidExistsInHistory = payloadLot.myBidExistsInHistory;
        mutableSourceLot.reservePriceStatusCode = payloadLot.reservePriceStatusCode;

        if (mutableSourceLot.completesAt.toSeconds() !== completesAtSeconds || mutableSourceLot.isCompleted !== isCompleted) {
            mutableSourceLot.completesAt = completesAt;
            mutableSourceLot.isCompleted = isCompleted;
            mutableSourceLot.hasRound = payloadLot.hasRound;
        }
    }
    else if (linkedLotsGroupId && mutableSourceLot.linkedLotsGroupId === linkedLotsGroupId) {
        if (mutableSourceLot.completesAt.toSeconds() !== completesAtSeconds || mutableSourceLot.isCompleted !== isCompleted) {
            mutableSourceLot.completesAt = completesAt;
            mutableSourceLot.isCompleted = isCompleted;
        }
    }
}
