import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../rematch/store";
import {CurrentAuctionLotsModelState} from "../rematch/models/currentAuctionLotsModel";
import {ListingBrowserParams, RunningCatalogLot_ModelTypeDef} from "../type_defs/model";
import AppController from "../AppController";
import {TaggedLogger} from "../utilities";
import getCurrentBidder from "./getCurrentBidder";

const _logger = TaggedLogger.get('CurrentAuctionLotsListHook');



export function useCurrentAuctionSingleLot(lotId:number):RunningCatalogLot_ModelTypeDef {

    const dispatch = useDispatch<Dispatch>();

    React.useEffect(() => {
        dispatch.currentAuctionLots.asyncLoadForSingleLotView({ lotId });
    }, [lotId])

    return useSelector<RootState, RunningCatalogLot_ModelTypeDef>((state: RootState) => state.currentAuctionLots.lotInFocus);
}





type useCurrentAuctionLotsListBrowsable_HookReturnTypeDef = CurrentAuctionLotsModelState & {
    validSafePageNumber:(number)=>number
}

export function useCurrentAuctionLotsListBrowsable(auctionId: number, lotIdInFocus: number|null, browseParams:ListingBrowserParams):useCurrentAuctionLotsListBrowsable_HookReturnTypeDef {

    const { pageNumber, pageSize, filters, sortingCriteria, entityDetailsLevel } = browseParams;

    const dispatch = useDispatch<Dispatch>();

    React.useEffect(() => {
        dispatch.currentAuctionLots.resetDataBeforeInitialFetch();
    }, [auctionId]);


    React.useEffect(() => {
        //HACK! do not dispatch async reload if a lot is in focus
        if (! lotIdInFocus) {
            dispatch.currentAuctionLots.asyncLoadForBrowsableView({pageNumber, pageSize, filters, sortingCriteria, entityDetailsLevel});
        }
    }, [auctionId, lotIdInFocus, pageNumber, pageSize, filters, sortingCriteria, entityDetailsLevel])


    const lotsModel = useSelector((state: RootState) => state.currentAuctionLots);
    const pagesCount = lotsModel?.pagesCount || 1;

    const validSafePageNumber = React.useCallback((reqPageNumber:number) => {
        // ensure reqPageNumber is between 1 and pagesCount
        return Math.min(pagesCount, Math.max(1, reqPageNumber));
    }, [auctionId, pagesCount]);



    return {
        ... lotsModel,
        validSafePageNumber,
    };
}






export function useRemoteUpdatingAuctionLotsChangeListener(auctionId:number) {
    const dispatch = useDispatch<Dispatch>();

    React.useEffect(() => {

        const unbindChannelAuctionLots = AppController.instance.remoteEventsEmitter.onAuctionLotsChange(auctionId, (data) => {
            _logger.debug('[useRemoteUpdatingAuctionLotsChangeListener]>>useEffect>onAuctionLotsChange: data:', data);
            const {lotId} = data;
            dispatch.currentAuctionLots.asyncFetchAndUpdateRunningLotWithSideEffects({ auctionId, lotId });
        });

        return () => {
            unbindChannelAuctionLots();
        }
    }, [auctionId]);
}




export function useRemoteUpdatingLotsInterestChangeListener(auctionId:number) {
    const dispatch = useDispatch<Dispatch>();
    const currentBidderId = getCurrentBidder()?.id;

    React.useEffect(() => {

        const unbindListener = AppController.instance.remoteEventsEmitter.onMyLotsInterestChange((data) => {
            _logger.debug('[useRemoteUpdatingLotsInterestChangeListener]>>useEffect>onMyLotsInterestChange: data:', data);
            const {lotId, auctionId:dataAuctionId} = data;
            if (dataAuctionId === auctionId) {
                dispatch.currentAuctionLots.asyncFetchAndUpdateLotChangedInterest({lotId});
            }
        });

        return () => {
            unbindListener();
        }
    }, [currentBidderId, auctionId]);
}
