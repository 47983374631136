import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from "luxon";
import {Dispatch, RootState} from "../rematch/store";
import {ApiResourceDetailsLevelsEnum, AuctionTimelineStateCodeID_Enum, BiddingWarrantStateCode_Enum} from "../type_defs/api";
import {TaggedLogger} from "../utilities";
import AppController from "../AppController";
import {Auction_ModelTypeDef} from "../type_defs/model";
import {CurrentAuctionModelState} from "../rematch/models/currentAuctionModel";
import StaticModelsAndConstants from "../StaticModelsAndConstants";

// const _logger = TaggedLogger.get('CurrentAuctionHook')


export function useCurrentAuction():Auction_ModelTypeDef {
    return useSelector<RootState, Auction_ModelTypeDef>((state: RootState) => state.currentAuction.auction);
}

export function useCurrentAuctionModelState():CurrentAuctionModelState {
    return useSelector<RootState, CurrentAuctionModelState>((state: RootState) => state.currentAuction);
}



const _logger_hookLoadAuction = TaggedLogger.get('useLoadAuctionAsCurrentAndRegisterForRealTimeUpdates')

export function useLoadAuctionAsCurrentAndRegisterForRealTimeUpdates(auctionId:number):void {

    const dispatch = useDispatch<Dispatch>();

    React.useEffect(() => {
        if (auctionId) {
            dispatch.currentAuction.asyncFetchLoad({ auctionId, detailsLevel: ApiResourceDetailsLevelsEnum.minimal });
        }
    }, [auctionId]);


    const storeCurrentAuctionId = useSelector<RootState, number>((state: RootState) => state.currentAuction.auctionId);

    React.useEffect(() => {
        _logger_hookLoadAuction.debug('.useEffect: setup: storeCurrentAuctionId: ', storeCurrentAuctionId);

        if (! storeCurrentAuctionId) {
            // first render, before load
            return;
        }

        const cleanupHandlerForAuctionVisibilityChange = AppController.instance.remoteEventsEmitter
            .onAuctionVisibilityChange(async (data) => {
                _logger_hookLoadAuction.debug('.useEffect>onAuctionVisibilityChange: ', data);

                if (data.auctionId !== auctionId) {
                    // ignore for any other auction except *this* current in-view one
                    return;
                }

                if (! data.isVisible) {
                    // this means the current in-view auction was unpublished (it was set to draft|archive)
                    dispatch.currentAuction.setFetchError('Auctioneer has removed the Auction.');
                }
            });


        const cleanupHandlerForAuctionDescriptiveUpdate = AppController.instance.remoteEventsEmitter
            .onAuctionDescriptiveChange(async (data) => {
                _logger_hookLoadAuction.debug('.useEffect>onAuctionDescriptiveChange: ', data);

                if (data.auctionId !== auctionId) {
                    // ignore for any other auction except *this* current in-view one
                    return;
                }

                dispatch.currentAuction.asyncFetchLoad({ auctionId, detailsLevel: ApiResourceDetailsLevelsEnum.full });
            });


        const cleanupHandlerForAuctionBiddingWarrantChange = AppController.instance.remoteEventsEmitter
            .onAuctionBiddingWarrantStateChange(async (data) => {
                _logger_hookLoadAuction.debug('.useEffect>onAuctionBiddingWarrantStateChange: ', data);

                if (data.auctionId !== auctionId) {
                    // ignore for any other auction except *this* current in-view one
                    return;
                }

                dispatch.currentAuction.updateMyBiddingWarrantStateByCode(data.state as BiddingWarrantStateCode_Enum);
                dispatch.currentAuction.asyncFetchLoad({ auctionId, detailsLevel: ApiResourceDetailsLevelsEnum.metal });
            });


        const intervalReload = setInterval(() => {

            dispatch.currentAuction.asyncFetchLoad({ auctionId, detailsLevel: ApiResourceDetailsLevelsEnum.metal });

        }, 5 * 60_000); // every 5 minutes


        return () => {
            _logger_hookLoadAuction.debug('.useEffect: cleanup');

            clearInterval(intervalReload);
            cleanupHandlerForAuctionVisibilityChange();
            cleanupHandlerForAuctionDescriptiveUpdate();
            cleanupHandlerForAuctionBiddingWarrantChange();
        }

    }, [storeCurrentAuctionId]);
}


function useCurrentAuctionWithRealtimeUpdatedStatus():Auction_ModelTypeDef|null {
    const auction = useCurrentAuction() || {} as Auction_ModelTypeDef;
    const auctionId = auction.id;
    const { auctionType,
        startsAt, closingStartsAt, webcastStartsAt, closingEndsAt, completesAt, isCompleted,

        timelineState, currentSegmentType, webcastIsPrepared
    } = auction;

    let newTimelineState = timelineState;
    let newCurrentSegment = currentSegmentType;

    if (auctionId) {
        const timeNow = DateTime.now();

        if (isCompleted) {
            newTimelineState = StaticModelsAndConstants.AuctionTimelineStates.getById(AuctionTimelineStateCodeID_Enum.completed);
        }

    }


    return React.useMemo(() => {
            if (!auctionId) {
                return null;
            }

            return {
                ... auction,
            }
        },
        [
            auctionId,
            auctionType,
            startsAt?.toUnixInteger(),
            closingStartsAt?.toUnixInteger(),
            webcastStartsAt?.toUnixInteger(),
            closingEndsAt?.toUnixInteger(),
            completesAt?.toUnixInteger(),
        ]);
}
