import React from "react";
import {DateTime, Duration} from "luxon";
import {useDispatch} from "react-redux";
import AppController from "../AppController";
import {Auction_ModelTypeDef, RunningLotMetal_ModelTypeDef} from "../type_defs/model";
import {Dispatch} from "../rematch/store";
import {TaggedLogger} from "../utilities";
import {DATETIME_FORMAT_STRINGS, formatDateTime, formatDurationAsHumanString} from "../helpers";

const _logger = TaggedLogger.get('LotHooks');

export const WEBCAST_ENTER_TIMEFRAME_MS = 60 * 60_000; // 1 hour
const SECONDS_BEFORE_LOT_CLOSE_TO_SHOW_COUNTDOWN_TIMER: number = 12 * 3600;


export enum LotStatusCodesEnum {
    upcoming = 'upcoming',
    active = 'active',
    active_with_counter = 'active_with_counter',
    closing_now = 'closing_now',
    closed_time_expired = 'closed_time_expired',
    closed_completed_lot = 'closed_completed_lot',
    closed_webcast_lot = 'closed_webcast_lot',
    closed_stalled_auction = 'closed_stalled_auction',
    closed_webcast_preparing = 'closed_webcast_preparing',
    active_webcast_running = 'active_webcast_running',
    closed_completed_auction = 'closed_completed_auction',
}


export type AuctionLotUpdatingStatus_TypeDef = {
    statusCode: LotStatusCodesEnum,
    textDate?: string
    textTime?: string,
    textDuration?: string,
    secondsRemaining?: number,
}


type LotAndAuctionTuple = {
    lot: RunningLotMetal_ModelTypeDef,
    auction: Auction_ModelTypeDef
}



function calculateLotStatusCode({lot, auction}: LotAndAuctionTuple): LotStatusCodesEnum {
    const timeNow = DateTime.now();

    if (auction.isCompleted) {
        // Auction is COMPLETED
        return LotStatusCodesEnum.closed_completed_auction;
    }
    else if (auction.auctionType.hasOnlineBidding && auction.startsAt > timeNow) {
        // Auction is UPCOMING when it has an online bidding part (not Webcast-only)
        return LotStatusCodesEnum.upcoming;
    }
    else {
        // Auction is RUNNING (or if combined, it may be STALLED)

        if ((auction.auctionType.isWebcast) || (auction.auctionType.isCombinedAny && timeNow > auction.closingStartsAt)) {
            // auction is webcast
            // OR
            // auction is combined and online part closed

            if (auction.webcastIsPrepared) {
                // webcast is prepared
                if (timeNow > auction.webcastStartsAt.minus(WEBCAST_ENTER_TIMEFRAME_MS)) {
                    // current time is withing the webcast start timeframe

                    if (lot.hasRound) {
                        // Lot closed if it has a round
                        return LotStatusCodesEnum.closed_webcast_lot;
                    }
                    else {
                        return LotStatusCodesEnum.active_webcast_running;
                    }
                }
                else {
                    // although the webcast is prepared, we're not inside the webcast enter timeframe
                    return LotStatusCodesEnum.closed_stalled_auction;
                }
            }
            else {
                // webcast is NOT prepared
                if (timeNow > auction.webcastStartsAt) {
                    // current time is past scheduled start time
                    return LotStatusCodesEnum.closed_webcast_preparing;
                }
                else {
                    return LotStatusCodesEnum.closed_stalled_auction;
                }
            }
        }

        // the Auction is running ONLINE (if it's combined, it's in the online part)
        // the Lot may be:
        // - closed completed_lot — Timed staggered
        // - closing online bidding
        // - active/running online bidding

        if (lot.isCompleted) {
            return LotStatusCodesEnum.closed_completed_lot;
        }

        const duration: Duration = lot.completesAt.diffNow();
        const durationSeconds = Math.ceil(duration.as('seconds'));

        if (durationSeconds < 0) {
            return LotStatusCodesEnum.closed_time_expired;
        }
        else if (durationSeconds < 2) {
            return LotStatusCodesEnum.closing_now;
        }
        else if (durationSeconds < SECONDS_BEFORE_LOT_CLOSE_TO_SHOW_COUNTDOWN_TIMER) {
            return LotStatusCodesEnum.active_with_counter;
        }
        else {
            return LotStatusCodesEnum.active;
        }
    }
}


function buildFullStateForLotStatusCode(lotStatusCode:LotStatusCodesEnum, { lot, auction }:LotAndAuctionTuple):AuctionLotUpdatingStatus_TypeDef {

    const newState:AuctionLotUpdatingStatus_TypeDef = {
        statusCode: lotStatusCode,
    };

    if (lotStatusCode === LotStatusCodesEnum.upcoming) {
        // Auction UPCOMING
        return {
            statusCode: lotStatusCode,
            textDate: formatDateTime(auction.startsAt, DATETIME_FORMAT_STRINGS.LOT_CARD_WITH_WEEKDAY),
            textTime: formatDateTime(auction.startsAt, DATETIME_FORMAT_STRINGS.LOT_CARD_TIME_SIMPLE),
        }
    }
    else if (lotStatusCode === LotStatusCodesEnum.closed_completed_auction) {
        // Auction COMPLETED
        return newState;
    }
    else {
        // Auction RUNNING or STALLED


        switch (lotStatusCode) {
            case LotStatusCodesEnum.active: {
                return {
                    statusCode: lotStatusCode,
                    textDate: formatDateTime(lot.completesAt, DATETIME_FORMAT_STRINGS.LOT_CARD_WITH_WEEKDAY),
                    textTime: formatDateTime(lot.completesAt, DATETIME_FORMAT_STRINGS.LOT_CARD_TIME_SIMPLE),
                }
            }

            case LotStatusCodesEnum.active_with_counter: {
                const duration: Duration = lot.completesAt.diffNow();
                const durationSeconds = Math.ceil(duration.as('seconds'));
                return {
                    statusCode: lotStatusCode,
                    textDate: formatDateTime(lot.completesAt, DATETIME_FORMAT_STRINGS.LOT_CARD_WITH_WEEKDAY),
                    textTime: formatDateTime(lot.completesAt, DATETIME_FORMAT_STRINGS.LOT_CARD_TIME_SIMPLE),
                    textDuration: formatDurationAsHumanString(duration),
                    secondsRemaining: durationSeconds,
                }
            }

            case LotStatusCodesEnum.closed_stalled_auction: {
                return {
                    statusCode: lotStatusCode,
                    textDate: formatDateTime(auction.webcastStartsAt, DATETIME_FORMAT_STRINGS.LOT_CARD_WITH_WEEKDAY),
                    textTime: formatDateTime(auction.webcastStartsAt, DATETIME_FORMAT_STRINGS.LOT_CARD_TIME_SIMPLE),
                }
            }

        } //switch(lotStatusCode)
    }

    return newState;
}




export function useAuctionLotUpdatingStatus(lot: RunningLotMetal_ModelTypeDef, auction: Auction_ModelTypeDef): AuctionLotUpdatingStatus_TypeDef {

    const dispatchStore = useDispatch<Dispatch>();

    const [currentStatus, setCurrentStatus] =
        React.useState<AuctionLotUpdatingStatus_TypeDef>(buildFullStateForLotStatusCode(calculateLotStatusCode({ lot, auction }), { lot, auction }));

    React.useEffect(() => {
        const unsubscribeFromTimerTick = AppController.instance.timerEventsEmitter.onEverySecond(() => {
            const lotStatusCode = calculateLotStatusCode({ lot, auction });
            // _logger.debug('>>>>>>>>>lotStatusCode:', lotStatusCode);

            if (lotStatusCode === LotStatusCodesEnum.closing_now) {
                // ensure we fetch the most updated status from the server, when the lot is about to close or closed_time_expired
                dispatchStore.currentAuctionLots.asyncFetchAndUpdateRunningLotWithSideEffects({ auctionId: lot.auctionId, lotId: lot.id });
            }

            setCurrentStatus((prevState) => {
                //
                // if statusCode is changed for: upcoming, completed_auction, active(no_countdown_timer), completed_lot, stalled_auction
                // => return exact same state object (avoid re-rendering)
                if (lotStatusCode !== prevState.statusCode
                    || [LotStatusCodesEnum.active_with_counter].includes(lotStatusCode)) {
                    return buildFullStateForLotStatusCode(lotStatusCode, {lot, auction});
                }

                return prevState;
            });
        });

        return () => {
            unsubscribeFromTimerTick();
        }

    }, [lot, auction]);

    return currentStatus;
}


