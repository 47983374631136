import React from "react";
import classNames from "classnames";
import {CatalogLot_ModelTypeDef, LotItemMediaFile_ModelTypeDef} from "@nextlot/core/type_defs/model";
import StaticModelsAndConstants from "@nextlot/core/StaticModelsAndConstants";
import ZoomableImage from "./ZoomableImage";
import YouTube, { YouTubeProps } from 'react-youtube';

// https://stackoverflow.com/a/27728417/317884
const REGEXP_YOUTUBE_VIDEOID = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/


type LotItemMediaFileWithVideo_ModelTypeDef = LotItemMediaFile_ModelTypeDef & {
    videoId?: string,
}


export default function LotMediaFilesGallery({lot}:{lot:CatalogLot_ModelTypeDef}) {

    const mediaFiles:Array<LotItemMediaFileWithVideo_ModelTypeDef> = React.useMemo(() => {

        if (! lot) {
            return null;
        }

        let videoMediaFile:LotItemMediaFileWithVideo_ModelTypeDef;
        if (lot.videoUrl) {

            const mediaType = lot.videoUrl.includes('youtu')
                ? StaticModelsAndConstants.ItemMediaTypes.data.video_youtube
                : StaticModelsAndConstants.ItemMediaTypes.data.video_vimeo

            videoMediaFile = {
                id: 99, // by convention set to hardcoded value, must be unique within all other itemMediaFiles for this Lot
                lotId: lot.id,
                auctionId: lot.auctionId,
                mediaType: mediaType,
                sortIdx: -1, // make it 1st in list order
                url: lot.videoUrl,
                urlHighDef: lot.videoUrl,
                urlThumbImage: lot.videoUrlThumbImage,
                videoId: lot.videoUrl.match(REGEXP_YOUTUBE_VIDEOID)[1],
            }
        }

        return [videoMediaFile].filter(Boolean).concat(lot.mediaFiles);

    }, [lot?.mediaFiles, lot?.videoUrl]);

    const [selectedThumbnailItem, setSelectedThumbnailItem] = React.useState<[LotItemMediaFile_ModelTypeDef, number]>([null, -1]);

    const [_selectedMediaFile, selectedThumbnailItemIdx] = selectedThumbnailItem;
    const selectedMediaFileId = _selectedMediaFile?.id || (mediaFiles.length ? mediaFiles[0].id : null);


    React.useEffect(() => {
        if (mediaFiles?.length) {
            setSelectedThumbnailItem([mediaFiles[0], 0]);
        }
        else {
            setSelectedThumbnailItem([null, -1]);
        }
    }, [lot?.mediaFiles, lot?.videoUrl])


    const handleClick_thumbnailItem = React.useCallback((mf:LotItemMediaFile_ModelTypeDef, mfIdx:number) => (evt) => {
        setSelectedThumbnailItem([mf, mfIdx]);
    }, [lot?.mediaFiles, lot?.videoUrl]);

    const handleClick_navigateToItem = React.useCallback((direction:number) => (evt) => {
        evt.preventDefault();

        if (! mediaFiles?.length) {
            return;
        }

        setSelectedThumbnailItem(([_mf, cIdx]) => {
            let nIdx = cIdx + direction;
            if (nIdx >= mediaFiles.length) {
                nIdx = 0;
            }
            if (nIdx < 0) {
                nIdx = mediaFiles.length-1;
            }
            return [mediaFiles[nIdx], nIdx];
        });
    }, [lot?.mediaFiles, lot?.videoUrl]);


    if (! mediaFiles?.length) {
        return (
            <div className='p-3 text-bg-light'>
                There are no photos for this lot.
            </div>
        )
    }

    return (
        <div className='lot-details__gallery'>
            <div className='gallery__large-view-container carousel'>
                <div className='carousel-inner'>
                    {
                        mediaFiles.map((mf, idx) => {
                            return (
                                <div key={mf.id} className={classNames('carousel-item', (selectedMediaFileId === mf.id) && 'active')}>
                                    {
                                        (mf.mediaType.isImage)
                                            ? <ZoomableImage
                                                src={mf.url}
                                                fullSizeSrc={mf.urlHighDef || mf.url}
                                                alt="Lot photo"
                                            />
                                            : <YouTubePlayer mediaFile={mf} isSelectedMediaFileId={selectedMediaFileId === mf.id} />

                                    }
                                </div>
                            )
                        })
                    }

                    <button className="carousel-control-prev" type="button" onClick={handleClick_navigateToItem(-1)}>
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" onClick={handleClick_navigateToItem(+1)}>
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>

                    <div className="carousel-hint-container">
                        {
                            mediaFiles.find(mf => mf.id === selectedMediaFileId).mediaType.isImage
                            &&
                            <span>Click or tap the image to zoom in and out</span>
                        }
                    </div>
                </div>
            </div>


            <div className='gallery__thumbnails-list-container'>
                <div className='list-scrollable-container'>
                    {mediaFiles.map((mf, idx) => {
                        return (
                            <img key={mf.id}
                                 src={mf.urlThumbImage}
                                 alt={`Lot media file ${idx}`}
                                 className={idx === selectedThumbnailItemIdx ? 'active' : ''}
                                 onClick={handleClick_thumbnailItem(mf, idx)}
                            />
                        )

                    })}
                </div>
            </div>
        </div>
    )
}

function YouTubePlayer({ mediaFile, isSelectedMediaFileId }:{ mediaFile: LotItemMediaFileWithVideo_ModelTypeDef, isSelectedMediaFileId: boolean}) {
  const refVideo = React.useRef(null);

  const playerOpts ={
                      playerVars: {
                          // https://developers.google.com/youtube/player_parameters
                          autoplay: isSelectedMediaFileId ? 1 : 0,
                          controls: 0,
                          fs: 1, // no fullscreen
                          iv_load_policy: 3, // no annotations
                          modestbranding: 1, // smaller youtube logo
                          showinfo: 0, // do not show info
                          rel: 0, // show only video related from he same channel
                      }
                    }
  !isSelectedMediaFileId && refVideo.current.getInternalPlayer().stopVideo();
  return <YouTube ref={refVideo} videoId={mediaFile.videoId}
            className='mx-auto ratio ratio-16x9'
            style={{ width: '80%' }} // Make the video fit its entire eight within the `.carousel-item` boundary.
            opts={playerOpts}
          />;
}
