import TaggedLogger from "./TaggedLogger";

const _loggerEB = TaggedLogger.get('EventBus');

type ListenerFunction = (data?:any)=>void

export class EventBus {
    private readonly allListenersMap: Map<string, Set<ListenerFunction>>;

    constructor() {
        this.allListenersMap = new Map(); // <eventType, Set<ListenerFn>>
    }

    on = (eventType:string, listenerFn:ListenerFunction):()=>void => {
        let eventTypeListeners:Set<ListenerFunction> = this.allListenersMap.get(eventType);
        if (!eventTypeListeners) {
            eventTypeListeners = new Set();
            this.allListenersMap.set(eventType, eventTypeListeners);
        }
        eventTypeListeners.add(listenerFn);
        return () => {
            eventTypeListeners.delete(listenerFn);
        }
    }

    emit = (eventType:string, data?:any) => {
        const typeListeners:Set<ListenerFunction> = this.allListenersMap.get(eventType);
        if (!typeListeners) {
            return;
        }

        typeListeners.forEach((listenerFn) => {
            listenerFn(data);
        });
    }

    offAll = (eventType:string) => {
        this.allListenersMap.delete(eventType);
    }
}


const _loggerSTEB = TaggedLogger.get('SingleTypeEventBus');

export class SingleTypeEventBus<T extends any> {

    private _eb: EventBus;

    constructor() {
        this._eb = new EventBus();
    }

    on = (handlerFn:(data?:T)=>void) => {
        return this._eb.on('_default', handlerFn);
    }

    emit = (data?:T) => {
        this._eb.emit('_default', data);
    }

    offAll = () => {
        this._eb.offAll('_default');
    }
}


export class SimpleStoredDataEventBus<T extends any> {
    private data: T;
    private handlerFn: (data:T)=>void|null

    constructor(init:T = null) {
        this.data = init;
        this.handlerFn = null;
    }

    registerHandler = (handlerFn: (data:T)=>void) => {
        this.handlerFn = handlerFn;
        if (this.data) {
            this.handlerFn(this.data);
            this.data = null;
        }
    }


    unregisterHandler = () => {
        this.handlerFn = null;
    }

    emit = (data:T) => {
        this.data = data;
        if (this.handlerFn) {
            this.handlerFn(data);
            this.data = null;
        }
    }


}
