import React from "react";
import {Link, useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useSelector} from "react-redux";
import useListingUrlParams, {generatePathWithParamsFunction_TypeDef, parseFiltersString,} from "../hooks/useListingUrlParams";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {RootState} from "@nextlot/core/rematch/store";
import {
    useCurrentAuctionLotsListBrowsable,
    useRemoteUpdatingAuctionLotsChangeListener,
    useRemoteUpdatingLotsInterestChangeListener,
} from "@nextlot/core/hooks/CurrentAuctionLotsListHook";
import {TaggedLogger} from "@nextlot/core/utilities";
import {LotsViewModes_Enum} from "@nextlot/core/rematch/models/currentAuctionLotsModel";
import LotCard, {BiddingContextViewEnum} from "../components/LotCard";
import getCurrentBidder from "@nextlot/core/hooks/getCurrentBidder";
import {ApiResourceDetailsLevelsEnum} from "@nextlot/core";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import ListingsPagination from "src/components/ListingsPagination";

const _logger = TaggedLogger.get("AuctionBrowsableLotsPage");


type DefaultCmpProps_TypeDef = {
    currentViewMode: LotsViewModes_Enum,
    preloadedLotId?: number|null,
}


export default function AuctionBrowsableLotsPage({currentViewMode, preloadedLotId}:DefaultCmpProps_TypeDef) {
    _logger.debug('.render()');

    const navigate = useNavigate();

    const currentBidder = getCurrentBidder();

    const auction = useSelector<RootState, Auction_ModelTypeDef>((state: RootState) => state.currentAuction.auction);
    const auctionId = auction.id;


    const {
        pageNumber, pageSize,
        filters, sortingCriteria,
        generatePathWithParams} = useListingUrlParams();
    const auctionLots = useCurrentAuctionLotsListBrowsable(auctionId, preloadedLotId,
        { pageNumber, pageSize, filters, sortingCriteria,
            entityDetailsLevel: currentViewMode === LotsViewModes_Enum.list ? ApiResourceDetailsLevelsEnum.full : ApiResourceDetailsLevelsEnum.minimal
        });
    const filtersStruct = parseFiltersString(filters) || {};

    useRemoteUpdatingAuctionLotsChangeListener(auctionId);
    useRemoteUpdatingLotsInterestChangeListener(auctionId);

    const [isFilterWatchedActive, setIsFilterWatchedActive] = React.useState<boolean>(!!filtersStruct.watched);
    React.useEffect(() => {
        const filtersStruct = parseFiltersString(filters) || {};
        setIsFilterWatchedActive(!!filtersStruct.watched);
    }, [filters]);



    const [inputLotsSearchText, setInputLotsSearchText] = React.useState(filtersStruct.text_search || '')
    const handleChange_inputLotsSearchText = (evt) => {
        setInputLotsSearchText(evt.target.value);
    }
    const handleSubmit_searchLots = (evt) => {
        evt.preventDefault();
        navigate(generatePathWithParams({ filters: { text_search: inputLotsSearchText }}));
    }

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [auctionId, pageNumber]);



    if (auctionLots.hasFetchError) {
        return (
            <div className="py-3">
                <h4>Connection Error</h4>

                <div>
                    Please refresh the page in a few seconds.
                    <br/>
                    If that doesn't help, then connect to WiFi or a faster connection and try again.
                </div>
            </div>
        )
    }

    return (

        <div className="col">

            <div className="pt-3 pb-5 row g-2 d-flex">
                <div className="col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2 px-0">
                    <ListingsPagination
                        generatePathWithParams={generatePathWithParams}
                        validSafePageNumber={auctionLots.validSafePageNumber}
                        pageNumber={auctionLots.pageNumber}
                        pagesCount={auctionLots.pagesCount}
                    />
                </div>
                {
                    currentBidder
                    &&
                    <div className='col-12 col-md-3 col-lg-3 col-xl-2 pt-2 pt-md-0 text-sm-start text-md-center'>
                        <Link to={generatePathWithParams({ filters: { watched: !isFilterWatchedActive }})}
                              className={classNames('btn btn-sm', isFilterWatchedActive ? 'btn-dark' : 'btn-outline-dark')}>
                            {
                                isFilterWatchedActive
                                    ? <i className='fa-regular fa-square-check' />
                                    : <i className='fa-regular fa-square' />
                            }
                            &nbsp; Watched only
                        </Link>
                    </div>
                }

                <div className='col-12 col-md-3 mt-3 mt-md-2'>
                    <form onSubmit={handleSubmit_searchLots} className='d-flex flex-row'>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm"
                                   placeholder="Search lots"
                                   aria-label="Search lots"
                                   value={inputLotsSearchText}
                                   onChange={handleChange_inputLotsSearchText}
                            />
                            <button className="btn btn-sm btn-outline-dark px-5 px-md-2" type="submit">
                                <i className="fa-solid fa-magnifying-glass" />
                            </button>
                        </div>

                    </form>
                </div>

                <div className='col text-sm-start text-lg-end'>
                    <a href={`${NextLotJSDATA.webapp_root_base_url}/auctions/${auctionId}/catalog_print`} target="_blank" className="btn btn-sm btn-outline-dark text-nowrap"><i className="fa-regular fa-print"></i> Print preview</a>
                </div>
            </div>




            {
                // NOTE: `fastBidding` view mode is handled in the upper components stack, AuctionLotsPageRoot
            }

            {
                auctionLots.lotsList?.length
                    ? <div className={classNames('lots-view', { 'lots-view--grid': currentViewMode === LotsViewModes_Enum.grid, 'lots-view--list': currentViewMode === LotsViewModes_Enum.list })}>
                        {
                            auctionLots.lotsList
                                .map((lot, idx) => (
                                    <LotCard
                                        key={lot.id}
                                        auction={auction}
                                        lot={lot}
                                        biddingContextView={BiddingContextViewEnum.list}
                                    />))
                        }
                    </div>
                    : (
                        auctionLots.isFetching
                            ? null
                            : <div className='py-3'>
                                <div className='alert alert-warning'>There are no lots for display.</div>
                            </div>
                    )
            }

            <div className="py-5 d-flex flex-row align-items-center">
                <ListingsPagination
                    generatePathWithParams={generatePathWithParams}
                    validSafePageNumber={auctionLots.validSafePageNumber}
                    pageNumber={auctionLots.pageNumber}
                    pagesCount={auctionLots.pagesCount}
                />
            </div>

        </div>

    )
}

