import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {Auction_ModelTypeDef} from "../../type_defs/model";
import AppController from "../../AppController";
import {ApiResourceDetailsLevelsEnum, BiddingWarrantStateCode_Enum} from "../../type_defs/api";
import StaticModelsAndConstants from "../../StaticModelsAndConstants";
import ModelsHelpers from "./helpers";


export type CurrentAuctionModelState = {
    auctionId: number,
    auction: Auction_ModelTypeDef,

    isFetching?: boolean,
    hasFetchError?: string|boolean,

    isTimedFastBiddingAvailable?: boolean,
}

export const currentAuction = createModel<RootModel>()({
    name: 'currentAuction',

    state: {
        auctionId: null,
        auction: null,
        isFetching: true,
    } as CurrentAuctionModelState,

    reducers: {
        setAuctionByReplace: (state, payload:Auction_ModelTypeDef) => {
            state.auction = payload;
            state.auctionId = payload?.id;
            if (state.auction) {
                state.auction.completesAtDiffNowMinutes = Math.floor(state.auction.completesAt.diffNow().as('minutes'));
                state.isTimedFastBiddingAvailable = state.auction.auctionType.isTimed && state.auction.timedLotsStaggeringOffsetSeconds &&
                    state.auction.timelineState.isRunning && (state.auction.closingStartsAt.diffNow().as('minutes') < 15);
            }
            state.isFetching = false;
            state.hasFetchError = null;
        },

        updateAuction: (state, payload:Partial<Auction_ModelTypeDef>) => {
            state.auction = ModelsHelpers.mergeAuction(state.auction, payload);
            state.auction.completesAtDiffNowMinutes = Math.floor(state.auction.completesAt.diffNow().as('minutes'));
            state.isTimedFastBiddingAvailable = state.auction.auctionType.isTimed && state.auction.timedLotsStaggeringOffsetSeconds &&
                state.auction.timelineState.isRunning && (state.auction.closingStartsAt.diffNow().as('minutes') < 15);
            state.isFetching = false;
            state.hasFetchError = null;
        },


        setIsFetching: (state, payload:boolean = true) => {
            state.isFetching = payload;
        },

        setFetchError: (state, payload:string|boolean) => {
            state.isFetching = false;
            state.hasFetchError = payload;
        },


        updateMyBiddingWarrantStateByCode: (state, newWarrantStateCode:BiddingWarrantStateCode_Enum) => {
            state.auction.myBiddingWarrantState = StaticModelsAndConstants.BiddingWarrantStates.getByCode(newWarrantStateCode);
        },
    },


    effects: (dispatch) => ({

        async asyncFetchLoad({ auctionId, detailsLevel = ApiResourceDetailsLevelsEnum.full }:{ auctionId:number, detailsLevel?:ApiResourceDetailsLevelsEnum }, rootState) {
            try {
                dispatch.currentAuction.setIsFetching(true);
                const fetchedAuction = await AppController.instance.remoteDataHandler.fetchAuction(auctionId, detailsLevel);

                if (rootState.currentAuction.auction?.id === fetchedAuction.id) {
                    dispatch.currentAuction.updateAuction(fetchedAuction);
                }
                else {
                    dispatch.currentAuction.setAuctionByReplace(fetchedAuction);
                }
            }
            catch (ex) {
                dispatch.currentAuction.setFetchError(ex.toString() || true);
            }
        },

    }),

})
