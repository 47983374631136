import {Auction_ModelTypeDef} from "../../../type_defs/model";
import {ApiResourceDetailsLevelsEnum} from "../../../type_defs/api";
import {genericNotUndefinedValueMergerFn, PropertyMergerFunction} from "./commonHelpers";
import {TaggedLogger} from "../../../utilities";

const _logger = TaggedLogger.get('auctionsHelper');

/**
 * "mergers" (aka properties mergers) are functions that contain the logic how to update an existing value
 */

const AuctionPropertiesMergers:{ [key:string]:PropertyMergerFunction<any> } = {

    _detailsLevel: (eVal:null|ApiResourceDetailsLevelsEnum, uVal:ApiResourceDetailsLevelsEnum|null):ApiResourceDetailsLevelsEnum => {
        // details level is "additive", meaning that for example: "full" overwrites "medium", but "medium" cannot overwrite "full"

        if (! eVal) {
            return uVal;
        }
        else if (! uVal) {
            return eVal;
        }

        if (eVal === ApiResourceDetailsLevelsEnum.full) {
            return eVal;
        }
        if (uVal === ApiResourceDetailsLevelsEnum.full) {
            return uVal;
        }
        if (eVal === ApiResourceDetailsLevelsEnum.minimal) {
            return eVal;
        }
        if (uVal === ApiResourceDetailsLevelsEnum.minimal) {
            return uVal;
        }
        return ApiResourceDetailsLevelsEnum.minimal
    }
}





export const mergeAuction = (existingAuction:Auction_ModelTypeDef, updateAuction:Partial<Auction_ModelTypeDef>):Auction_ModelTypeDef => {
    const resultAuction:Auction_ModelTypeDef = {
        ... existingAuction
    }

    if (updateAuction) {
        const safeExistingAuction = existingAuction || {};
        Object.entries(updateAuction).forEach(([propKey, updateValue]) => {
            const mergerFn = AuctionPropertiesMergers[propKey] || genericNotUndefinedValueMergerFn;
            const existingValue = safeExistingAuction[propKey];
            resultAuction[propKey] = mergerFn(existingValue, updateValue);
        })
    }

    return resultAuction;
};





