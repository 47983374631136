import React from "react";
import debounce from "lodash.debounce";
import classNames from "classnames";
import {BidIncrementsTable_TypeDef} from "@nextlot/core/type_defs/model";
import {getMoneyHelperForCurrencyCode} from "@nextlot/core/services/MoneyHelper";
import {TaggedLogger} from "@nextlot/core";

type CmpProps_TypeDef = {
    bidIncrementsTable: BidIncrementsTable_TypeDef,
    currencyCode: string,
    minBidAmount: number,
    mustBeGreaterThanMinBidAmount: boolean,
    onSubmitBidAmount: (amount:number)=>void,
}


type AllowedBidListItem = [bidCents:number, bidFormatted:string];
type AllowedBidsList = Array<AllowedBidListItem>;


const _logger = TaggedLogger.get('BidAmountOnIncrementsInputDropdownField');

const ALLOWED_BIDS_CALCULATED_COUNT_MAX:number = 25_000;
const ALLOWED_MAX_BID_CENTS = 90_000_000 * 100; // $90 million
function calculateReasonableMaximumBidCentsFromMinimumAllowed(minCents:number):number {
    if (minCents < 50_000_00) {
        return 900_000_00;
    }
    else {
        return ALLOWED_MAX_BID_CENTS;
    }
}


export function LotBidOnIncrementWithDropdownSuggestionsInputField({ bidIncrementsTable, currencyCode, minBidAmount, mustBeGreaterThanMinBidAmount, onSubmitBidAmount }: CmpProps_TypeDef) {
    const moneyHelper = getMoneyHelperForCurrencyCode(currencyCode);

    const minBidCents = minBidAmount * 100;

    const calculateIncrementForBidCents = React.useCallback((bidCents:number):number => {
        const tuple = ([...bidIncrementsTable] || []).reverse().find((t) => (t[0] <= bidCents));
        return tuple?.length ? tuple[1] : 100; // this should never happen, but as failsafe to have an increment of 100 cents ($1.00)
    }, [bidIncrementsTable]);

    const calculateNextAllowedBidAfterBidCents = React.useCallback((bidCents:number):number => {
        return bidCents + calculateIncrementForBidCents(bidCents);
    }, [calculateIncrementForBidCents]);


    const minAllowedBidCents = React.useMemo(() => {
            return (mustBeGreaterThanMinBidAmount || minBidCents === 0)
                ? calculateNextAllowedBidAfterBidCents(minBidCents)
                : minBidCents
        },
        [minBidCents, mustBeGreaterThanMinBidAmount, calculateNextAllowedBidAfterBidCents]);

    const minAllowedBidMoney = React.useMemo(() => moneyHelper.fromCents(minAllowedBidCents), [minAllowedBidCents, currencyCode]);
    const minAllowedBidAmount = React.useMemo(() => minAllowedBidMoney.value, [minAllowedBidMoney]);
    const minAllowedBidFormatted = React.useMemo(() => minAllowedBidMoney.format(), [minAllowedBidMoney]);

    const [rawInputValue, setRawInputValue] = React.useState<string>('');
    const [inputAmount, setInputAmount] = React.useState(minAllowedBidAmount);
    const [isInputValid, setIsInputValid] = React.useState(true);


    const allowedBidsCentsList = React.useMemo(() => {
        const bidsCents:Array<number> = [];
        const reasonableMaxBidCents = calculateReasonableMaximumBidCentsFromMinimumAllowed(minAllowedBidCents);

        let stepBidCents = minAllowedBidCents;
        while (stepBidCents) {
            bidsCents.push(stepBidCents);
            stepBidCents = calculateNextAllowedBidAfterBidCents(stepBidCents);
            if (stepBidCents >= reasonableMaxBidCents || bidsCents.length > ALLOWED_BIDS_CALCULATED_COUNT_MAX) {
                stepBidCents = 0;
            }
        }
        return bidsCents;
    }, [minAllowedBidCents, mustBeGreaterThanMinBidAmount, calculateNextAllowedBidAfterBidCents]);


    const buildSuggestedBidsListFromInputAmount = React.useCallback((inputAmount:number):AllowedBidsList => {
        // ensure the starting bid is valid
        const validInputBidCents = (inputAmount >= minAllowedBidAmount) ? inputAmount*100 : minAllowedBidCents;

        // Create a copy of the array and reverse that to keep original array intact
        const reversedAllowedBidsCentsList = [...allowedBidsCentsList].reverse();
        const idx = reversedAllowedBidsCentsList.findIndex(c => (c <= validInputBidCents));
        // take the next 2 allowed bids (in reverse order), if the found bid is *not* the first *nor* the last in the list
        const suggestedBidsCount = (idx === 0 || idx === allowedBidsCentsList.length-1) ? 1 : 2;

        const startIndexForSuggestion = suggestedBidsCount > 1 ? idx-1 : idx;

        return reversedAllowedBidsCentsList.slice(startIndexForSuggestion, startIndexForSuggestion+suggestedBidsCount)
            .reverse()
            .map(c => ([c, moneyHelper.formatFromCents(c)]));

    }, [allowedBidsCentsList, currencyCode]);


    const [isInputFocused, setIsInputFocused] = React.useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
    const [suggestedBidsList, setSuggestedBidsList] = React.useState<AllowedBidsList>(buildSuggestedBidsListFromInputAmount(inputAmount));


    // _logger.debug('minBidAmount: ', minBidAmount)
    // _logger.debug('minAllowedBidAmount: ', minAllowedBidAmount)
    // _logger.debug('allowedBidsCentsList: ', allowedBidsCentsList.slice(0, 10))
    // _logger.debug('suggestedBidsList: ', suggestedBidsList)


    // re-initialize input after a submit
    React.useEffect(() => {
        setRawInputValue('');
        setInputAmount(minAllowedBidAmount);
    }, [minAllowedBidAmount]);

    // run validation, and build suggestions
    React.useEffect(() => {
        // inputAmount is valid when it's in the list of allowed bids
        const isValid = allowedBidsCentsList.includes(inputAmount*100);

        if (!isValid) {
            // if invalid, build suggestions
            // we build suggestions here, so it's triggered by invalid status, and NOT by focus
            setSuggestedBidsList(buildSuggestedBidsListFromInputAmount(inputAmount));
        }

        setIsInputValid(isValid);

    }, [inputAmount, allowedBidsCentsList, buildSuggestedBidsListFromInputAmount]);


    React.useEffect(() => {
        if (document.hasFocus()) {
            // show dropdown when input is focused *AND* the input is present but invalid
            const isVisible = isInputFocused && !isInputValid
            setIsDropdownVisible(isVisible);
        }
    }, [isInputFocused, isInputValid])




    const debouncedUpdateAfterInputChange = React.useMemo(() => debounce((rawStrVal:string) => {
        setInputAmount(rawStrVal ? parseFloat(rawStrVal) : minAllowedBidAmount);
    }, 50), [minAllowedBidAmount]);

    const handleChange_bidNumberInput = React.useCallback((evt) => {
        setRawInputValue(evt.target.value);
        debouncedUpdateAfterInputChange(evt.target.value);
    }, [debouncedUpdateAfterInputChange]);


    const handleFocusChange_bidNumberInput = (isFocused:boolean) => (evt) => {
        setIsInputFocused(isFocused);
    }

    const handleClick_suggestedBidListItem = (bidCents:number) => (evt) => {
        const bidAmount = bidCents/100;
        setRawInputValue(bidAmount.toString());
        setIsDropdownVisible(false);
        setInputAmount(bidAmount);
    };


    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        onSubmitBidAmount(inputAmount);
    };


    return (
        <form onSubmit={handleFormSubmit}>
            <div className="w-100 d-flex flex-row">
                <div className="flex-grow-1">
                    <input type="number"
                           min={minAllowedBidAmount}
                           step="0.01"
                           placeholder={`${minAllowedBidFormatted} or more`}
                           className={classNames('form-control bid-amount-input', { 'is-invalid': !isInputValid })}
                           value={rawInputValue}
                           onChange={handleChange_bidNumberInput}
                           onFocus={handleFocusChange_bidNumberInput(true)}
                           onBlur={handleFocusChange_bidNumberInput(false)}
                    />

                    {
                        isDropdownVisible
                        &&
                        <div className="position-relative">
                            <div className="position-absolute w-100 bg-white shadow-lg">
                                <div className={classNames('position-relative px-3 py-1 small text-start border-bottom fw-bold shadow-sm', {'text-danger': !isInputValid })}>
                                    Select to bid on increment
                                    <i className="position-absolute mt-2 me-2 top-0 end-0 text-end fa-sharp fa-solid fa-caret-down"></i>
                                </div>
                                <div
                                    style={{
                                        maxHeight: '115px'
                                    }}
                                    className="overflow-auto"
                                >
                                    {
                                        suggestedBidsList?.length
                                        &&
                                        <ul className="list-group rounded-0">
                                            {
                                                suggestedBidsList.map(([suggestedBidCents, suggestedBidFormatted]:AllowedBidListItem) => {
                                                    return (
                                                        <a
                                                            key={suggestedBidCents}
                                                            className='text-start list-group-item list-group-item-action border-0 has-cursor-pointer'
                                                            // HACK-FIX: blur is always fired before click https://stackoverflow.com/a/57630197
                                                            onMouseDown={(evt) => evt.preventDefault()}
                                                            onClick={handleClick_suggestedBidListItem(suggestedBidCents)}
                                                        >
                                                            <span>{suggestedBidFormatted}</span>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <button
                    type='submit'
                    disabled={!isInputValid}
                    className='ms-1 btn btn-primary px-5 px-md-3'
                >Bid</button>
            </div>
        </form>
    )
}

