import reducerWbsNetworkStatus, {ReduxWebcastWbsNetworkStatusType} from "./webcast/reducerWbsNetworkStatus";
import reducerWebcastStatus, {ReduxWebcastStatusType} from "./webcast/reducerWebcastStatus";
import reducerWebcastAuctionData, {ReduxWebcastAuctionDataType} from "./webcast/reducerWebcastAuctionData";
import reducerWebcastCurrentRound, {ReduxWebcastCurrentRoundType} from "./webcast/reducerWebcastCurrentRound";
import reducerWebcastCurrentRoundBidding, {ReduxWebcastCurrentRoundBiddingType} from "./webcast/reducerWebcastCurrentRoundBidding";
import reducerWebcastParticipants, {ReduxWebcastParticipantsType} from "./webcast/reducerWebcastParticipants";
import reducerWebcastChat, {ReduxWebcastChatType} from "./webcast/reducerWebcastChat";
import {combineReducers} from "@reduxjs/toolkit";


export type ReduxActiveWebcastSubStateType = {

    wbsNetworkStatus: ReduxWebcastWbsNetworkStatusType,

    webcastStatus?: ReduxWebcastStatusType,

    webcastAuctionData: ReduxWebcastAuctionDataType,

    webcastCurrentRound?: ReduxWebcastCurrentRoundType,

    webcastCurrentRoundBidding?: ReduxWebcastCurrentRoundBiddingType,


    webcastChat?: ReduxWebcastChatType,


    webcastParticipants?: ReduxWebcastParticipantsType,
};



/**
 * combined as `activeWebcast`
 */
const activeWebcastRootState = {

    wbsNetworkStatus: reducerWbsNetworkStatus,

    webcastStatus: reducerWebcastStatus,

    webcastAuctionData: reducerWebcastAuctionData,
    webcastCurrentRound: reducerWebcastCurrentRound,
    webcastCurrentRoundBidding: reducerWebcastCurrentRoundBidding,

    webcastChat: reducerWebcastChat,

    // clerk only
    webcastParticipants: reducerWebcastParticipants,
};


export default combineReducers(activeWebcastRootState);
