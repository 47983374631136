import React from "react";
import {useDispatch} from "react-redux";
import ReactModal from "react-modal";
import currency from "currency.js";
import {getMoneyHelperForCurrencyCode} from "@nextlot/core/services/MoneyHelper";
import {Auction_ModelTypeDef, RunningCatalogLot_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {Dispatch} from "@nextlot/core/rematch/store";
import {AppController, TaggedLogger} from "@nextlot/core";
import {AxiosError, AxiosResponse} from "axios";
import {LotBidOnIncrementWithDropdownSuggestionsInputField} from "./LotBidOnIncrementWithDropdownSuggestionsInputField";

const _logger = TaggedLogger.get('LotPlaceOnlineBidWithConfirmationModal');


type TimedLotPlaceBidCmp_PropsTypeDef = {
    auction: Auction_ModelTypeDef,
    lot: RunningCatalogLot_ModelTypeDef,
}

export default function LotPlaceOnlineBidWithConfirmationModal({auction, lot}:TimedLotPlaceBidCmp_PropsTypeDef) {
    // _logger.debug('.render: props: ', props);
    const dispatchStore = useDispatch<Dispatch>();

    const auctionCurrencyCode = auction.currencyCode;
    const moneyHelper = getMoneyHelperForCurrencyCode(auctionCurrencyCode);

    const auctionBidIncrementsTable = React.useMemo(() => {
        // memoize the bidIncrementsTable based on auction.id
        return auction.bidIncrementsTable;
    }, [auction.id]);

    const [submitBidMoney, setSubmitBidMoney] = React.useState<currency>(moneyHelper.fromCents(lot.askingBidAmountCents));
    const [bidPlaceError, setBidPlaceError] = React.useState(null);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [isBidButtonsDisabled, setBidButtonsDisabled] = React.useState(false);

    const askingBidMoney:currency = moneyHelper.fromCents(lot.askingBidAmountCents);
    const askingBidAmount:number = askingBidMoney.value;

    const myMaxBidMoney:currency = lot.myMaxBidAmountCents ? moneyHelper.fromCents(lot.myMaxBidAmountCents) : null;
    const myMaxBidAmount:number = myMaxBidMoney ? myMaxBidMoney.value : null;
    const myMaxBidFormatted:string = myMaxBidMoney ? myMaxBidMoney.format() : null;


    React.useEffect(() => {
        setSubmitBidMoney(moneyHelper.fromCents(lot.askingBidAmountCents));
    }, [lot.askingBidAmountCents]);




    const onSubmitBidAmount = (amount:number) => {
        // _logger.debug('.onSubmitBidAmount: amount: ', amount);
        setSubmitBidMoney(moneyHelper.fromAmount(amount));
        setModalIsOpen(true);
    }


    const handleCloseModal = () => {
        setModalIsOpen(false);
    }

    const handleClickPlaceBidInModal = (raiseBid:boolean) => async (evt) => {
        setBidButtonsDisabled(true);

        try {
            await AppController.instance.remoteDataHandler.submitPlaceBid(lot.auctionId, lot.id, submitBidMoney.intValue, raiseBid);
            setBidPlaceError(null);
        }
        catch (ex) {
            _logger.warn(`.handleClickPlaceBidInModal(raiseBid:${raiseBid})>AppController.instance.remoteDataHandler.submitPlaceBid FAILED: `, ex, (ex as AxiosError).response);
            const response:AxiosResponse = (ex as AxiosError).response;

            if (response) {
                // check if the error is caused by Bidder eligibility
                if (response.status === 403 && response.data?.code === 'bid_authorization_error') {
                    setBidPlaceError([false, `Error: ${response.data.error}`]);
                }
                else if (response.status === 409 && response.data?.code === 'bid_place_error') {
                    setBidPlaceError([true, `Your bid is under the new asking bid amount. Please bid again.`]);
                }
                else {
                    setBidPlaceError([true, `Error: ${response.data.error}`]);
                }
            }
            else {
                setBidPlaceError([false, `Error: ${ex}`]);
            }
        }

        await dispatchStore.currentAuctionLots.asyncFetchAndUpdateRunningLotWithSideEffects({ auctionId: lot.auctionId, lotId: lot.id });

        setBidButtonsDisabled(false);
        handleCloseModal();
    }




    if (bidPlaceError && !bidPlaceError[0]) {
        return <div className='alert alert-danger'>{bidPlaceError[1]}</div>
    }


    return (
        <div>

            <div>
                <LotBidOnIncrementWithDropdownSuggestionsInputField
                    bidIncrementsTable={auctionBidIncrementsTable}
                    currencyCode={auctionCurrencyCode}
                    minBidAmount={lot.myBidIsLeadingBid ? myMaxBidAmount : askingBidAmount}
                    mustBeGreaterThanMinBidAmount={lot.myBidIsLeadingBid}
                    onSubmitBidAmount={onSubmitBidAmount}
                />
                {
                    (auction.auctionType.hasTimedBidding && lot.myBidIsLeadingBid)
                        ? <div>Your Max Bid: {myMaxBidFormatted}</div>
                        : <div>&nbsp; {/* intentional empty vertical space */}</div>
                }
            </div>


            {
                (bidPlaceError && bidPlaceError[0])
                &&
                <div className='alert alert-danger'>{bidPlaceError[1]}</div>
            }

            {
                <ReactModal isOpen={modalIsOpen}
                            onRequestClose={handleCloseModal}
                            shouldCloseOnOverlayClick={false}
                            shouldCloseOnEsc={false}
                            contentLabel={"Confirm place bid"}
                    // testId={`modal_confirm_place_bid_lot${lot.id}`}
                            className={'rmodal--place-bid modal'}
                            overlayClassName={'modal-backdrop'}
                            bodyOpenClassName={'modal-open'}
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(255,255,255, 0.75)',
                                },
                                content: {
                                    display: 'block',
                                }
                            }}
                >
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                        {
                            auction.auctionType.hasTimedBidding
                                ? <TimedBidConfirmationModalContent
                                    auction={auction}
                                    lot={lot}
                                    submitBidMoney={submitBidMoney}
                                    isBidButtonsDisabled={isBidButtonsDisabled}
                                    handleClickPlaceBidInModal={handleClickPlaceBidInModal}
                                    handleCloseModal={handleCloseModal}
                                />
                                : <SecretBidConfirmationModalContent
                                    auction={auction}
                                    lot={lot}
                                    submitBidMoney={submitBidMoney}
                                    isBidButtonsDisabled={isBidButtonsDisabled}
                                    handleClickPlaceBidInModal={handleClickPlaceBidInModal}
                                    handleCloseModal={handleCloseModal}
                                />
                        }
                    </div>
                </ReactModal>
            }

        </div>
    )
}

function TimedBidConfirmationModalContent({ auction, lot, submitBidMoney, isBidButtonsDisabled, handleClickPlaceBidInModal, handleCloseModal }) {
    const moneyHelper = getMoneyHelperForCurrencyCode(auction.currencyCode);

    const auctionTimedJumpBidsAllowed = auction.auctionType.hasTimedBidding && auction.timedJumpBidsAllowed;

    const askingBidFormatted:string = moneyHelper.formatFromCents(lot.askingBidAmountCents);

    const submitBidFormatted = submitBidMoney.format();
    const submitTotalBidFormatted = submitBidMoney.multiply(lot.quantity).format();

    const isSubmitBidGreaterThanAskingBid:boolean = React.useMemo(() => {
        return (submitBidMoney.intValue > lot.askingBidAmountCents) // new bid input amount is > current asking
            && (!lot.myBidIsLeadingBid) // I'm not the leading bid
    }, [submitBidMoney, lot.askingBidAmountCents, lot.myBidIsLeadingBid]);


    return (
        <div className="modal-content shadow">
            <div className="modal-header">
                <h4 className="modal-title">You are about to place a bid on Lot #{lot.number}</h4>
            </div>

            <div className="modal-body">
                {
                    lot.myBidIsLeadingBid
                        ? <p>
                            Your current bid: <span className='font-monospace'>{moneyHelper.formatFromCents(lot.leadingBidAmountCents)}</span>
                            {
                                (lot.quantity > 1)
                                &&
                                <>
                                    <br />
                                    Your current total bid: <span className='font-monospace'>{moneyHelper.formatFromCents(lot.leadingBidAmountCents * lot.quantity)} = {moneyHelper.formatFromCents(lot.leadingBidAmountCents)} &times; {lot.quantity}</span>
                                </>
                            }

                            <br/>
                            Your current max bid: <span className='font-monospace'>{moneyHelper.formatFromCents(lot.myMaxBidAmountCents)}</span>
                            <br/>
                            <strong>Your new max bid: <span className='font-monospace'>{submitBidFormatted}</span></strong>
                            {
                                (lot.quantity > 1)
                                &&
                                <>
                                    <br />
                                    <strong>Your new total max bid: <span className='font-monospace'>{submitTotalBidFormatted} = {submitBidFormatted} &times; {lot.quantity}</span></strong>
                                </>
                            }
                        </p>

                        : <p>
                            Required min bid: <span className='font-monospace'>{askingBidFormatted}</span>
                            <br/>
                            <strong>Your current bid: <span className='font-monospace'>{submitBidFormatted}</span></strong>
                            {
                                (lot.quantity > 1)
                                &&
                                <>
                                    <br />
                                    <strong>Your current total bid: <span className='font-monospace'>{submitTotalBidFormatted} = {submitBidFormatted} &times; {lot.quantity}</span></strong>
                                </>
                            }
                        </p>
                }


                {
                    lot.myBidIsLeadingBid
                        ? <p>
                            By clicking <strong>"Update Max Bid"</strong>, you agree to raise your max bid to {submitBidFormatted}
                            {
                                (lot.quantity > 1)
                                &&
                                <>&nbsp;&times;&nbsp;{lot.quantity}, total of {submitTotalBidFormatted}</>
                            }
                            &nbsp; + the Buyer's Premium of {auction.internetBuyerPremiumDisplayText}.
                        </p>
                        : (isSubmitBidGreaterThanAskingBid
                                ? <>
                                    <p>
                                        By clicking <strong>"Place Bid"</strong>, you agree to bid {askingBidFormatted}
                                        {
                                            (lot.quantity > 1)
                                            &&
                                            <>&nbsp;&times;&nbsp;{lot.quantity}, total of {moneyHelper.formatFromCents(lot.askingBidAmountCents * lot.quantity)}</>
                                        }
                                        &nbsp; + the Buyer's Premium of {auction.internetBuyerPremiumDisplayText},
                                        and the system will bid on your behalf up to your max amount of {submitBidFormatted}
                                        {
                                            (lot.quantity > 1)
                                            &&
                                            <>&nbsp;&times;&nbsp;{lot.quantity}, total of {submitTotalBidFormatted}</>
                                        }
                                        &nbsp;+ the Buyer's Premium of {auction.internetBuyerPremiumDisplayText}.
                                    </p>
                                    {
                                        auctionTimedJumpBidsAllowed
                                        &&
                                        <p>
                                            By clicking <strong>"Raise bid"</strong> to {submitBidFormatted},
                                            you will instantly raise your bid to your max bid of {submitBidFormatted}
                                            {
                                                (lot.quantity > 1)
                                                &&
                                                <>&nbsp;&times;&nbsp;{lot.quantity}, total of {submitTotalBidFormatted}</>
                                            }
                                            &nbsp; + the Buyer's Premium of {auction.internetBuyerPremiumDisplayText}.
                                        </p>
                                    }
                                </>
                                : <p>
                                    By clicking <strong>"Place Bid"</strong>, you agree to bid {submitBidFormatted}
                                    {
                                        (lot.quantity > 1)
                                        &&
                                        <>&nbsp;&times;&nbsp;{lot.quantity}, total of {submitTotalBidFormatted}</>
                                    }
                                    &nbsp; + the Buyer's Premium of {auction.internetBuyerPremiumDisplayText}.
                                </p>
                        )
                }


                <p>
                    <strong>By doing so, you are entering a legally binding contract.</strong>
                </p>


            </div>

            {
                isBidButtonsDisabled
                    ? <div className="modal-footer">
                        <button type='button' disabled={true} className={'btn btn-link text-decoration-none'}>Submitting ...</button>
                    </div>

                    : <div className="modal-footer">
                        <button typeof='button' className='btn btn-outline-secondary'
                                onClick={handleCloseModal}
                                disabled={isBidButtonsDisabled}
                        >Cancel
                        </button>

                        {
                            // "Raise" bid button is available if:
                            (auctionTimedJumpBidsAllowed && isSubmitBidGreaterThanAskingBid)
                            &&
                            <button type='button' className='ms-3 btn btn-primary'
                                    onClick={handleClickPlaceBidInModal(true)}
                                    disabled={isBidButtonsDisabled}
                            >Raise Bid: {submitBidFormatted}</button>
                        }

                        <button type='button' className='ms-3 btn btn-primary'
                                onClick={handleClickPlaceBidInModal(false)}
                                disabled={isBidButtonsDisabled}
                        >
                            {lot.myBidIsLeadingBid
                                ? 'Update Max Bid: '
                                : 'Place Bid: '}

                            {isSubmitBidGreaterThanAskingBid
                                ? `${askingBidFormatted} (max ${submitBidFormatted})`
                                : submitBidFormatted}
                        </button>
                    </div>
            }
        </div>
    )
}



function SecretBidConfirmationModalContent({ auction, lot, submitBidMoney, isBidButtonsDisabled, handleClickPlaceBidInModal, handleCloseModal }) {
    const moneyHelper = getMoneyHelperForCurrencyCode(auction.currencyCode);

    const submitBidFormatted = submitBidMoney.format();
    const submitTotalBidFormatted = submitBidMoney.multiply(lot.quantity).format();

    const myExistingBidFormatted:string = lot.myMaxBidAmountCents > 0 ? moneyHelper.formatFromCents(lot.myMaxBidAmountCents) : null;

    return (
        <div className="modal-content shadow">
            <div className="modal-header">
                <h4 className="modal-title">You are about to place a bid on Lot #{lot.number}</h4>
            </div>

            <div className="modal-body">
                <p>
                    {
                        lot.myBidIsLeadingBid
                        &&
                        <>
                            Your current bid: <span className='font-monospace'>{myExistingBidFormatted}</span>
                            {
                                (lot.quantity > 1)
                                &&
                                <>
                                    <br />
                                    Your current total bid: <span className='font-monospace'>{moneyHelper.formatFromCents(lot.myMaxBidAmountCents * lot.quantity)} = {moneyHelper.formatFromCents(lot.leadingBidAmountCents)} &times; {lot.quantity}</span>
                                </>
                            }

                            <br/>
                        </>
                    }

                    <strong>Your new bid: <span className='font-monospace'>{submitBidFormatted}</span></strong>
                    {
                        (lot.quantity > 1)
                        &&
                        <>
                            <br />
                            <strong>Your new total bid: <span className='font-monospace'>{submitTotalBidFormatted} = {submitBidFormatted} &times; {lot.quantity}</span></strong>
                        </>
                    }
                </p>


                {
                    lot.myBidIsLeadingBid
                        ? <p>
                            By clicking <strong>"Update Bid"</strong>, you agree to update your bid to {submitBidFormatted}
                            {
                                (lot.quantity > 1)
                                &&
                                <>&nbsp;&times;&nbsp;{lot.quantity}, total of {submitTotalBidFormatted}</>
                            }
                            &nbsp; + the Buyer's Premium of {auction.internetBuyerPremiumDisplayText}.
                        </p>
                        :  <p>
                            By clicking <strong>"Place Bid"</strong>, you agree to bid {submitBidFormatted}
                            {
                                (lot.quantity > 1)
                                &&
                                <>&nbsp;&times;&nbsp;{lot.quantity}, total of {submitTotalBidFormatted}</>
                            }
                            &nbsp; + the Buyer's Premium of {auction.internetBuyerPremiumDisplayText}.
                        </p>
                }


                <p>
                    <strong>By doing so, you are entering a legally binding contract.</strong>
                </p>


            </div>

            {
                isBidButtonsDisabled
                    ? <div className="modal-footer">
                        <button type='button' disabled={true} className={'btn btn-link text-decoration-none'}>Submitting ...</button>
                    </div>

                    : <div className="modal-footer">
                        <button typeof='button' className='btn btn-outline-secondary'
                                onClick={handleCloseModal}
                                disabled={isBidButtonsDisabled}
                        >Cancel
                        </button>

                        <button type='button' className='ms-3 btn btn-primary'
                                onClick={handleClickPlaceBidInModal(false)}
                                disabled={isBidButtonsDisabled}
                        >
                            {lot.myBidIsLeadingBid
                                ? 'Update Bid: '
                                : 'Place Bid: '}

                            {submitBidFormatted}
                        </button>
                    </div>
            }
        </div>
    )
}

