import React from 'react';
import ContentLoader from 'react-content-loader';

/*

https://skeletonreact.com/

*/


function AuctionSkeleton() {
    return (
        <ContentLoader
            viewBox="0 0 600 120"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="160" y="1" rx="0" ry="0" width="400" height="30" />
            <rect x="160" y="45" rx="0" ry="0" width="400" height="10" />
            <rect x="3" y="1" rx="0" ry="0" width="150" height="110" />
            <rect x="350" y="80" rx="0" ry="0" width="210" height="28" />
        </ContentLoader>
    )
}


function AuctionsListSkeleton({ count = 3 }) {

    const arr = Array.apply(null, {length: (count || 3)});

    return (
        <div>
            {
                arr.map((_, idx) => (
                    <div key={idx} className={'py-3'}>
                        <AuctionSkeleton />
                    </div>
                ))
            }
        </div>
    )
}



function ParagraphSkeleton(props = {}) {
    return (
        <ContentLoader
            speed={2}
            width={340}
            height={84}
            viewBox="0 0 340 84"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
            <rect x="76" y="0" rx="3" ry="3" width="256" height="12" />
            <rect x="120" y="52" rx="3" ry="3" width="53" height="11" />
            <rect x="188" y="53" rx="3" ry="3" width="146" height="11" />
            <rect x="3" y="53" rx="3" ry="3" width="100" height="11" />
            <rect x="3" y="27" rx="3" ry="3" width="140" height="11" />
            <rect x="160" y="27" rx="3" ry="3" width="173" height="11" />
        </ContentLoader>
    )
}


function LotCardSkeleton(props = {}) {
    return (
        <ContentLoader
            speed={2}
            width={280}
            height={400}
            viewBox="0 0 280 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="2" y="2" rx="0" ry="0" width="50" height="20" /> <rect x="228" y="2" rx="0" ry="0" width="50" height="20" />
            <rect x="2" y="32" rx="0" ry="0" width="278" height="20" />
            <rect x="2" y="62" rx="2" ry="2" width="278" height="188" />
            <rect x="2" y="260" rx="0" ry="0" width="292" height="20" />
            <rect x="2" y="290" rx="0" ry="0" width="239" height="20" />
            <rect x="2" y="320" rx="0" ry="0" width="274" height="20" />
        </ContentLoader>
    )
}

function LotHorizontalSkeleton(props = {}) {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height={200}
            viewBox="0 0 400 100"
            preserveAspectRatio={'none'}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="2" y="2" rx="0" ry="0" width="90" height="90" />
            <rect x="100" y="2" rx="0" ry="0" width="50" height="20" />
            <rect x="100" y="32" rx="0" ry="0" width="278" height="20" />
            <rect x="100" y="62" rx="2" ry="2" width="278" height="20" />
        </ContentLoader>
    )
}


function LotsHorizontalListSkeleton({ count = 3 }) {
    const arr = Array.apply(null, {length: (count || 3)});

    return (
        <div>
            {
                arr.map((_, idx) => (
                    <div key={idx} className={'py-3'}>
                        <LotHorizontalSkeleton />
                    </div>
                ))
            }
        </div>
    )

}

export default {
    AuctionSkeleton,
    AuctionsListSkeleton,
    ParagraphSkeleton,
    LotCardSkeleton,
    LotsHorizontalListSkeleton,
}
