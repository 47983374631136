import {ACTION_TYPES, CurrentRoundCloseActionPayloadType, CurrentRoundStopActionPayloadType} from "../actions";
import {
    Bid,
    PMBiddingUpdated,
    PMChoiceLotsSelected,
    PMAuctionDataSnapshot,
    OnlineBid,
    ReduxAction,
    Round
} from "../../data/data_types_definitions";
import {BidReviseTypeEnum, RoundStopTypeEnum, RoundTypeEnum, decorateBid} from "../../data/WebcastDataDomain";
import {WebcastAuctionConnectData_TypeDef} from "../../services/AuctionsManager";


export type ReduxWebcastCurrentRoundBiddingType = {
    myUserId?: number,
    myUserRole?: string,

    roundId: string,

    firstOnlineBid?: OnlineBid,
    secondOnlineBid?: OnlineBid,

    askingBidValue?: number,
    bidIncrement?: number,
    bidsHistory?: Array<Bid>,

    highestBid?: Bid,

    stopType?: number,

    choiceLotsSelection?: Array<[number, number]>, // Array<[lotId, participantId]>
    choiceLotsSelectionMap?: Map<number, number>, // Map<lotId, participantId>
    choiceSelectionSubmitted?: boolean,
};



const initialResetState:ReduxWebcastCurrentRoundBiddingType = {
    // ! do not include fields that *should* be kept during new reducer runs (fields like myUserId, myUserRole)
    roundId:  null,
    firstOnlineBid: null,
    secondOnlineBid: null,
    askingBidValue: null,
    bidIncrement: null,
    highestBid:  null,
    bidsHistory: null,
    choiceLotsSelection: null,
    choiceLotsSelectionMap: new Map(),
    choiceSelectionSubmitted: false,
};

/**
 * combined as `webcastCurrentRoundBidding`
 *
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMAuctionDataSnapshot> {
 *      currentRound: <Round> {
 *          roundId: string // uuid
 *          roundType:
 *          lotsIds:
 *          bidsHistory: Array<Bid> [
 *              {
 *                  value: integer<cents>
 *                  bidderNumber: string
 *                  bidderLabel: string
 *                  overridden: boolean
 *                  participantId: integer // CLERK only
 *              },
 *              ....
 *          ]
 *     }
 *
 *     askingBidValue: integer<cents>
 *
 *     highestBid: <Bid> {
 *         value: integer<cents>
 *         bidderNumber: string
 *         bidderLabel: string
 *         participantId: integer // CLERK only
 *     }
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_CREATE
 * payload: <Round> {
 *     roundId: string // uuid
 *     roundType:
 *     lotsIds:
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_BIDDING_UPDATE
 * payload: {
 *      roundId: string // uuid
 *
 *      askingBidValue?: integer<cents>
 *      bidIncrement?: number,
 *
 *      highestBid?: {
 *          value: number // cents
 *          bidderNumber: string
 *          bidderLabel: string
 *          participantId: number // CLERK only
 *      }
 * }
 *
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_STOP
 * payload: CurrentRoundStopActionPayloadType {
 *     roundId: string // uuid
 *     roundType: number
 *     stopType: integer // see RoundStopTypeEnum
 *     isTransitional: boolean
 * }
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_CLOSE
 * payload: CurrentRoundCloseActionPayloadType {
 *     roundId: string // uuid
 *
 *     stopType: integer // see RoundStopTypeEnum
 *
 *     nextRound: ?<Round> {
 *          roundId:
 *          roundType:
 *          lotsIds:
 *          firstOnlineBid:
 *          secondOnlineBid:
 *     }
 *
 *     choiceLotsAssigned: Array
 * }
 *
 *
 * ===
 * action: WEBCAST_CHOICE_LOTS_SELECTION_UPDATE
 * payload: <PMChoiceLotsSelected> {
 *     roundId: string
 *     choiceLotsIds: Array<number>
 *     choiceUsersIds: Array<number>
 * }
 *
 *
 * @param state
 * @param action
 * @returns ReduxWebcastCurrentRoundBiddingType {{
 *      roundId: string
 *
 *      firstOnlineBid?: OnlineBid
 *      secondOnlineBid?: OnlineBid
 *
 *      askingBidValue: number
 *      bidIncrement: number
 *      bidsHistory: Array<Bid>
 *
 *      highestBid: <Bid> // { value, bidderNumber, bidderLabel, participantId }
 *
 *      stopType?: number
 *
 *      choiceLotsSelection?: Array<[number, number]> // Array<[lotId, participantId]>
 *      choiceLotsSelectionMap?: Map<number, number> // Map<lotId, participantId>
 *      choiceSelectionSubmitted?: boolean
 * }}
 */
export default function reducerWebcastCurrentRoundBidding(state:ReduxWebcastCurrentRoundBiddingType = initialResetState, action:ReduxAction):ReduxWebcastCurrentRoundBiddingType {
    if (action.error) {
        return state;
    }


    switch(action.type) {

        case ACTION_TYPES.WEBCAST_AUCTION_INIT: {
            const saleConnectData:WebcastAuctionConnectData_TypeDef = action.payload;
            return {
                ... initialResetState,
                myUserId: saleConnectData.participantId,
                myUserRole: saleConnectData.participantRole,
            };
        }


        case ACTION_TYPES.WEBCAST_AUCTION_DATA_SNAPSHOT_INIT:
            return handleAction_SALE_DATA_SNAPSHOT_INIT(state, action.payload);


        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_CREATE: {
            return {
                ... state,
                ... initialResetState,
                ... computeHighestOnlineBidsSubState(action.payload),
                roundId: action.payload.roundId,
            }
        }


        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_BIDDING_UPDATE:
            return handleAction_CURRENT_ROUND_BIDDING_UPDATE(state, action.payload);



        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_STOP: {
            const payload:CurrentRoundStopActionPayloadType = action.payload;
            if (payload.isTransitional) {

                if (payload.choiceLotsIds && payload.choiceLotsIds.length) {
                    // a CHOICE|CHOICE_REOPEN is closing, we need to have selection to show it in the <RoundStopInfo> screens
                    const choiceLotsSelection:Array<[number, number]> = payload.choiceLotsIds.map((lotId, idx) => [lotId, payload.choiceUsersIds[idx]]);
                    return {
                        ... state,
                        stopType: payload.stopType,
                        choiceLotsSelection: choiceLotsSelection,
                        choiceLotsSelectionMap: new Map(choiceLotsSelection),
                        choiceSelectionSubmitted: false,
                    }
                }

                // non-CHOICE is stopping
                return {
                    ... state,
                    stopType: payload.stopType,
                }
            }
            else {
                // not a transitional state
                if (payload.nextRound) {
                    // non-CHOICE round is stopping, do nothing
                    return state;
                }
                else {
                    // a Choice round is stopping
                    return {
                        ... state,
                        stopType: payload.stopType,
                        choiceLotsSelection: [],
                        choiceLotsSelectionMap: new Map(),
                        choiceSelectionSubmitted: false,
                    };
                }
            }
        }



        case ACTION_TYPES.WEBCAST_CURRENT_ROUND_CLOSE: {
            const payload:CurrentRoundCloseActionPayloadType = action.payload;

            if (payload.stopType === RoundStopTypeEnum.CANCEL) {
                return {
                    ... state,
                    ... initialResetState,
                    roundId: payload.nextRound.roundId,

                    ... computeHighestOnlineBidsSubState(payload.nextRound),
                }
            }
            else {
                const payloadNextRound:Round = payload.nextRound;

                if (payloadNextRound.roundType === RoundTypeEnum.GROUP_CHOICE_REOPEN) {
                    // CHOICE_REOPEN for nextRound
                    const bidsHistory = payloadNextRound.bidsHistory.map(bid => decorateBid(bid));
                    return {
                        ... state,
                        ... initialResetState,
                        roundId: payloadNextRound.roundId,
                        stopType: payloadNextRound.stopType, // this type of round is already stopped
                        bidsHistory: bidsHistory,
                        highestBid: bidsHistory[bidsHistory.length - 1], // there's actually only one bid in the history
                        ... computeHighestOnlineBidsSubState(payloadNextRound),
                    }
                }
                else {
                    // not a CHOICE_REOPEN nextRound
                    return {
                        ... state,
                        ... initialResetState,
                        roundId: payloadNextRound.roundId,
                        ... computeHighestOnlineBidsSubState(payloadNextRound),
                    }
                }
            }
        }



        case ACTION_TYPES.WEBCAST_CHOICE_LOTS_SELECTION_UPDATE: {
            const payload:PMChoiceLotsSelected = action.payload;

            if ( ! (payload && payload.choiceLotsIds && payload.choiceLotsIds.length)) {
                // do nothing if there's no valid payload -- should NOT get here!
                return state;
            }

            // build a helper Map with existing selection, to preserve un-changed entries
            const existingSelection = [ ...(state.choiceLotsSelection || []) ];
            const existingSelectionMap:Map<number, number> = new Map(existingSelection);
            payload.choiceLotsIds.forEach((payloadLotId, idx) => {
                const payloadUserId = payload.choiceUsersIds[idx];
                existingSelectionMap.set(payloadLotId, payloadUserId);
            });

            const updatedChoiceLotsSelection = [];
            let choiceSelectionSubmitted = false;
            existingSelectionMap.forEach((userId, lotId) => {
                updatedChoiceLotsSelection.push([lotId, userId]);
                if (userId === state.myUserId) {
                    // if our participantId is in the selection, then we've submitted our selection
                    choiceSelectionSubmitted = true;
                }
            });


            return {
                ... state,
                choiceLotsSelection: updatedChoiceLotsSelection,
                choiceLotsSelectionMap: new Map(updatedChoiceLotsSelection),
                choiceSelectionSubmitted: choiceSelectionSubmitted,
            }

        }

    }// switch

    return state;
};







function transformApiBidsHistory(bids:Array<Bid>):Array<Bid> {
    if (bids?.length) {
        return bids.map((bid) => decorateBid<Bid>(bid));
    }
    else {
        return null
    }
}


function computeHighestOnlineBidsSubState(round:Round):Pick<ReduxWebcastCurrentRoundBiddingType, 'firstOnlineBid'|'secondOnlineBid'> {
    return {
        firstOnlineBid: decorateBid<OnlineBid>(round.firstOnlineBid),
        secondOnlineBid: decorateBid<OnlineBid>(round.secondOnlineBid),
    }
}







function handleAction_SALE_DATA_SNAPSHOT_INIT(state:ReduxWebcastCurrentRoundBiddingType, payload:PMAuctionDataSnapshot):ReduxWebcastCurrentRoundBiddingType {
    const payloadCurrentRound:Round = payload.currentRound;

    if (payloadCurrentRound) {
        const bidsHistory = transformApiBidsHistory(payloadCurrentRound.bidsHistory);
        const highestBid = bidsHistory && bidsHistory.length ? bidsHistory[bidsHistory.length - 1] : null;
        const choiceLotsSelection = [];

        let choiceSubmitted = false;
        if (payload.choiceLotsIds && payload.choiceLotsIds.length) {
            payload.choiceLotsIds.forEach((payloadLotId, idx) => {
                const payloadUserId = payload.choiceUsersIds[idx];
                choiceLotsSelection.push([payloadLotId, payloadUserId]);
                if (payloadUserId === state.myUserId) {
                    choiceSubmitted = true;
                }
            });
        }


        return {
            ... state,
            ... computeHighestOnlineBidsSubState(payloadCurrentRound),
            roundId: payloadCurrentRound.roundId,
            askingBidValue: payload.askingBidValue,
            bidIncrement: payload.bidIncrement,
            bidsHistory: bidsHistory,
            highestBid: highestBid,
            choiceLotsSelection: choiceLotsSelection,
            choiceLotsSelectionMap: new Map(choiceLotsSelection),
            choiceSelectionSubmitted: choiceSubmitted,
        }
    }

    return state;
}







function handleAction_CURRENT_ROUND_BIDDING_UPDATE(state:ReduxWebcastCurrentRoundBiddingType, payload:PMBiddingUpdated):ReduxWebcastCurrentRoundBiddingType {


    // first priority, we check if this is a bidRevise
    if (payload.bidReviseType && payload.bidReviseType !== 0) {
        // bid reverse or override
        const existingBiddingHistory = state.bidsHistory || [];
        if (existingBiddingHistory.length === 0) {
            alert('FATAL ERROR [rcb268]: BidRevise triggered with no bids history. Please reload page and report this issue!');
            throw 'FATAL ERROR: BidRevise triggered with no bids history. Please reload page and report this issue!';
        }

        if (payload.bidReviseType === BidReviseTypeEnum.OVERRIDE_BY_FLOOR) {
            const lastBid = existingBiddingHistory[existingBiddingHistory.length - 1];

            const overriddenBid:Bid = {
                ... lastBid,
                overridden: true
            };

            const newFloorBid:Bid = decorateBid(payload.bidsPlaced[0]);

            // new history must replace the last bid and and re-add it as overridden, then add the floor bid last
            const newBiddingHistory = [ ... existingBiddingHistory.slice(0, existingBiddingHistory.length - 1), overriddenBid, newFloorBid ];

            return {
                ... state,

                askingBidValue: payload.askingBidValue,
                bidIncrement: payload.bidIncrement,

                highestBid: newFloorBid,
                bidsHistory: newBiddingHistory,
            }
        }
        else { // reverse

            // the newHighestBid that should remain in the history is the only bid in the payload.BidsPlaced
            const payloadNewHighestBid:Bid = payload.bidsPlaced[0];

            let newBidsHistory:Array<Bid>;

            if (payloadNewHighestBid) {
                // remove all bids to match new highest remaining bid
                newBidsHistory = existingBiddingHistory.filter((bid:Bid) => {
                    return bid.timestamp <= payloadNewHighestBid.timestamp;
                });
            }
            else {
                // no newHighestBid => no bids history left
                newBidsHistory = null;
            }

            let newHighestBid:Bid = (newBidsHistory && newBidsHistory.length) ? newBidsHistory[newBidsHistory.length - 1] : null;
            if (newHighestBid && newHighestBid.overridden && newBidsHistory) {
                // remove override from the new highestBid
                newHighestBid = {
                    ... newHighestBid,
                    overridden: false,
                };

                // remove the old "newBiddingHistory" and add the new "newBiddingHistory" that doesn't have `overridden`
                newBidsHistory = [ ... newBidsHistory.slice(0, newBidsHistory.length -1 ), newHighestBid ];
            }


            return {
                ... state,

                askingBidValue: payload.askingBidValue,
                bidIncrement: payload.bidIncrement,

                highestBid: newHighestBid,

                bidsHistory: newBidsHistory,
            }
        }
    }
    else if (payload.bidsPlaced && payload.bidsPlaced.length) {


        let highestBid:Bid = null;
        const newExtraBidsHistory:Array<Bid> = [];

        [... payload.bidsPlaced].reverse().forEach((placedBid:Bid) => {
            highestBid = decorateBid(placedBid);
            newExtraBidsHistory.push(highestBid);
        });


        // a bid has been placed
        const existingBiddingHistory = state.bidsHistory || [];

        return {
            ... state,

            roundId: payload.roundId,

            askingBidValue: payload.askingBidValue,
            bidIncrement: payload.bidIncrement,

            highestBid: highestBid,

            bidsHistory: [... existingBiddingHistory, ... newExtraBidsHistory],
        }
    }
    else {
        // the askingBid was updated, and maybe there also a new increment
        return {
            ... state,
            ... initialResetState,

            roundId: payload.roundId,

            askingBidValue: payload.askingBidValue,
            bidIncrement: payload.bidIncrement,

            highestBid: state.highestBid,

            bidsHistory: state.bidsHistory,

            // save some state info from before
            firstOnlineBid: state.firstOnlineBid,
            secondOnlineBid: state.secondOnlineBid,
        }
    }
}
