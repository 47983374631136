import type {DateTime} from 'luxon';
import {Auction_ModelTypeDef} from "../type_defs/model";


export function buildAuctionTimelineLabels(auction:Auction_ModelTypeDef, dateTimeFormatFunc:(dateTime:DateTime)=>string):[string, string?] {
    // Completed auctions
    if (auction.timelineState.isCompleted) {
        return [`Closed ${dateTimeFormatFunc(auction.completesAt)}`];
    }

    // Upcoming auctions
    else if (auction.timelineState.isUpcoming) {
        if (auction.auctionType.isTimed) {
            return [`Starts on ${dateTimeFormatFunc(auction.startsAt)}`,
                `Lots start closing on ${dateTimeFormatFunc(auction.closingStartsAt)}`];

        }
        else if (auction.auctionType.isSealed) {
            return [`Starts on ${dateTimeFormatFunc(auction.startsAt)}`,
                `Closing on ${dateTimeFormatFunc(auction.closingStartsAt)}`];

        }
        else if (auction.auctionType.isWebcast) {
            return [`Webcast starts on ${dateTimeFormatFunc(auction.startsAt)}`];

        }
        else if (auction.auctionType.isCombinedAny) {
            return [
                `${auction.auctionType.isCombinedAbsentee ? 'Absentee bidding' : 'Pre-bidding'} opens on ${dateTimeFormatFunc(auction.startsAt)}`,
                `Webcast starts on ${dateTimeFormatFunc(auction.webcastStartsAt)}`
            ];
        }
    }

    // Running auctions && Running extended
    else if (auction.timelineState.isLive) {
        if (auction.auctionType.isTimed) {
            return [`First lot closing on ${dateTimeFormatFunc(auction.closingStartsAt)}`];

        }
        else if (auction.auctionType.isSealed) {
            return [`Closing on ${dateTimeFormatFunc(auction.closingStartsAt)}`];

        }
        else if (auction.auctionType.isWebcast) {
            if (auction.webcastIsPrepared) {
                return ['Live now'];
            }
            else {
                return [`Webcast will start soon (scheduled ${dateTimeFormatFunc(auction.webcastStartsAt)})`];
            }
        }
        else if (auction.auctionType.isCombinedAny) {
            return [
                `${auction.auctionType.isCombinedAbsentee ? 'Absentee bidding' : 'Pre-bidding'} ${auction.currentSegmentType.isOnline ? 'closes' : 'closed'} on ${dateTimeFormatFunc(auction.closingStartsAt)}`,
                auction.currentSegmentType.isWebcast
                    ? (auction.webcastIsPrepared ? 'Webcast is live now' : 'Webcast will start soon')
                    : `Webcast starts on ${dateTimeFormatFunc(auction.webcastStartsAt)}`];

        }
    }

    // Stalled auctions
    else { // isStalled
        if (auction.auctionType.isCombinedAny) {
            return [
                `${auction.auctionType.isCombinedAbsentee ? 'Absentee bidding' : 'Pre-bidding'} closed on ${dateTimeFormatFunc(auction.closingStartsAt)}`,
                `Webcast starts on ${dateTimeFormatFunc(auction.webcastStartsAt)}`
            ];
        }
        else if(auction.auctionType.hasWebcastBidding) {
            return [`Webcast starts on ${dateTimeFormatFunc(auction.webcastStartsAt)}`];
        }
        else {
            return ['Auction paused'];
        }
    }
}
