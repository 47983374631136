import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { generatePathWithParamsFunction_TypeDef } from "src/hooks/useListingUrlParams";

type ListingsPaginationProps_TypeDef = {
  containerClassName?: string,
  generatePathWithParams: generatePathWithParamsFunction_TypeDef,
  pageNumber: number,
  pagesCount: number,
  validSafePageNumber: (reqPageNumber:number) => number,
}

export default function ListingsPagination({containerClassName, generatePathWithParams, validSafePageNumber, pageNumber, pagesCount}:ListingsPaginationProps_TypeDef) {
  const navigate = useNavigate();

  const handleChange = (evt) => {
      const selectedPageNumber = evt.target.value;
      navigate(generatePathWithParams({ pageNumber: selectedPageNumber }));
  };


  return (
      <div className={classNames(containerClassName, 'hstack gap-1 ps-1')}>
          Page

          <Link to={generatePathWithParams({ pageNumber: validSafePageNumber(pageNumber-1) })}
                className="btn btn-sm btn-outline-secondary px-3 px-lg-2"><i className="fa-regular fa-chevron-left" /></Link>

          <select onChange={handleChange} value={pageNumber} className='form-select form-select-sm'>
              {
                  Array.from({length: pagesCount}, (_, i) => i + 1).map((page) => {
                      return <option key={page.toString()}>{page}</option>
                  })
              })
          </select>

          <div style={{ whiteSpace: "nowrap" }}> of {pagesCount}</div>

          <Link to={generatePathWithParams({ pageNumber: validSafePageNumber(pageNumber+1) })}
                className="btn btn-sm btn-outline-secondary px-3 px-lg-2"><i className="fa-regular fa-chevron-right" /></Link>
      </div>
  )
}
