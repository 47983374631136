import * as luxon from "luxon";
import {IPusherAdapter} from "./services/IPusherAdapter";
import {TaggedLogger, TimerEventsEmitter} from "./utilities";
import {CurrentBidder} from "./type_defs/model";
import {AppRemoteDataHandler} from "./services/AppRemoteDataHandler";
import {LotsViewModes_Enum} from "./rematch/models/currentAuctionLotsModel";


const _logger = TaggedLogger.get('AppController');


type AppConfig_TypeDef = {
    site_id: number,
    frontend_api_base_url: string,

    site_timezone_name: string,

    site_initial_catalog_lots_view_mode?: LotsViewModes_Enum,

    bidder_id?: number,
    bidder_email?: string,
    bidder_token?: string,
}

type InitArgs_TypeDef = {
    appConfig: AppConfig_TypeDef,
    pusherAdapter?:IPusherAdapter,
    onAuthenticationRemoved?:()=>void,
}


export default class AppController {
    public static instance:AppController|null = null;

    public static init = async (initArgs:InitArgs_TypeDef):Promise<any> => {
        if (AppController.instance) {
            throw 'AppController.init: already initialized';
        }

        luxon.Settings.defaultZone = initArgs.appConfig.site_timezone_name;
        if (initArgs.appConfig.site_timezone_name.toLowerCase().startsWith('australia')) {
            luxon.Settings.defaultLocale = 'en-AU';
        }

        AppController.instance = new AppController(initArgs);

        await AppController.instance.initServices();

        ((window || global || {}) as any)._dev_AppController_instance = AppController.instance;

        return AppController.instance;
    }



    public readonly appConfig:AppConfig_TypeDef;
    public readonly remoteDataHandler: AppRemoteDataHandler;
    public readonly timerEventsEmitter: TimerEventsEmitter;
    public readonly remoteEventsEmitter: IPusherAdapter;
    private readonly _handleAuthenticationRemoved: () => void;
    private _currentBidder: CurrentBidder|null;



    constructor({ appConfig, pusherAdapter, onAuthenticationRemoved }:InitArgs_TypeDef) {
        if (AppController.instance) {
            throw new Error('AppController.new: already initialized');
        }
        _logger.debug('.constructor: ', appConfig);

        this.appConfig = appConfig;

        this.remoteDataHandler = new AppRemoteDataHandler({
            frontendApiBaseUrl: this.appConfig.frontend_api_base_url,
            siteId: this.appConfig.site_id,
        });

        this.remoteEventsEmitter = pusherAdapter;
        this.timerEventsEmitter = TimerEventsEmitter.init();

        this._handleAuthenticationRemoved = () => {
            this._setCurrentBidder(null);
            this.remoteEventsEmitter?.updateCurrentBidder(null);
            onAuthenticationRemoved && onAuthenticationRemoved();
        }

        this.remoteDataHandler.onAuthenticationStateChanged((isAuthenticated) => {
            if (! isAuthenticated) {
                _logger.warn('Authentication lost! Most likely the Api Bidder Token was invalidated remotely or expired.');
                this._handleAuthenticationRemoved();
            }
        });

        this.remoteDataHandler.onResponseSuccess(({ headers }) => {
            const httpDate:string = headers['date'];
            this.timerEventsEmitter.updateServerUnixTimestamp(new Date(httpDate).getTime() / 1_000);
        });
    }

    private _setCurrentBidder(currentBidder:CurrentBidder|null) {
        this._currentBidder = currentBidder?.id ? currentBidder : null;
    }

    public get currentBidder():null|CurrentBidder {
        // this is an intentional *hack* to use the stored value `this._currentBidder` from AppController
        // it's irrelevant if the value for `AppController.currentBidder` will change, because if it does, then
        // it is handled by the app registered callback in AppController.init for `onAuthenticationRemoved`
        // This will be re-addressed when we need to respond to change of `currentBidder` in the views (like maybe on mobile app?)
        return this._currentBidder;
    }


    async initServices() {
        _logger.debug('.initServices() ...');

        const isValidBidderToken = await this.remoteDataHandler.setAndAsyncRemoteVerifyBidderToken(this.appConfig.bidder_token);
        if (isValidBidderToken) {
            this._setCurrentBidder({
                id: this.appConfig.bidder_id,
                email: this.appConfig.bidder_email,
                apiToken: this.appConfig.bidder_token,
            });
        }

        await this.remoteEventsEmitter?.init(this.currentBidder);

        _logger.info('.initServices() DONE.');
    }




    async destroy() {
        await this.remoteEventsEmitter?.disconnect();
    }

}
