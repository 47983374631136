import React from "react";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {RootState} from "@nextlot/core/rematch/store";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import RoutesPathDefs from "../RoutesPathDefs";
import LotMediaFilesGallery from "../components/LotMediaFilesGallery";
import {
    useCurrentAuctionSingleLot,
    useRemoteUpdatingAuctionLotsChangeListener,
    useRemoteUpdatingLotsInterestChangeListener
} from "@nextlot/core/hooks/CurrentAuctionLotsListHook";
import LotWatchWidgetButton from "../components/LotWatchWidgetButton";
import {BiddingContextViewEnum} from "../components/LotCard";
import LotBiddingAreaCmp from "../components/lot_bidding_area/LotBiddingAreaCmp";


export function LotDetailsFullPage() {
    const {lotId: lotIdStr, auctionId: auctionIdStr} = useParams<'lotId'|'auctionId'>();
    const lotId: number = Number(lotIdStr);
    const auctionId: number = Number(auctionIdStr);

    const auction = useSelector<RootState, Auction_ModelTypeDef>((state: RootState) => state.currentAuction.auction);

    const lot = useCurrentAuctionSingleLot(lotId);
    useRemoteUpdatingAuctionLotsChangeListener(auctionId);
    useRemoteUpdatingLotsInterestChangeListener(auctionId);

    if (!lot) {
        return <div>Loading ...</div>
    }

    return (
        <>
            <div className="mb-3 pb-0 pt-2 bg-light border-bottom border-gray">
                <div className="container-xxl">
                    <div className="row d-flex">
                        <div className="col d-flex flex-row">
                            <div className="flex-grow-1">
                                <Link to={RoutesPathDefs.toAuctionLotsPage.generatePath(auction.id)}
                                      state={{ fromLot: true, auction: auction }}
                                      className="py-1 text-decoration-none">
                                    <i className="fa-solid fa-angle-left" />&nbsp;&nbsp;
                                    <span className="text-decoration-underline">Back to lots view</span>
                                </Link>

                                <h5 className="pt-2">
                                    <strong>#{lot.number}</strong>
                                    &nbsp;|&nbsp;
                                    {lot.name}
                                </h5>
                            </div>
                            <div className="lot-actions pt-4">
                                <LotWatchWidgetButton lot={lot} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl">
                <div className="row">
                    <div className="col-12 col-lg-7 p-0 mb-3 mb-lg-0">
                        <LotMediaFilesGallery lot={lot} />
                    </div>

                    <div className="col-12 col-lg-5 p-0 ps-lg-4">
                        <div className="bg-light p-3 pb-2 border-bottom border-gray mb-3 shadow-sm">
                            <LotBiddingAreaCmp
                                auction={auction}
                                lot={lot}
                            />
                        </div>

                        <div className="bg-light px-3 pt-1 border-bottom border-gray shadow-sm">
                            <h4 className='border-bottom mt-3'>Detailed description</h4>
                            <div className='py-3 fr-view' dangerouslySetInnerHTML={{__html: lot.description}}/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

