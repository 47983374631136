import React from "react";


export function useIsMobileDevice() {
    const [isMobileDevice, setIsMobileDevice] = React.useState(false);

    React.useEffect(() => {
        const c_touchStart = 'ontouchstart' in document.documentElement;
        const c_touchMulti = navigator.maxTouchPoints > 1;
        setIsMobileDevice(c_touchMulti && c_touchStart);
    }, [])

    return isMobileDevice;
}
