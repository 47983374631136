import {EventBus} from "./EventBus";
import TaggedLogger from "./TaggedLogger";

const _logger = TaggedLogger.get('TimerEventsEmitter');

type ListenerFunction<T = any> = (param?: T)=>void;
type UnsubscribeFunction = ()=>void;

let _defaultInstance:TimerEventsEmitter;

const EVENT_TYPES = {
    tickSecond: 'tick.second',
    tickMinute: 'tick.minute',
    serverUnixTimestampUpdate: 'serverUnixTimestamp.update',
}

export default class TimerEventsEmitter {
    private readonly _eventBus:EventBus;
    private _intervalId;
    private _syncedServerUnixTimestamp: number;


    static init() {
        if (_defaultInstance) {
            throw 'already initialized';
        }
        _defaultInstance = new TimerEventsEmitter();
        _logger.info('.init: initialized! ');

        ((window || global || {}) as any)._dev_TimerEventsEmitter_instance = _defaultInstance;
        return _defaultInstance;
    }



    constructor(serverUnixTimestamp = 0) {
        this._eventBus = new EventBus();
        this._syncedServerUnixTimestamp = serverUnixTimestamp;
        this._intervalId = setInterval(() => {
            this._syncedServerUnixTimestamp += 1;

            // _logger.debug('.tick ~', this._syncedServerUnixTimestamp);

            this._eventBus.emit(EVENT_TYPES.tickSecond);
            if (this._syncedServerUnixTimestamp % 60 === 0) {
                this._eventBus.emit(EVENT_TYPES.tickMinute);
            }
        }, 1_000);
    }

    updateServerUnixTimestamp(serverUnixTimestamp:number) {
        // const previousValue = this._syncedServerUnixTimestamp;
        // _logger.debug('.updateServerUnixTimestamp: [', previousValue, '] -> [', serverUnixTimestamp, ']: diff: ', serverUnixTimestamp - previousValue);
        this._syncedServerUnixTimestamp = serverUnixTimestamp;
        this._eventBus.emit(EVENT_TYPES.serverUnixTimestampUpdate, serverUnixTimestamp);
    }


    onEverySecond(cbFn:ListenerFunction):UnsubscribeFunction {
        return this._eventBus.on(EVENT_TYPES.tickSecond, cbFn);
    }


    onEveryMinute(cbFn:ListenerFunction):UnsubscribeFunction {
        return this._eventBus.on(EVENT_TYPES.tickMinute,cbFn);
    }

    onServerUnixTimestampUpdate(cbFn:ListenerFunction):UnsubscribeFunction {
      return this._eventBus.on(EVENT_TYPES.serverUnixTimestampUpdate, cbFn);
    }


    onEvery(durationSeconds:number, cbFn:ListenerFunction):UnsubscribeFunction {
        const intervalId = setInterval(cbFn, durationSeconds);
        return () => {
            clearInterval(intervalId);
        }
    }

}
