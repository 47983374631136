import {Models} from "@rematch/core";
import {auctionsLists} from "./auctionsListsModel";
import {currentAuction} from "./currentAuctionModel";
import {currentAuctionLots} from "./currentAuctionLotsModel";
import {watchlist} from "./watchlistModel";

export interface RootModel extends Models<RootModel> {
    auctionsLists: typeof auctionsLists,

    currentAuction: typeof currentAuction,
    currentAuctionLots: typeof currentAuctionLots,

    watchlist: typeof watchlist,
}

export const models: RootModel = {
    auctionsLists,

    currentAuction,
    currentAuctionLots,

    watchlist: watchlist,
};
