import {createModel} from "@rematch/core";
import type {RootModel} from "./index";
import {Auction_ModelTypeDef, ListingBrowserParams, PaginatedList_TypeDef} from "../../type_defs/model";
import AppController from "../../AppController";
import {ApiResourceDetailsLevelsEnum} from "../../type_defs/api";

export type AuctionsModelListSubState = {
    pageSize: number;
    totalCount: number;
    pageNumber: number;
    pagesCount: number;
    isFetching?: boolean,
    fetchError?:string|boolean,
    list: Auction_ModelTypeDef[]
}

export type AuctionsModelState = {
    active: AuctionsModelListSubState,
    completed: AuctionsModelListSubState,
    byId: Map<number, Auction_ModelTypeDef>,
}

export const auctionsLists = createModel<RootModel>()({
    name: 'auctionsLists',

    state: {
        active: {
            isFetching: true,
            list: [],
            totalCount: 0,
            pageNumber: 1,
            pagesCount: 1,
        },
        completed: {
            isFetching: true,
            list: [],
            totalCount: 0,
            pageNumber: 1,
            pagesCount: 1,
            },
        byId: new Map(),
    } as AuctionsModelState,


    reducers: {
        setFetchedActiveList: (state, payload:PaginatedList_TypeDef<Auction_ModelTypeDef>) => {
            state.active.isFetching = false;
            state.active.fetchError = null;
            state.active.list = payload.list;
            payload.list.forEach((a) => {
                state.byId.set(a.id, a);
            });
            state.active.pageNumber = payload.pageNumber;
            state.active.pageSize = payload.pageSize;
            state.active.totalCount = payload.totalCount;
            state.active.pagesCount = payload.pagesCount;
        },

        setFetchErrorForActiveList: (state, payload:string) => {
            state.active.isFetching = false;
            state.active.fetchError = payload;
        },

        setFetchedCompletedList: (state, payload:PaginatedList_TypeDef<Auction_ModelTypeDef>) => {
            state.completed.isFetching = false;
            state.completed.fetchError = null;
            state.completed.list = payload.list;
            payload.list.forEach((a) => {
                state.byId.set(a.id, a);
            });
            state.completed.pageNumber = payload.pageNumber;
            state.completed.pageSize = payload.pageSize;
            state.completed.totalCount = payload.totalCount;
            state.completed.pagesCount = payload.pagesCount;
        },

        setFetchErrorForCompletedList: (state, payload:string) => {
            state.completed.isFetching = false;
            state.completed.fetchError = payload;
        },


        updateAuction: (state, auction:Auction_ModelTypeDef) => {
            const existingAuction = state.byId.get(auction.id);

            const updatedAuctionList = auction.isCompleted ? state.completed.list : state.active.list;
            const updatedAuction = {
                    ... existingAuction, // may be null
                    ... auction, // overwrite with new info
                }

            if (! existingAuction) {
                // existing Auction not found
                // then just add
                updatedAuctionList.push(auction);
            }

            else {
                // existing Auction found
                let existingAuctionList = existingAuction.isCompleted ? state.completed.list : state.active.list;
                let existingAuctionListIndex = existingAuctionList.findIndex(a => a.id === auction.id);

                // need to change list to/from completed <-> active ?
                if (existingAuction.isCompleted !== updatedAuction.isCompleted) {
                    // remove from old list
                    existingAuctionList.splice(existingAuctionListIndex, 1);
                    // add to new list
                    updatedAuctionList.push(updatedAuction);
                }
                else {
                    // update
                    existingAuctionList[existingAuctionListIndex] = updatedAuction;
                }
            }

            if (updatedAuction.isCompleted) {
                updatedAuctionList.sort((a1, a2) => {
                    // when COMPLETED: sort descending by completesAt
                    return a2.completesAt.toSeconds() - a1.completesAt.toSeconds();
                })
            }
            else {
                updatedAuctionList.sort((a1, a2) => (
                    a1.completesAt.toSeconds() - a2.completesAt.toSeconds() ||
                    a1.startsAt.toSeconds() - a2.startsAt.toSeconds() ||
                    a1.id - a2.id))
            }

            // add to Map
            state.byId.set(auction.id, updatedAuction);
        },


        removeAuction: (state, {auctionId}:{ auctionId:number }) => {
            console.log('>>>>removeAuction: ', auctionId, state);
            state.active.list = state.active.list.filter(a => a.id !== auctionId);
            state.completed.list = state.completed.list.filter(a => a.id !== auctionId);
            state.byId.delete(auctionId);
        },
    },


    effects: (dispatch) => ({

        async loadActiveAuctionsList(browserParams?: ListingBrowserParams) {
            try {
                const auctionsList: PaginatedList_TypeDef<Auction_ModelTypeDef> = await AppController.instance.remoteDataHandler.fetchAuctionsList({
                    ...browserParams,
                    completesAt: 1,
                    entityDetailsLevel: ApiResourceDetailsLevelsEnum.minimal
                });
                dispatch.auctionsLists.setFetchedActiveList(auctionsList);
            }
            catch (ex) {
                dispatch.auctionsLists.setFetchErrorForActiveList(ex.toString());
            }
        },


        async loadCompletedAuctionsList(browserParams?: ListingBrowserParams) {
            try {
                const auctionsList: PaginatedList_TypeDef<Auction_ModelTypeDef> = await AppController.instance.remoteDataHandler.fetchAuctionsList({
                    ...browserParams,
                    completesAt: -1,
                    entityDetailsLevel: ApiResourceDetailsLevelsEnum.minimal
                });
                dispatch.auctionsLists.setFetchedCompletedList(auctionsList);
            }
            catch (ex) {
                dispatch.auctionsLists.setFetchErrorForCompletedList(ex.toString());
            }
        },


        async reloadAuction({auctionId, details = ApiResourceDetailsLevelsEnum.minimal}:{auctionId:number, details?:ApiResourceDetailsLevelsEnum}, rootState) {
            try {
                const auction:Auction_ModelTypeDef = await AppController.instance.remoteDataHandler.fetchAuction(auctionId, details);
                dispatch.auctionsLists.updateAuction(auction);

                // update "currentAuction" if it's the same (by id)
                if (auctionId === rootState.currentAuction?.auction?.id) {
                    dispatch.currentAuction.updateAuction(auction);
                }
            }
            catch (ex) {
                //???
            }
        }

    }),
});
