import React from "react";
import {Outlet, useParams} from "react-router-dom";
import {AuctionPageHeaderCmp} from "../components/auction/AuctionPageHeaderCmp";
import {useCurrentAuctionModelState, useLoadAuctionAsCurrentAndRegisterForRealTimeUpdates} from "@nextlot/core/hooks";
import {TaggedLogger} from "@nextlot/core/utilities";


const _logger = TaggedLogger.get('AuctionPageRoot');


export function AuctionPageRoot() {
    const routeParams = useParams();
    const auctionId = parseInt(routeParams.auctionId);

    _logger.debug('.render() routeParams:', routeParams);

    useLoadAuctionAsCurrentAndRegisterForRealTimeUpdates(auctionId);

    const {auction, isFetching, hasFetchError} = useCurrentAuctionModelState();

    if (!auction) {
        if (isFetching) {
            return <div>Loading ...</div>;
        } else if (hasFetchError) {
            return (
                <div className="py-3">
                    <h4>Connection Error</h4>

                    <div>
                        Please refresh the page in a few seconds.
                        <br/>
                        If that doesn't help, then connect to WiFi or a faster connection and try again.
                    </div>
                </div>
            )
        }
    }
    // else if the auction object exists, then:
    // ignore the fetch error (since we already have the auction object)
    // it may be stale, but at least we don't break the UI


    return (
        <>
            <AuctionPageHeaderCmp />

            {isFetching && <div className='position-absolute'>overlay</div>}

            <Outlet /> {/* this <Outlet> will render as one of:
            - AuctionWarrantPageDispatcher
            - AuctionLotsPageRoot
               */}

        </>
    )
}
