import React from "react";
import {DateTime, Duration} from "luxon";
import classNames from "classnames";
import {Bid_ModelTypeDef, LotMetal_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {getMoneyHelperForCurrencyCode} from "@nextlot/core/services/MoneyHelper";
import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useId,
    useInteractions,
    useRole
} from "@floating-ui/react-dom-interactions";
import {AppController, pluralize} from "@nextlot/core";
import {DATETIME_FORMAT_STRINGS, formatDateTime} from "@nextlot/core/helpers";


type BidsHistoryPopover_Props_TypeDef = React.PropsWithChildren<{
    lot: LotMetal_ModelTypeDef,
    currencyCode: string,
}>;


type BidsHistoryPopover_Bid_ModelTypeDef = Bid_ModelTypeDef & {
    placedAtAsHumanString:string,
    amountFormatted:string,
};

type BidsHistoryPopover_List_TypeDef = Array<BidsHistoryPopover_Bid_ModelTypeDef>;


export default function LotBidsCountLabelAndHistoryPopover({ lot, currencyCode }:{ lot:LotMetal_ModelTypeDef, currencyCode: string }) {
    if (lot.bidsCount === -1) {
        // convention for bids history is NOT hidden (see Auction.bids_history_settings.post_auction_visibility)
        return null;
    }

    if (lot.leadingBidAmountCents > 0) {
        return <BidsHistoryPopoverContainer lot={lot} currencyCode={currencyCode}>
            <div className={classNames("d-flex flex-row align-items-center", (lot.bidsCount > 0) && "has-cursor-pointer")}>
                <small className={classNames("fw-light", (lot.bidsCount > 0) && "text-decoration-underline")}>{pluralize(lot.bidsCount, 'bids', 'bid')}</small>
                {
                    (lot.bidsCount > 0)
                    &&
                    <i className="ms-2 fa-regular fa-chevron-down fa-2xs"/>
                }
            </div>
        </BidsHistoryPopoverContainer>
    }
    else {
        return <div className="small">&nbsp;</div>
    }
}


function BidsHistoryPopoverContainer({ children, lot, currencyCode }:BidsHistoryPopover_Props_TypeDef) {

    const [open, setOpen] = React.useState(false);

    const moneyHelper = getMoneyHelperForCurrencyCode(currencyCode);

    const {
        x,
        y,
        reference,
        floating,
        strategy,
        refs,
        update,
        context
    } = useFloating({
        open,
        onOpenChange: setOpen,
        middleware: [offset(1), flip(), shift()],
        placement: 'bottom',
    });

    const labelId = useId();

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context),
        useDismiss(context),
    ]);

    React.useEffect(() => {
        if (refs.reference.current && refs.floating.current && open) {
            return autoUpdate(refs.reference.current, refs.floating.current, update);
        }
    }, [open, update, refs.reference, refs.floating]);



    const [bidsList, setBidsList] = React.useState<BidsHistoryPopover_List_TypeDef>(null);

    React.useEffect(() => {
        if (open) {
            AppController.instance.remoteDataHandler.fetchAuctionLotBidsList(lot.auctionId, lot.id)
                .then((respList) => {
                    const updatedBidsList:BidsHistoryPopover_List_TypeDef = (respList.list || []).map((b) => ({
                        ... b,
                        placedAtAsHumanString: bidDateTimeAsHumanString(b.createdAt),
                        amountFormatted: moneyHelper.formatFromCents(b.amountCents),
                    }));


                    setBidsList(updatedBidsList);
                });
        }
    }, [lot.id, lot.bidsCount, open]);


    if ( ! (lot.bidsCount > 0)) {
        // if the lot has no bids, then don't render the popover handler
        return <>{children}</>;
    }

    return (
        <>
            {React.isValidElement(children) &&
                React.cloneElement(children, getReferenceProps({ ref: reference }))}

            <FloatingPortal>
                {open && (
                    <div
                        {...getFloatingProps({
                            className: classNames("lot-bids-history-popover", bidsList?.length || "has-no-bids"),
                            ref: floating,
                            style: {
                                position: strategy,
                                top: y ?? "",
                                left: x ?? ""
                            },
                            "aria-labelledby": labelId,
                        })}
                    >
                        {
                            (bidsList?.length)
                                ? (
                                    <>
                                        <div className='header'>
                                            <h4 style={{ margin: 0 }} id={labelId}>Bids history</h4>
                                            <button type="button" className="btn-close"
                                                    aria-label="Close"
                                                    onClick={() => setOpen(false)}
                                            />
                                        </div>

                                        <div className='content'>
                                            <div className='list-scrollable-container'>
                                                <table className='table table-sm table-hover table-striped'>
                                                    <thead>
                                                    <tr>
                                                        <th>Bidder</th>
                                                        <th>Bid</th>
                                                        <th>Time</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        bidsList.map((bid) => (
                                                            <tr key={bid.id}>
                                                                <td>{bid.bidderDisplayText}</td>
                                                                <td className={classNames(bid.isOverridden && 'text-decoration-line-through')}>{bid.amountFormatted}{bid.isAuto ? ' *' : ''}</td>
                                                                <td>{bid.placedAtAsHumanString}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='footer'>
                                            <small>
                                                <strong>*</strong> &nbsp; <em>This bid was automatically generated on behalf of a bidder who had previously placed a maximum bid.</em>
                                            </small>
                                        </div>
                                    </>
                                )
                                : (
                                    bidsList
                                        ? <div className='p-3'>There are no bids.</div>
                                        : <div className='p-3'><i className='fa-xl fa-regular fa-sync fa-spin' /></div>
                                )
                        }

                    </div>

                )}
            </FloatingPortal>
        </>
    )
}



const DURATION_THRESHOLD_HIDE_MINUTES = Duration.fromObject({ hours: 2 });
const DURATION_THRESHOLD_SHOW_DATE = Duration.fromObject({ days: 2 });


function bidDateTimeAsHumanString(bidDateTime:DateTime):string {
    const duration:Duration = bidDateTime.diffNow().negate(); // call .negate() to make the duration values positive
    if (duration > DURATION_THRESHOLD_SHOW_DATE) {
        return formatDateTime(bidDateTime, DATETIME_FORMAT_STRINGS.LOT_CARD_WITH_WEEKDAY);
    }
    else if (duration > DURATION_THRESHOLD_HIDE_MINUTES) {
        const durObj = duration.shiftTo('hours').toObject();
        return Math.round(durObj.hours) + 'h';
    }
    else {

        const durObj = duration.shiftTo('hours', 'minutes').toObject();
        const hoursNumber = Math.round(durObj.hours);
        const minutesNumber = Math.round(durObj.minutes);
        if (hoursNumber + minutesNumber === 0) {
            return '< 1min'
        }
        return [
            hoursNumber > 0 && `${hoursNumber}h`,
            minutesNumber > 0 && `${minutesNumber}min`
        ].filter(Boolean).join(' ');
    }
}
