import React from "react";
import { useLocation } from "react-router-dom";
import {
  ListingBrowserParams,
  LotsListFiltersParamValueStruct,
  LotsListSortingParamValueStruct,
} from "@nextlot/core/type_defs/model";
import { TaggedLogger } from "@nextlot/core/utilities";

type generatePathParams = Partial<
  Omit<ListingBrowserParams, "filters" | "sortingCriteria"> & {
    filters: string | LotsListFiltersParamValueStruct,
    sortingCriteria: string | LotsListSortingParamValueStruct
  }
>;

export type generatePathWithParamsFunction_TypeDef = (params:generatePathParams)=>string;

export type useLotsListingUrlParamsResultTypeDef = ListingBrowserParams & {
    generatePathWithParams: generatePathWithParamsFunction_TypeDef,
}

const _logger = TaggedLogger.get('useListingUrlParams');

export default function useListingUrlParams():useLotsListingUrlParamsResultTypeDef {

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const pageNumber = parseInt(urlSearchParams.get('page')) || 1;
    const pageSize = parseInt(urlSearchParams.get('page_size')) || undefined;
    const filters = urlSearchParams.get('filters') || undefined;
    const sortingCriteria = urlSearchParams.get('sorting_criteria') || undefined;

    const generatePathWithParams = React.useCallback(
        (params:generatePathParams) => {
            const { pageNumber = 1, pageSize, filters, sortingCriteria } = params;
            // _logger.debug('>generatePathWithParams:', params);

            const urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set('page', (pageNumber || 1).toString());
            if (pageSize) {
                urlSearchParams.set('page_size', pageSize.toString());
            }
            if (filters) {
                const existingFiltersStruct = parseFiltersString(urlSearchParams.get('filters'));
                const argFiltersStruct = (typeof filters === 'string')
                    ? parseFiltersString(filters)
                    : filters;
                urlSearchParams.set('filters', serializeFilterStruct({ ... existingFiltersStruct, ... argFiltersStruct }));
            }
            if (sortingCriteria) {
                const existingSortStruct = parseSortString(urlSearchParams.get('sorting_criteria'));
                const argSortStruct = (typeof sortingCriteria === 'string')
                  ? parseSortString(sortingCriteria)
                  : sortingCriteria;
                urlSearchParams.set('sorting_criteria', serializeFilterStruct({ ... existingSortStruct, ... argSortStruct }));
            }

            return location.pathname + '?' + urlSearchParams.toString();
        }, [location]);

    return {
        pageNumber,
        pageSize,
        filters,
        sortingCriteria,
        generatePathWithParams,
    }
}


function stringToBoolean(argStrVal:string):boolean {
    const strVal:any = (typeof(argStrVal) === 'string') ? argStrVal.trim().toLowerCase() : argStrVal;

    switch(strVal) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

export function parseFiltersString(filtersString:string|undefined):LotsListFiltersParamValueStruct|undefined {

    if ( ! filtersString) {
        return undefined;
    }

    const filterStruct:LotsListFiltersParamValueStruct = {};
    let isValid = false;
    filtersString.split('|').forEach((fsPart) => {
        let [fKey, fVal] = fsPart.split(':');
        fKey ||= '';
        switch (fKey) {
            case 'watched': {
                // `watched` refers to "watched-only"; so false value means "no filter for watched"
                filterStruct.watched = stringToBoolean(fVal);
                isValid = true;
                break;
            }
            case 'text_search': {
                filterStruct.text_search = fVal;
                isValid = true;
                break;
            }
            case 'auction_completes_at': {
                filterStruct.auction_completes_at = fVal;
                isValid = true;
                break;
            }
            case 'auctions_running': {
              filterStruct.auctions_running = stringToBoolean(fVal);
              isValid = true;
              break;
            }
            case 'auctions_upcoming': {
              filterStruct.auctions_upcoming = stringToBoolean(fVal);
              isValid = true;
              break;
            }
        }
    });

    return isValid ? filterStruct : undefined;
}

export function parseSortString(sortString:string|undefined):LotsListSortingParamValueStruct|undefined {

    if ( ! sortString) {
        return undefined;
    }

    const filterStruct:LotsListSortingParamValueStruct = {};
    let isValid = false;
    sortString.split('|').forEach((fsPart) => {
        let [fKey, fVal] = fsPart.split(':');
        fKey ||= '';
        switch (fKey) {
            case 'lots_completes_at': {
                filterStruct.lots_completes_at = fVal;
                isValid = true;
                break;
            }
            case 'auctions_starts_at': {
                filterStruct.auctions_starts_at = fVal;
                isValid = true;
                break;
            }
        }
    });

    return isValid ? filterStruct : undefined;
}


export function serializeFilterStruct(fst:LotsListFiltersParamValueStruct | LotsListSortingParamValueStruct):string {
    if (!fst) {
        return undefined;
    }
    return Object.entries(fst).map(([fKey, fVal]) => (`${fKey}:${fVal}`)).join('|') || undefined;
}
