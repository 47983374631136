import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactModal from "react-modal";
import {CatalogLot_ModelTypeDef, RunningCatalogLot_ModelTypeDef} from "@nextlot/core/type_defs/model";
import LotMediaFilesGallery from "./LotMediaFilesGallery";
import {TaggedLogger} from "@nextlot/core/utilities";
import {useCurrentAuctionSingleLot} from "@nextlot/core/hooks/CurrentAuctionLotsListHook";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "@nextlot/core/rematch/store";


const _logger = TaggedLogger.get('LotDetailsModalCmp');



export default function LotDetailsModalCmp({preloadedLot}:{ preloadedLot:CatalogLot_ModelTypeDef }) {
    const {lotId: lotIdStr} = useParams<'lotId'>();
    const lotId: number = Number(lotIdStr);

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = React.useState(true);

    const modal_onRequestClose = React.useCallback(() => {
        setIsModalOpen(false);
        navigate(-1);
    }, [lotId])


    if (!isModalOpen) {
        return null;
    }

    return (
        <ReactModal
            isOpen={isModalOpen}
            onRequestClose={modal_onRequestClose}
            contentLabel="Lot details"
            portalClassName={'rmodal__portal'}
            overlayClassName={{ base: 'rmodal__overlay', afterOpen: 'rmodal__overlay--after-open', beforeClose: '' }}
            className={'rmodal__dialog lot-details__rmodal-dialog'}
            bodyOpenClassName={'rmodal__html-body--open'}
        >
            <div className='lot-details__container'>
                <div className='lot-details__header'>
                    <div>
                        <strong>#{preloadedLot.number}</strong>
                        &nbsp;|&nbsp;
                        {preloadedLot.name}
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <a href='' target='_blank' className='btn btn-sm btn-outline-primary text-nowrap'>
                            Lot Full Page <i className="fa-regular fa-arrow-up-right-from-square" />
                        </a>

                        <button type="button" className="ms-5 btn-close" aria-label="Close" onClick={modal_onRequestClose} />
                    </div>
                </div>

                {
                    preloadedLot
                        ? <LoadingCatalogLotDetailsBodyViewForModal preloadedLot={preloadedLot} />
                        : <LoadingCatalogLotDetailsBodyViewForSingleLotPage lotId={lotId} />

                }
            </div>


            <div className='lot-details__footer'>
            </div>
        </ReactModal>
    )
}



function LoadingCatalogLotDetailsBodyViewForModal({preloadedLot}:{preloadedLot:CatalogLot_ModelTypeDef}) {
    // _logger.debug('[LoadingCatalogLotDetailsBodyViewForModal] .render() preloadedLot:', preloadedLot);

    const lotId = preloadedLot.id;
    const lotsList = useSelector<RootState, CatalogLot_ModelTypeDef[]>((state: RootState) => state.currentAuctionLots.lotsList);
    const lot = lotsList?.find(l => (l.id === lotId));

    const dispatch = useDispatch<Dispatch>();
    React.useEffect(() => {
        if(lot) {
            dispatch.currentAuctionLots.asyncFetchAndUpdateLotWithFullDetails({ lotId: lot.id });
        }
    }, [lotId, lot?.id])


    return (
        <div className='lot-details__body'>
            {
                lot?.mediaFiles
                &&
                <LotMediaFilesGallery lot={lot} />
            }

            <div className='py-4 px-3'>
                <h4 className='border-bottom'>Detailed description</h4>
                {
                    lot?.description
                    &&
                    <div className='py-3 fr-view' dangerouslySetInnerHTML={{__html: lot.description}}/>
                }
            </div>
        </div>
    )
}



function LoadingCatalogLotDetailsBodyViewForSingleLotPage({lotId}:{lotId:number}) {
    const lot = useCurrentAuctionSingleLot(lotId);

    return (
        <div className='lot-details__body'>
            {
                lot
                &&
                <LotMediaFilesGallery lot={lot} />
            }

            <div className='py-4 px-3'>
                <h4 className='border-bottom'>Detailed description</h4>
                {
                    lot
                    &&
                    <div className='py-3 fr-view' dangerouslySetInnerHTML={{__html: lot.description}}/>
                }
            </div>
        </div>
    )

}


