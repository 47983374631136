
export default class TaggedLogger {

    static get(tagPrefix:string) {
        return new TaggedLogger(tagPrefix);
    };



    tagPrefix: string;


    constructor(logProducer:any) {

        let producerTag:string;

        if (typeof logProducer === 'function') {
            producerTag = logProducer.name;
        }
        else if (typeof logProducer === 'object') {
            throw new Error('TaggerLogger ERROR: invalid logProducer of type object');
        }
        else {
            producerTag = String(logProducer);
        }

        this.tagPrefix = `[${producerTag}]`;
    }


    $updateTagPrefix(newTagPrefix:string):void {
        this.tagPrefix = `[${newTagPrefix}]`;
    }


    debug(...args:Array<any>) {
        console.debug(this.tagPrefix, ...args);
    }

    log(...args:Array<any>) {
        console.log(this.tagPrefix, ...args);
    }

    info(...args:Array<any>) {
        console.info(this.tagPrefix, ...args);
    }

    warn(...args:Array<any>) {
        console.warn(this.tagPrefix, ...args);
    }

    error(...args:Array<any>) {
        console.error(this.tagPrefix, ...args);
    }
}
